import { captureMessage } from '@sentry/browser';
import {
  MetraSimpleAction,
  ModelPropertySchema,
  ModelSchemaCategories,
} from 'types';
import { SCHEMA_CATEGORY } from 'utils/constants-extra';
import { PROPERTY_SCHEMA } from 'utils/constants';
import { gid } from 'modules/model/gid';

export const createPropertySchema = (schema: {
  label: string;
  category: ModelSchemaCategories;
  id?: GID;
  required?: boolean;
}): ModelPropertySchema => {
  if (!Object.values(SCHEMA_CATEGORY).includes(schema.category)) {
    const errMessage = `Invalid property schema category: ${schema.category}`;
    captureMessage(errMessage);
    throw errMessage;
  }

  return {
    id: schema.id ?? gid(),
    label: schema.label,
    category: schema.category,
    required: schema.required ?? false,
  };
};

export const addPropertySchema = (schema: ModelPropertySchema) =>
  addPropertySchemas([schema]);

export const addPropertySchemas = (
  schemas: ModelPropertySchema[]
): MetraSimpleAction<ModelPropertySchema[]> => ({
  type: PROPERTY_SCHEMA.CREATE,
  payload: schemas,
});

/*
 * Makes an action for updating a property schema. Note that category
 * cannot be updated.
 *
 * @param  id - the gid of the property schema
 * @param  label - the string to display for this schema
 * @return  A property schema update action
 */
export const updatePropertySchema = (schema: ModelPropertySchema) =>
  updatePropertySchemas([schema]);
export const updatePropertySchemas = (
  schemas: ModelPropertySchema[]
): MetraSimpleAction<ModelPropertySchema[]> => {
  let trimmedSchemas = schemas.map((schema) => {
    let label = schema.label;
    label = label && label.trim();
    return { ...schema, label: label };
  });
  return {
    type: PROPERTY_SCHEMA.UPDATE,
    payload: trimmedSchemas,
  };
};

/*
 * Makes an action for deleting property schema.
 *
 * @param id - the gid of the schema
 * @return A property schema delete action
 */
export const deletePropertySchema = (schema: ModelPropertySchema) =>
  deletePropertySchemas([schema]);
export const deletePropertySchemas = (
  schemas: ModelPropertySchema[]
): MetraSimpleAction<ModelPropertySchema[]> => ({
  type: PROPERTY_SCHEMA.DELETE,
  payload: schemas,
});

export const initPropertySchemas = (
  schemas: Record<GID, ModelPropertySchema>
): MetraSimpleAction<Record<GID, ModelPropertySchema>> => ({
  type: PROPERTY_SCHEMA.INITIALIZE,
  payload: schemas,
});
