import { LIBRARY_VIEW_TYPE, isPayloadAction } from 'modules/common';
import { LIBRARY, LIBRARY_GENERIC } from './constants';
import { LibraryReducer, MetraAction } from 'types';
import { Reducer } from 'redux';

export const initialLibraryState: LibraryReducer = {
  sort: {
    ascending: false,
    column: 'modified',
  },
  selectedFileIdNotOnPage: false,
  archiving: false,
  creatingNewFolder: null,
  deleting: false,
  uploadingCount: 0,
  canceling: false,
  crumbPath: null,
  loading: false,
  newFile: null,
  previewFile: null,
  renamingFile: false,
  tags: [],
  showPreview: false,
  showDeleteFileConfirmationModal: false,
  showDeleteProjectConfirmationModal: false,
  showModelProcessingWarningModalForId: null,
  showUploadModal: false,
  selectedFolder: [],
  selected: {
    ids: [],
    files: [],
  },
  folderId: null,
  movingFiles: false,
  editingFile: null,
  newName: {
    value: undefined,
    valid: false,
  },
  pagination: {
    count: 0,
    numberOfRows: 50,
    rowCount: 0,
    startIndex: 0,
    total: 0,
  },
  currentFolder: null,
  startIndex: 0,
  columns: ['FileSelect', 'FileName', 'FileType', 'FileUser', 'FileDate'],
  columnOptions: {
    [LIBRARY_VIEW_TYPE.MEDIA_BY_FOLDER]: {
      FileSelect: {
        value: 'selected',
        title: '',
        colWidth: '46px',
      },
      FileName: {
        showSize: true,
        value: 'name',
        title: 'Name',
        archives: false,
        colWidth: '360px',
        minimumWidth: 160,
        resizable: true,
        showFilePath: false,
        sortable: true,
      },
      FileType: {
        value: 'type',
        title: 'Type',
        colWidth: '105px',
        minimumWidth: 60,
        resizable: true,
        sortable: true,
      },
      FileUser: {
        value: 'created_by',
        title: 'Uploaded By',
        colWidth: '185px',
        minimumWidth: 110,
        resizable: true,
        sortable: true,
      },
      FileDate: {
        value: 'modified',
        title: 'Date Modified',
        colWidth: '187px',
        minimumWidth: 110,
        resizable: true,
        sortable: true,
      },
    },
    [LIBRARY_VIEW_TYPE.MODELS]: {
      FileSelect: {
        value: 'selected',
        title: '',
        colWidth: '46px',
      },
      FileName: {
        showSize: true,
        value: 'name',
        title: 'Name',
        archives: false,
        colWidth: '467px',
        minimumWidth: 160,
        resizable: true,
        showFilePath: true,
        sortable: true,
      },
      FileUser: {
        value: 'created_by',
        title: 'Uploaded By',
        colWidth: '185px',
        minimumWidth: 110,
        resizable: true,
        sortable: true,
      },
      FileDate: {
        value: 'modified',
        title: 'Date Modified',
        colWidth: '187px',
        minimumWidth: 110,
        resizable: true,
        sortable: true,
      },
    },
  },
  fileFlyout: {
    show: false,
    file: null,
    taggedObject: null,
    top: 0,
    left: 0,
  },
  invalidFlyout: {
    show: false,
    top: 0,
    left: 0,
    tooltipText: '',
  },
  filePermissionsFlyout: {
    fileOwnerName: undefined,
    tooltipText: '',
    top: 0,
    left: 0,
    show: false,
  },
  tableScroll: {
    scrolledRight: 0,
  },
  tableView: LIBRARY_VIEW_TYPE.MEDIA_BY_FOLDER,
};

export const libraryReducer: Reducer<
  LibraryReducer,
  MetraAction<any, any, any>
> = (state = initialLibraryState, action) => {
  if (!isPayloadAction(action)) return state;
  switch (action.type) {
    case LIBRARY.UPDATE.SORT:
      return {
        ...state,
        startIndex: 0,
        sort: { ...state.sort, ...action.payload },
      };
    case LIBRARY.UPDATE.PAGINATION:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          ...action.payload,
        },
      };
    case LIBRARY.UPDATE.COLUMN_WIDTH:
      return {
        ...state,
        columnOptions: {
          ...state.columnOptions,
          [state.tableView]: { ...action.payload },
        },
      };
    case LIBRARY.UPDATE.SHOW_FILE_PATH:
      return {
        ...state,
        columnOptions: {
          ...state.columnOptions,
          [state.tableView]: { ...action.payload },
        },
      };
    case LIBRARY.UPDATE.SCROLLED_RIGHT:
      return {
        ...state,
        ...action.payload,
      };
    case LIBRARY.UPDATE.CURRENT_FOLDER:
      return {
        ...state,
        currentFolder: action.payload,
      };
    default:
      if (action.type.includes(LIBRARY_GENERIC))
        return {
          ...state,
          ...action.payload,
        };
      return state;
  }
};

export default libraryReducer;
