import {
  CollapseTuple,
  CollapsedConfig,
  MetraThunkAction,
  ModelEdge,
  ModelNode,
  ModelReducer,
  ModelSet,
  ModifiedEntities,
  RootReducer,
  ShapeConfig,
  ThunkActionFunc,
  Transition,
  TransitionData,
} from 'types';
import { getCount, globalGetState, makeLabel } from 'utils/utils-extra';
import { isNone, isSome } from './utils';
import { getSelectedShapes } from 'modules/model/selectors';
import { isEqual } from 'lodash';
import { Vector2 } from 'utils/vector';
import {
  isCollapsedSet,
  isEdge,
  isNode,
} from 'modules/model/shape/shape-helpers';
import {
  addShapesByConfig,
  deleteShapesByConfig,
  updateShapesByConfig,
} from 'modules/model/pixi';
import {
  createCollapsedSets,
  deleteCollapsedSets,
  remember,
} from 'modules/model/base/actions';
import { GLOBAL_EVENT, SCHEMA_CATEGORY, SHAPE } from 'utils/constants-extra';
import { makeCollapsedConfig } from 'modules/model/shape/shape';
import { updateExpressions, updateRowSize } from 'modules/model/properties';
import { addSet, createSet, updateSet } from 'modules/model/set';
import { colorParse } from 'utils/utils';
import { HEX_COLOR, SET_COLOR_OPTIONS } from 'utils/constants';
import { updateEntitySort } from 'modules/model/table/sort/actions';
import { updateNodes } from 'modules/model/node';
import { updateEdges } from 'modules/model/edge';
import {
  Collapsed,
  CollapsedSet,
  Edge,
  Node,
  Position,
  SetColors,
  ShapeID,
} from 'engine/components';
import { Entity } from 'ecs/Entity';
import { type EcsInstance } from 'ecs/EcsInstance';
import { getSelectedSets } from 'modules/model/selections';
import { edgeNodesInSameCollapsedSet } from './edges/collapsed';
import { isInCollapsedSet, maybeSetCollapsed } from './sets/collapsed';
import { isVisibleByGID } from './entities/visibility';
import { setIsContained, topContainerSet } from './sets/utils';
import { maybeSetVisible } from './sets/visibility';
import { gid } from 'modules/model/gid';

export const removeCollapsedSets = (
  deletedCollapsedShapes: ShapeConfig[],
  ecs: EcsInstance
): void => {
  for (let i = deletedCollapsedShapes.length; i--; ) {
    const collapsedSetId = deletedCollapsedShapes[i].id;
    const entity = ecs.tagManager.getEntityByTag(collapsedSetId);
    if (!entity) continue;
    ecs.deleteEntity(entity);
  }
};

export function toggleCollapsed(
  entity: Entity,
  collapsed: boolean,
  ecs: EcsInstance
): void {
  if (collapsed && !ecs.hasComponent(entity, Collapsed.type)) {
    ecs.addComponent(entity, new Collapsed());
    ecs.resolve(entity);
  } else if (!collapsed && ecs.hasComponent(entity, Collapsed.type)) {
    ecs.removeComponentType(entity, Collapsed);
    ecs.resolve(entity);
  }
}

/**
 * attempts to collapse an edge
 * based on current collapsing transition data
 * @param data the current transition data
 */
export function tryCollapseEdge(data: TransitionData): boolean {
  // are we currently in a collapsed set
  const inCollapsedSet = isInCollapsedSet(data);
  const edge = data.ecs.getComponentOfType(data.targetEntity, Edge);
  if (isNone(edge)) return false;
  const inAnchorVisible = isVisibleByGID(edge.inAnchorId, data);
  const outAnchorVisible = isVisibleByGID(edge.outAnchorId, data);
  const nodesInSameCollapsedSet = edgeNodesInSameCollapsedSet(data);
  // IF this edge is in a collapsed set with both end points
  // OR either OR both endpoints are non-visible
  // THEN this edge is not visible
  return (
    (inCollapsedSet && nodesInSameCollapsedSet) ||
    !(inAnchorVisible && outAnchorVisible)
  );
}

/**
 * attempts to uncollapse an edge
 * based on current collapsing transition data
 * @param data the current transition data
 */
export function tryUncollapseEdge(data: TransitionData): boolean {
  // are we currently in a collapsed set
  const inCollapsedSet = isInCollapsedSet(data);
  const edge = data.ecs.getComponent(data.targetEntity, Edge) as Edge;
  const inAnchorVisible = isVisibleByGID(edge.inAnchorId, data);
  const outAnchorVisible = isVisibleByGID(edge.outAnchorId, data);
  // IF we're not in a collapsed set
  // OR both anchors are visible
  // THEN this edge should be visible
  return !inCollapsedSet || (inAnchorVisible && outAnchorVisible);
}

/**
 * updates the positions of uncollapsing nodes based on transition data
 * @param data the current transition data
 */
export function updateNodeUncollapsePositions(
  data: TransitionData
): Option<ShapeConfig> {
  const [position, shapeID] = data.ecs.retrieve(data.targetEntity, [
    Position,
    ShapeID,
  ]);
  if (isNone(position) || isNone(shapeID)) return null;
  const nodeConfig = data.model.shapes[shapeID.value];
  if (!nodeConfig) return null;
  const nodeData = data.model.nodes[shapeID.value];
  if (!nodeData) return null;

  for (let i = nodeData.setIds.length; i--; ) {
    const setId = nodeData.setIds[i];
    const isCollapsed = maybeSetCollapsed(setId, data);
    const maybeTransition = data?.setCollapseTransitions?.[setId];
    // only the sets that will become uncollapsed should affect positions
    if (isCollapsed || isNone(maybeTransition)) continue;
    if (data.deltaPositions) {
      const delta = data.deltaPositions[setId];
      position.value = position.value.add(delta);
    }
  }

  data.ecs.update(position);
  return { ...nodeConfig, pos: position.value.toPoint() };
}

export const constructSet = (
  selectedSet: ModelSet,
  selectedShapes: ShapeConfig[]
): [ModelNode[], ModelEdge[]] => {
  const state = globalGetState().modelReducer;
  const updatedNodes: ModelNode[] = [];
  const updatedEdges: ModelEdge[] = [];
  selectedShapes.forEach((shape) => {
    if (shape.type === SHAPE.NODE) {
      const setNodeSet = new Set(selectedSet.nodeIds);
      setNodeSet.add(shape.id);
      selectedSet.nodeIds = Array.from(setNodeSet);
      const nodeSetSet = new Set(state.nodes[shape.id].setIds);
      nodeSetSet.add(selectedSet.id);
      updatedNodes.push({
        ...state.nodes[shape.id],
        setIds: Array.from(nodeSetSet),
      });
    }
    if (shape.type === SHAPE.EDGE) {
      const setEdgeSet = new Set(selectedSet.edgeIds);
      setEdgeSet.add(shape.id);
      selectedSet.edgeIds = Array.from(setEdgeSet);
      const edgeSetSet = new Set(state.edges[shape.id].setIds);
      edgeSetSet.add(selectedSet.id);
      updatedEdges.push({
        ...state.edges[shape.id],
        setIds: Array.from(edgeSetSet),
      });
    }
  });
  return [updatedNodes, updatedEdges];
};

/**
 * makes a set given the current model selection state
 */
export const makeSet: ThunkActionFunc<
  [opt: Partial<ModelSet>],
  Option<ModelSet>
> =
  (opt = {}) =>
  (dispatch, getState, { emit }) => {
    const state = getState().modelReducer;
    const selectedShapes = getSelectedShapes(state);

    const set = createSet({ label: makeLabel('set', state.sets), ...opt });

    // find an available color or generate one randomly
    const usedColors = Object.values(state.sets).map((set) => set.color);
    set.color =
      SET_COLOR_OPTIONS.find((color) => !usedColors.includes(color)) ??
      '#ffffff';
    if (set.color == null) {
      const colorhex = Math.floor(Math.random() * HEX_COLOR.WHITE);
      set.color = `#${colorhex.toString(16).padStart(6, '0')}`;
    }

    const setShapes = constructSet(set, selectedShapes);
    const updatedNodes = setShapes[0];
    const updatedEdges = setShapes[1];

    const order = getCount(state.sets);
    const mptRowHeight = state.propSizes.cellSize.height;
    dispatch(addSet(set));
    dispatch(updateEntitySort({ type: 'sets', id: set.id, order }));
    dispatch(updateNodes(updatedNodes));
    dispatch(updateEdges(updatedEdges));
    dispatch(updateRowSize(set.id, mptRowHeight));
    dispatch(remember());

    const modifiedCells = Object.values(getState().modelReducer.propSchemas)
      .filter((schema) => schema.category === SCHEMA_CATEGORY.SETS)
      .map((schema) => ({ parentId: set.id, schemaId: schema.id }));
    dispatch(updateExpressions({ values: modifiedCells }));
    emit(GLOBAL_EVENT.SHAPES_UPDATE, selectedShapes);
    return set;
  };

export const makeSets: ThunkActionFunc<
  [opts: Partial<ModelSet>[]],
  Option<ModelSet>[]
> =
  (opts = []) =>
  (dispatch, _getState) => {
    const sets = [];

    for (let i = 0; i < opts.length; i++) {
      sets.push(dispatch(makeSet(opts[i])));
    }

    return sets;
  };

/**
 *
 * @param  set
 * @returns a tuple of modified entities
 */
export const addToSet: ThunkActionFunc<[ModelSet], Option<ModifiedEntities>> =
  (set) =>
  (dispatch, getState, { emit }) => {
    const state = getState().modelReducer;
    const selectedSet: ModelSet = { ...set };
    const selectedShapes = getSelectedShapes(state);
    if (selectedShapes.length < 1) return null;

    const [updatedNodes, updatedEdges] = constructSet(
      selectedSet,
      selectedShapes
    );
    dispatch(updateSet(selectedSet));
    dispatch(updateNodes(updatedNodes));
    dispatch(updateEdges(updatedEdges));
    const modifiedCells = Object.values(state.propSchemas)
      .filter((schema) => schema.category === SCHEMA_CATEGORY.SETS)
      .map((schema) => ({ parentId: set.id, schemaId: schema.id }));
    dispatch(updateExpressions({ values: modifiedCells, rebuildCache: true }));

    emit(GLOBAL_EVENT.SHAPES_UPDATE, selectedShapes);
    return [selectedSet, updatedNodes, updatedEdges];
  };

/**
 *
 * @param set
 * @returns the modified entities
 */
export const removeFromSet: ThunkActionFunc<
  [ModelSet],
  Option<ModifiedEntities>
> =
  (set) =>
  (dispatch, getState, { emit }) => {
    const state = getState().modelReducer;
    const selectedSet = { ...set };
    const selectedShapes = getSelectedShapes(state);
    if (selectedShapes.length < 1) return null;

    let updatedNodes: ModelNode[] = [];
    let updatedEdges: ModelEdge[] = [];

    selectedShapes.forEach((shape) => {
      if (shape.type === SHAPE.NODE) {
        selectedSet.nodeIds = selectedSet.nodeIds.filter(
          (id) => id !== shape.id
        );
        updatedNodes.push({
          ...state.nodes[shape.id],
          setIds: [
            ...state.nodes[shape.id].setIds.filter(
              (id) => id !== selectedSet.id
            ),
          ],
        });
      }
      if (shape.type === SHAPE.EDGE) {
        selectedSet.edgeIds = selectedSet.edgeIds.filter(
          (id) => id !== shape.id
        );
        updatedEdges.push({
          ...state.edges[shape.id],
          setIds: [
            ...state.edges[shape.id].setIds.filter(
              (id) => id !== selectedSet.id
            ),
          ],
        });
      }
    });
    dispatch(updateSet(selectedSet));
    dispatch(updateNodes(updatedNodes));
    dispatch(updateEdges(updatedEdges));
    const modifiedCells = Object.values(getState().modelReducer.propSchemas)
      .filter((schema) => schema.category === SCHEMA_CATEGORY.SETS)
      .map((schema) => ({ parentId: set.id, schemaId: schema.id }));
    dispatch(updateExpressions({ values: modifiedCells, rebuildCache: true }));

    emit(GLOBAL_EVENT.SHAPES_UPDATE, selectedShapes);
    return [selectedSet, updatedNodes, updatedEdges];
  };

// /**
//  * toggles display of a set membership color on a set's nodes and edges.
//  * @param allSetsShapes - array of set shapes and edges.
//  */
// export const toggleSetMemberships =
//   (allSetsShapes: ShapeConfig[]) => (): void => {
//     const flattenedSetShapes = allSetsShapes.flat();
//     EventManager.emit(
//       GLOBAL_EVENT.TOGGLE_SET_MEMBERSHIP_DISPLAY,
//       flattenedSetShapes
//     );
//     EventManager.emit(GLOBAL_EVENT.SHAPES_UPDATE, flattenedSetShapes);
//   };

/**
 * toggles the collapse/expansion of a single set.
 * @param  id - the id of the set
 * @returns  a redux thunk
 */

/*
 * is the given set considered non-visible
 */
export function isSetInvisible(
  setId: GID,
  modelState: ModelReducer = globalGetState().modelReducer
): boolean {
  const { sets } = modelState;
  const set = sets[setId];

  return set && !set.visible;
}

/**
 * Determines whether a set can collapse or expand
 *
 * A set cannot collapse or expand if:
 *    - the set contains collapsed members
 *    - the set contains no nodes
 *    - the set is contained in a collapsed set and does not contain collapsed sets
 *
 * @param setId
 * @param modelState
 * @returns true if the set can toggle
 */
export function setCanToggle(
  setId: GID,
  modelState: ModelReducer = globalGetState().modelReducer
): boolean {
  if (isSetInvisible(setId, modelState)) return false;

  const collapsedSets = findCollapsedSets(modelState);
  const isInCollapsed = checkSetContainedInCollapsed(
    setId,
    collapsedSets,
    modelState
  );
  const isCollapsed = !!modelState.base.collapsed.sets?.[setId] ?? false; // collapsedSets.includes(setId);
  const hasCollapsedSets = checkSetContainsCollapsedSets(
    setId,
    collapsedSets,
    modelState
  );
  const hasCollapsedMember = checkSetContainsCollapsedMember(setId, modelState);
  const hasCollapsedSubset = checkSetContainsCollapsedSubset(setId, modelState);
  const hasIdenticalCollapsedSet = checkSetHasIdenticalSet(
    setId,
    collapsedSets,
    modelState
  );

  const hasNodes = checkSetContainsNodes(setId, modelState);
  const allSetNodesContained = checkSetHasAllNodesContainedInCollapsed(
    setId,
    modelState
  );

  if (!isInCollapsed && hasCollapsedSets) {
    return true;
  } else if (isInCollapsed && !hasCollapsedSets) {
    return false;
  } else if (isCollapsed && hasIdenticalCollapsedSet) {
    return true;
  } else if (isInCollapsed && !hasCollapsedSubset) {
    return false;
  } else if (!hasCollapsedSets && hasCollapsedMember && hasCollapsedSubset) {
    return true;
  } else if (allSetNodesContained) {
    return false;
  } else if (!hasNodes) {
    return false;
  } else if (isInCollapsed) {
    return false;
  } else {
    return true;
  }
}

/**
 * is a collapsed set is wholly contained within another set
 */
export function collapsedSetIsContained(
  setId: GID,
  modelState: ModelReducer = globalGetState().modelReducer
): boolean {
  const {
    base: { collapsed },
  } = modelState;
  let foo = Object.keys(collapsed.sets).some(
    (sid) =>
      sid !== setId &&
      collapsed.sets[setId] &&
      setIsContained(setId, sid, modelState)
  );
  return foo;
}

/**
 * creates a collapsed set based on a passed array of set ids and model state.
 * @param setTuples - ids of sets to create CollapsedSet shapes from.
 * @param model - current model state.
 * @returns  an array of all created CollapsedSet shapes
 */
export const makeCollapsedShapes = (
  setTuples: CollapseTuple[],
  model: ModelReducer
): CollapsedConfig[] => {
  return setTuples.map(([collapsedId, setId]) => {
    // average the position by using the positions
    // of all the nodes in the set
    const pos = model.sets[setId].nodeIds.reduce(({ x, y }, id, i) => {
      const shapeConfig = model.shapes[id];
      // construct the average progressively
      return new Vector2(x * i, y * i)
        .add(shapeConfig.pos)
        .divScalar(i + 1)
        .toObject();
    }, Vector2.zero.toObject());

    return makeCollapsedConfig({
      options: {
        id: collapsedId,
        pos,
      },
      setId,
      startPos: pos,
    });
  });
};

/**
Returns all sets in the model that do not contain all of the 
currently-selected nodes and edges.
Restated, returns all sets for which either 
  (1) there is a selected node not contained in the set, or 
  (2) there is a selected edge not contained in the set.
*/

const _emptyArray: ModelSet[] = [];

/**
 * @param [state]
 * @return
 */
export const getSetsNotContainingSelection = (
  state: RootReducer = globalGetState()
): ModelSet[] => {
  const selectedNodes = new Set();
  const selectedEdges = new Set();
  for (const gid of state.modelReducer.base.selected.shapes) {
    if (isSome(state.modelReducer.nodes[gid])) {
      selectedNodes.add(gid);
    }
    if (isSome(state.modelReducer.edges[gid])) {
      selectedEdges.add(gid);
    }
  }

  const setsNotContainingSelection: Set<GID> = new Set();

  for (const setId in state.modelReducer.sets) {
    const set = state.modelReducer.sets[setId];
    const inSet = new Set();
    set.nodeIds.forEach((nid) => inSet.add(nid));
    set.edgeIds.forEach((eid) => inSet.add(eid));
    for (const selected of state.modelReducer.base.selected.shapes) {
      if (!inSet.has(selected)) {
        setsNotContainingSelection.add(setId);
      }
    }
  }

  const mapped = [];
  for (const setId of setsNotContainingSelection) {
    mapped.push(state.modelReducer.sets[setId]);
  }

  // returning the same empty array makes component re-renders more stable
  return mapped.length > 0 ? mapped : _emptyArray;
};

/**
 * Returns a list of all collapsed set ids
 * @param  modelReducer - modelReducer object from Redux state
 * @returns  - A list of set ids
 */
export const findCollapsedSets = (modelReducer: ModelReducer): GID[] => {
  return Object.keys(modelReducer.base.collapsed.sets);
};

/**
 * returns an array of unique set ids that have been collapsed and selected
 * @param  state - modelState.
 * @returns  collapsed and selected set ids.
 */
export const getCollapsedAndSelectedSets = (state: ModelReducer): GID[] => {
  const collapsedSets = state.base.collapsed.sets;
  return getSelectedSets(state).filter((id) => collapsedSets[id]);
};

/**
 * Finds any set that's associated with any member of a given set
 * @param  setId
 * @returns  - A list of unique set ids
 */
export function findAssociatedSets(
  setId: GID,
  model: ModelReducer = globalGetState().modelReducer
): GID[] {
  const associatedSets = new Set<GID>();

  const set = model.sets[setId];

  set.nodeIds.forEach((nodeId) => {
    const node = model.nodes[nodeId];
    node.setIds.forEach((sid) => associatedSets.add(sid));
  });

  set.edgeIds.forEach((edgeId) => {
    const edge = model.edges[edgeId];
    edge.setIds.forEach((sid) => associatedSets.add(sid));
  });

  associatedSets.delete(setId);

  return Array.from(associatedSets);
}

/**
 * returns all related shape ids for the given set ids, including collapset set shapes
 *
 */
export function getSetShapeIds(state: RootReducer, setIds: GID[]): GID[] {
  const model = state.modelReducer;
  const {
    sets,
    base: { collapsed },
  } = model;

  const shapeIds = setIds.reduce((shapeIds, setId) => {
    if (collapsed.sets[setId]) {
      shapeIds.push(collapsed.sets[setId]);
      return shapeIds;
    }
    const set = sets[setId];
    shapeIds = shapeIds.concat(set.nodeIds, set.edgeIds);

    // select the shape for any collapsed set fully contained within this set
    const contained = findAssociatedSets(set.id, model).filter(
      (sid) => collapsed.sets[sid] && setIsContained(sid, set.id, model)
    );
    shapeIds = shapeIds.concat(contained.map((sid) => collapsed.sets[sid]));
    return shapeIds;
  }, [] as GID[]);

  return shapeIds;
}

export function issueGetSetShapeIds(
  setIds: GID[]
): MetraThunkAction<void, void, GID[]> {
  return (_dispatch, getState) => {
    const model = getState().modelReducer;
    const {
      sets,
      base: { collapsed },
    } = model;
    const selectedSets = setIds.map((sid) => sets[sid]);
    let shapeIds: GID[] = [];
    selectedSets.forEach((set) => {
      if (collapsed.sets[set.id]) {
        shapeIds = shapeIds.concat(collapsed.sets[set.id]);
        return;
      }
      shapeIds = shapeIds.concat(set.nodeIds, set.edgeIds);

      // select the shape for any collapsed set fully contained within this set
      const contained = findAssociatedSets(set.id, model).filter(
        (sid) => collapsed.sets[sid] && setIsContained(sid, set.id, model)
      );
      shapeIds = shapeIds.concat(contained.map((sid) => collapsed.sets[sid]));
    });
    return shapeIds;
  };
}

/**
 * checks if every element
 */
function checkElements(a: string[], b: string[]): boolean {
  return a.length > 0 && a.every((id) => b.includes(id));
}

/**
 * check if a set contains collapsed sets within it
 */
export function checkSetContainsCollapsedSets(
  setId: GID,
  collapsedSets: GID[],
  modelState: ModelReducer = globalGetState().modelReducer
): Option<boolean> {
  const { sets } = modelState;
  const set = sets?.[setId] ?? null;
  if (isNone(set)) return null;
  const collapsedNodes = new Set<GID[]>();
  const collapsedEdges = new Set<GID[]>();
  collapsedSets.forEach((sid) => {
    let collapsedSet = sets[sid];
    collapsedNodes.add(collapsedSet.nodeIds);
    collapsedEdges.add(collapsedSet.edgeIds);
  });

  const nodeIds = Array.from(collapsedNodes).flat();
  const edgeIds = Array.from(collapsedEdges).flat();

  return (
    checkElements(set.nodeIds, nodeIds) && checkElements(set.edgeIds, edgeIds)
  );
}

/**
 * see if the given set contains any nodes who are also members of another
 * set that is currently collapsed
 */
export function checkSetContainsCollapsedMember(
  setId: GID,
  modelState: ModelReducer = globalGetState().modelReducer
): Option<boolean> {
  const {
    sets,
    nodes,
    base: { collapsed },
  } = modelState;
  const set = sets?.[setId] ?? null;
  if (isNone(set)) return null;
  return set.nodeIds.some((nid) =>
    nodes[nid].setIds.some((sid) =>
      // safely check if `sid` is a key in `modelReducer.base.collapsed.sets`
      Object.prototype.hasOwnProperty.call(collapsed.sets, sid)
    )
  );
}

export function checkSetContainsCollapsedSubset(
  setId: GID,
  modelState: ModelReducer = globalGetState().modelReducer
): Option<boolean> {
  const { sets } = modelState;
  const checkSet = sets?.[setId] ?? null;
  if (isNone(checkSet)) return null;
  const asscociatedSets = findAssociatedSets(setId, modelState);
  const subSets = [];
  asscociatedSets.forEach((sid) => {
    let relatedSet = sets[sid];
    if (relatedSet.nodeIds.every((nid) => checkSet.nodeIds.includes(nid))) {
      subSets.push(relatedSet);
    }
  });
  return subSets.length > 0;
}

/**
 * does a given set, contain any nodes
 */
export function checkSetContainsNodes(
  setId: GID,
  modelState: ModelReducer = globalGetState().modelReducer
): Option<boolean> {
  const { sets } = modelState;
  const set = sets?.[setId] ?? null;
  if (isNone(set)) return null;
  return set.nodeIds.length > 0;
}

/**
 * checks if a set's node members are all contained by other sets
 * @param setId - set Id to determine if node members all contained
 * @param [modelState] - current model state
 * @returns
 */
export function checkSetHasAllNodesContainedInCollapsed(
  setId: GID,
  modelState: ModelReducer = globalGetState().modelReducer
): Option<boolean> {
  const {
    sets,
    nodes,
    base: { collapsed },
  } = modelState;
  const set = sets?.[setId] ?? null;
  if (isNone(set)) return null;
  const collapsedSetKeys = Object.keys(collapsed.sets);
  const setNodes = set.nodeIds.map((nid) => nodes[nid]);
  return setNodes.every((node) =>
    node.setIds.some((sid) => collapsedSetKeys.includes(sid) && sid !== setId)
  );
}

/**
 * Checks if a set has exactly the same members as any other set
 * @param  setId - Id of set being checked
 * @param  setsToCheck - A list of set ids to check against
 * @param [modelState] - current model state
 * @returns
 */
export function checkSetHasIdenticalSet(
  setId: GID,
  setsToCheck: GID[],
  modelState: ModelReducer = globalGetState().modelReducer
): Option<boolean> {
  const { sets } = modelState;
  const set = sets?.[setId] ?? null;
  if (isNone(set)) return null;
  const setNodes = [...set.nodeIds].sort();
  const setEdges = [...set.edgeIds].sort();

  for (const otherSetId of setsToCheck) {
    if (setId === otherSetId) continue;
    const otherSet = sets[otherSetId];
    const otherSetNodes = [...otherSet.nodeIds].sort();
    const otherSetEdges = [...otherSet.edgeIds].sort();

    const identicalNodes = isEqual(setNodes, otherSetNodes);
    const identicalEdges = isEqual(setEdges, otherSetEdges);
    if (identicalNodes && identicalEdges) return true;
  }

  return false;
}

/**
 * Checks if a set is completely contained within any collapsed set
 * @param setId - The ID of the set being checked
 * @param [modelState] - current model state
 * @returns true if contained completely otherwise false
 */
export function checkSetContainedInCollapsed(
  setId: GID,
  collapsedSets: GID[],
  modelState: ModelReducer = globalGetState().modelReducer
): boolean {
  for (let i = collapsedSets.length; i--; ) {
    const sid = collapsedSets[i];
    if (setId !== sid && setIsContained(setId, sid, modelState)) return true;
  }
  return false;
}

/**
 * get the collaped sets contained
 * @param expandingSetId - the expanding set
 * @param checkSetId - the set to check
 * @param collapsedSets - the collapsed set ids to check against
 * @param [modelState] - current model state
 */
export function getContainingCollapsedSets(
  expandingSetId: GID,
  checkSetId: GID,
  collapsedSets: GID[],
  modelState: ModelReducer = globalGetState().modelReducer
): GID[] {
  const containingCollapsedSetIds: GID[] = [];
  collapsedSets.forEach((sid) => {
    if (
      checkSetId !== sid &&
      expandingSetId !== sid &&
      setIsContained(checkSetId, sid, modelState)
    ) {
      containingCollapsedSetIds.push(sid);
    }
  });
  return containingCollapsedSetIds;
}

/**
 * performs set collapse/expansion toggling.
 * @returns hiding and unhiding contained sets
 */
export function ecsSetsCollapseToggler({
  collapsing,
  expanding,
  removed,
  collapsedShapes,
  modelState,
}: {
  collapsing: CollapseTuple[];
  expanding: CollapseTuple[];
  removed: ShapeConfig[];
  collapsedShapes: CollapsedConfig[];
  modelState: ModelReducer;
}): MetraThunkAction<
  unknown,
  unknown,
  [
    hidingSetIds: GID[],
    unhidingSetIds: GID[],
    shapeTransitions: Record<GID, Transition>,
    deltaPositions: Record<GID, Vector2>
  ]
> {
  return (dispatch) => {
    const collapsedSetShapes: ShapeConfig[] = [];
    const deltaPositions: Record<GID, Vector2> = {};

    const collapsingSetIds = collapsing.map(([, setId]) => setId);
    const collapsedSetIds = [
      ...findCollapsedSets(modelState),
      ...collapsingSetIds,
    ].filter((sid) => {
      return !expanding.some(([, setId]) => setId === sid);
    });

    let hidingCollapsed: GID[] = [];
    let unhidingCollapsed: GID[] = [];

    collapsing.forEach(([, collapsingSetId]) => {
      const associatedSets = findAssociatedSets(collapsingSetId, modelState);

      const inBoth = associatedSets.filter((setId) =>
        collapsedSetIds.includes(setId)
      );

      const containedSets = inBoth.filter((setId) =>
        setIsContained(setId, collapsingSetId, modelState)
      );

      hidingCollapsed = hidingCollapsed.concat(containedSets);

      containedSets.forEach((setId) => {
        const shapeId = modelState.base.collapsed.sets[setId];

        let shape = modelState.shapes[shapeId];
        if (shape) {
          shape.visible = false;
          collapsedSetShapes.push(shape);
        } else {
          const maybeShape = collapsedShapes.find(
            (config) => config.setId === setId
          );
          if (maybeShape) {
            shape = maybeShape as ShapeConfig;
            shape.visible = false;
            collapsedSetShapes.push(shape);
          }
        }
      });
    });

    const invisibleSetShapes: ShapeConfig[] = [];

    expanding.forEach(([shapeId, invisibleSetId]) => {
      const expandingShape = modelState.shapes[shapeId];
      if (!isCollapsedSet(expandingShape)) return;
      const delta = Vector2.fromPoint(expandingShape.pos).sub(
        expandingShape.startPos
      );
      deltaPositions[invisibleSetId] = delta;

      const associatedSets = findAssociatedSets(invisibleSetId, modelState);
      const collapsedSets = findCollapsedSets(modelState);
      const inBoth = associatedSets.filter((setId) =>
        collapsedSets.includes(setId)
      );

      const containedSets = inBoth.filter((setId) =>
        setIsContained(setId, invisibleSetId, modelState)
      );

      unhidingCollapsed = unhidingCollapsed.concat(containedSets);

      containedSets.forEach((setId) => {
        const shapeId = modelState.base.collapsed.sets[setId];
        const setIsVisible = modelState.sets[setId].visible;
        const collapsedShape = modelState.shapes[shapeId];
        const assc = findAssociatedSets(setId, modelState);
        const inBoth = assc.filter((setId) => collapsedSets.includes(setId));
        const containingCollapsedSetIds = getContainingCollapsedSets(
          invisibleSetId,
          setId,
          inBoth,
          modelState
        );

        let contained = containingCollapsedSetIds.some(
          (sid) =>
            modelState.shapes[modelState.base.collapsed.sets[sid]].visible ===
            true
        );

        if (!contained && setIsVisible) collapsedShape.visible = true;

        collapsedShape.pos = delta.add(collapsedShape.pos).toObject();

        invisibleSetShapes.push(collapsedShape);
      });
    });

    // figure out which shapes are going visible / hidden based on
    // set collapse / expansion
    const hidden = collapseTupleShapes(collapsing, modelState);
    let visible = expandTupleShapes(expanding, modelState, deltaPositions);

    // update the shapes first, so the edges can update to their new visibility
    dispatch(
      updateShapesByConfig([
        ...hidden,
        ...visible,
        ...collapsedSetShapes,
        ...invisibleSetShapes,
      ])
    );

    // get the node transitions
    // this is used to update these node's edges
    const shapeCollapseTransitions: Record<GID, Transition> = {};
    for (let i = hidden.length; i--; ) {
      const shape = hidden[i];
      if (isNode(shape) || isEdge(shape)) {
        shapeCollapseTransitions[shape.id] = {
          before: false,
          after: true,
        };
      }
    }
    for (let i = visible.length; i--; ) {
      const shape = visible[i];
      if (isNode(shape) || isEdge(shape)) {
        shapeCollapseTransitions[shape.id] = {
          before: true,
          after: false,
        };
      }
    }

    dispatch(createCollapsedSets(collapsing));
    dispatch(deleteCollapsedSets(expanding));
    dispatch(addShapesByConfig(collapsedShapes));
    dispatch(deleteShapesByConfig(removed));

    return [
      hidingCollapsed,
      unhidingCollapsed,
      shapeCollapseTransitions,
      deltaPositions,
    ];
  };
}

/**
 * @return true if shape is contained in collapsed set
 */
export function shapeInCollapsedSet(
  shapeId: GID,
  modelState: ModelReducer = globalGetState().modelReducer
): boolean {
  const { base, nodes, edges } = modelState;
  let found = false;
  if (
    nodes[shapeId]?.setIds?.some((setId) =>
      Object.prototype.hasOwnProperty.call(base.collapsed.sets, setId)
    ) ||
    edges[shapeId]?.setIds?.some((setId) =>
      Object.prototype.hasOwnProperty.call(base.collapsed.sets, setId)
    )
  ) {
    found = true;
  }
  return found;
}

/**
 * @return true if shape is contained in collapsed and visible set
 */
export function shapeInCollapsedVisibleSet(
  shapeId: GID,
  modelState: ModelReducer = globalGetState().modelReducer
): boolean {
  const { base, nodes, edges, sets } = modelState;
  let found = false;
  if (
    nodes[shapeId]?.setIds?.some(
      (setId) =>
        Object.prototype.hasOwnProperty.call(base.collapsed.sets, setId) &&
        sets[setId].visible === true
    ) ||
    edges[shapeId]?.setIds?.some(
      (setId) =>
        Object.prototype.hasOwnProperty.call(base.collapsed.sets, setId) &&
        sets[setId].visible === true
    )
  ) {
    found = true;
  }
  return found;
}

/**
 * returns all the subsets of the given set with setId
 * @param  setId
 * @returns  subsets
 */
export function getSubSets(
  setId: GID,
  modelState: ModelReducer = globalGetState().modelReducer
): GID[] {
  const { sets } = modelState;
  const subSets: GID[] = [];
  Object.entries(sets).forEach((set) => {
    const [subSetId, subSet] = set;
    if (setId !== subSetId && isSubSet(sets[setId], subSet))
      subSets.push(subSetId);
  });
  return subSets;
}

/**
 * given a set (superset) and another set (potential subset), check if all
 * the subset's members are included in the superset
 */
export function isSubSet(set: ModelSet, subset: ModelSet): boolean {
  return (
    subset.edgeIds.every((edge) => set.edgeIds.includes(edge)) &&
    subset.nodeIds.every((node) => set.nodeIds.includes(node))
  );
}

/**
 * given a shape, return the sets that it is a member which
 * are also currently visible
 */
export function shapeVisibleSets(
  shapeId: GID,
  modelState: ModelReducer = globalGetState().modelReducer
): GID[] {
  const { sets, nodes, edges } = modelState;
  const shapeSetIds = nodes[shapeId]?.setIds || edges[shapeId]?.setIds || [];
  return shapeSetIds.filter((sid) => sets[sid].visible === true);
}

/**
 * toggles visibility to hidden of all subshapes of collapsing set tuples.
 * @param collapsing - collapsing set tuples.
 * @param modelState - Parameter description.
 * @returns all shapes becoming hidden.
 */
export function collapseTupleShapes(
  collapsing: CollapseTuple[],
  modelState: ModelReducer
): ShapeConfig[] {
  const results: ShapeConfig[] = [];
  return collapsing.reduce((shapes, [, setId]) => {
    return [
      ...shapes,
      ...modelState.sets[setId].edgeIds.map((edgeId) => {
        const edgeConfig = { ...modelState.shapes[edgeId] };
        edgeConfig.visible = false;
        return edgeConfig;
      }),
      ...modelState.sets[setId].nodeIds.map((nodeId) => {
        const nodeConfig = { ...modelState.shapes[nodeId] };
        nodeConfig.visible = false;
        return nodeConfig;
      }),
    ];
  }, results);
}

/**
 * toggles visibility to visible of all subshapes of expanding set tuples.
 * @param expanding - expanding set tuples.
 * @param modelState - Parameter description.
 * @returns all shapes becoming visible.
 */
export function expandTupleShapes(
  expanding: CollapseTuple[],
  modelState: ModelReducer,
  deltaPositions: Record<GID, Vector2>
): ShapeConfig[] {
  const results: ShapeConfig[] = [];
  return expanding.reduce((shapes, [_collapsedShapeId, setId]) => {
    // we're expanding this set, so calculate the move-delta to
    // apply to each node in this set.
    const delta = deltaPositions[setId];

    return [
      ...shapes,
      ...modelState.sets[setId].edgeIds.map((edgeId) => {
        const edgeConfig = { ...modelState.shapes[edgeId] };
        if (!isEdge(edgeConfig)) return edgeConfig;
        // only show edge shape if it has visible set membership
        edgeConfig.visible = true;
        // update waypoints by delta
        edgeConfig.waypoints = edgeConfig.waypoints.map((tup) =>
          delta.addTuple(tup).toTuple()
        );
        return edgeConfig;
      }),
      ...modelState.sets[setId].nodeIds.map((nodeId) => {
        const nodeConfig = { ...modelState.shapes[nodeId] };
        const node = { ...modelState.nodes[nodeId] };
        nodeConfig.pos = delta.add(nodeConfig.pos).toObject();
        const visSets = shapeVisibleSets(nodeId, modelState);
        const collapsedVisibleShapeSets = visSets.filter(
          (sid) => modelState.sets[sid].collapsed && sid !== setId
        );
        const nodeExposedInVisibleSet = node.setIds.every(
          (sid) => !collapsedVisibleShapeSets.includes(sid) || setId === sid
        );
        if (nodeExposedInVisibleSet) {
          nodeConfig.visible = true;
        }
        return nodeConfig;
      }),
    ];
  }, results);
}

/**
 * @param selectedSetIds
 * @param shapes - all model shapes
 * @param collapsedSetMap - links setIds to their collapsed set shapes, if they exist
 * @returns collapsing, expanding, and removed sets
 */
export function buildCollapsedExpandedSetTuples(
  selectedSetIds: GID[],
  shapes: Record<GID, ShapeConfig>,
  collapsedSetMap: Record<GID, GID>
): [
  collapsing: CollapseTuple[],
  expanding: CollapseTuple[],
  removedShapes: ShapeConfig[]
] {
  const collapsing: CollapseTuple[] = [];
  const expanding: CollapseTuple[] = [];
  const removed: ShapeConfig[] = [];
  const selectedSetIdCount = selectedSetIds.length;
  for (let i = 0; i < selectedSetIdCount; i++) {
    const setId = selectedSetIds[i];
    const collapsedId = collapsedSetMap[setId];
    if (!collapsedId) {
      collapsing.push([gid(), setId]);
    } else {
      expanding.push([collapsedId, setId]);
      removed.push(shapes[collapsedId]);
    }
  }
  return [collapsing, expanding, removed];
}

/**
 * returns all related shape ids for the given set id
 *
 */
export function getAllSetAssociatedShapeIds(
  setId: GID,
  state: RootReducer
): Set<GID> {
  const { sets, nodes } = state.modelReducer;
  const results = new Set<GID>();

  const modelSet = sets?.[setId];
  if (isNone(modelSet)) return results;

  modelSet.nodeIds.forEach((nodeId) => {
    results.add(nodeId);
    const maybeNode = nodes?.[nodeId];
    if (isNone(maybeNode)) return;
    maybeNode.edgeIds.forEach((edgeId) => results.add(edgeId));
  });
  modelSet.edgeIds.forEach((edgeId) => results.add(edgeId));

  return results;
}

/**
 * get the sets this set contains
 */
export function getContainedSets(
  setId: GID,
  modelState: ModelReducer
): ModelSet[] {
  return Object.values(modelState.sets).filter((set) =>
    setIsContained(set.id, setId, modelState)
  );
}

export function splitSets(
  sets: Record<GID, ModelSet>,
  _allSets: Record<GID, ModelSet>,
  model: ModelReducer
): [
  uncollapsed: Record<GID, ModelSet>,
  collapsed: Record<GID, ModelSet>,
  topSets: Set<GID>
] {
  const [uncollapsedSets, collapsedSets] = Object.reduce(
    sets,
    ([uncollapsed, collapsed], [_key, value]) => {
      if (value.collapsed) {
        collapsed[value.id] = value;
      } else {
        uncollapsed[value.id] = value;
      }
      return [uncollapsed, collapsed];
    },
    [{}, {}] as [
      uncollapsed: Record<GID, ModelSet>,
      collapsed: Record<GID, ModelSet>
    ]
  );

  // const allSetIds = Object.keys(allSets);
  const topSets = Object.reduce(
    collapsedSets,
    (topSets, [id, _set]) => {
      return topSets.add(topContainerSet(id, model.base.selected.sets, model));
    },
    new Set<GID>()
  );

  return [uncollapsedSets, collapsedSets, topSets];
}

/**
 * Checks if every member of a set is completely contained within another set
 * @param querySetId set we're checking
 * @param targetSetId set we're checking against
 * @returns true if query set is wholly contained by the targetSet
 */
export function setIsWhollyContained(
  querySetId: GID,
  targetSetId: GID,
  model: ModelReducer
): boolean {
  const targetSet = model.sets[targetSetId];
  const querySet = model.sets[querySetId];

  // IF query set does not exist OR has no members
  // OR target set does not exist OR has no members
  // THEN query set cannot be contained in target set
  if (
    !querySet ||
    !targetSet ||
    (querySet?.nodeIds.length === 0 && querySet?.edgeIds.length === 0) ||
    (targetSet?.nodeIds.length === 0 && targetSet?.edgeIds.length === 0)
  ) {
    return false;
  }

  // IF target set does not contain every node of query set
  // THEN query set cannot be contained in target set
  const targetNodeSet = new Set(targetSet.nodeIds);
  for (let i = querySet.nodeIds.length; i--; ) {
    if (!targetNodeSet.has(querySet.nodeIds[i])) return false;
  }

  // IF target set does not contain every edge of query set
  // THEN query set cannot be contained in target set
  const targetEdgeSet = new Set(targetSet.edgeIds);
  for (let i = querySet.edgeIds.length; i--; ) {
    if (!targetEdgeSet.has(querySet.edgeIds[i])) return false;
  }

  // query set must contain target set
  return true;
}

export function updateSetColors(
  entity: Entity,
  hiddenSets: Set<GID>,
  ecs: EcsInstance,
  model: ModelReducer,
  visibleSets?: Set<GID>
): void {
  const [setColors, shapeID, edge, node, collapsedSet] = ecs.retrieve(entity, [
    SetColors,
    ShapeID,
    Edge,
    Node,
    CollapsedSet,
  ]);
  if (!setColors || !shapeID) return;

  let setIds: Set<GID> = new Set();

  if (isSome(edge)) {
    const edgeSetIds: GID[] = model.edges[shapeID.value].setIds;
    // coerce setIds to have no more than one value, to reflect structure of setColor component for edges
    // take only the top-most visible set
    for (let i = edgeSetIds.length; i--; ) {
      const edgeSetId = edgeSetIds[i];
      if (!hiddenSets.has(edgeSetId)) {
        if (visibleSets && !visibleSets.has(edgeSetId)) {
          continue;
        }
        setIds.add(edgeSetId);
        break;
      }
    }
  } else if (isSome(node)) {
    if (visibleSets) {
      setIds = visibleSets;
    } else {
      const nodeData = model.nodes[shapeID.value];
      setIds = new Set(nodeData.setIds);
    }
  } else if (isSome(collapsedSet)) {
    setIds = setIds.add(model.base.collapsed.shapes[shapeID.value]);
  }

  const sets = model.sets;
  setColors.values = [];
  for (const setId of setIds) {
    if (hiddenSets.has(setId)) continue;
    setColors.values.push(colorParse(sets[setId].color));
  }
  ecs.update(setColors);
}

/**
 * show a collapsed set's ring
 * @param setId setId to show color for
 * @param hiddenSets sets that should be hidden
 * @param ecs current ecs instnace
 * @param model current model
 */
export function updateCollapsedSetColors(
  setId: GID,
  hiddenSets: Set<GID>,
  ecs: EcsInstance,
  model: ModelReducer
): void {
  const entity = ecs.tagManager.getEntityByTag(
    model.base.collapsed.sets[setId]
  );
  if (!entity) return;
  updateSetColors(entity, hiddenSets, ecs, model);
}

/**
 * Checks if a set is completely contained within any collapsed set
 * @param querySetId - The ID of the set being checked
 * @param data - the current transition data
 * @returns true if set is in a collapsed set
 */
export function setContainedInOtherCollapsedSet(
  querySetId: GID,
  data: TransitionData
): boolean {
  const { model } = data;
  const collapsedSets = Object.keys(model.base.collapsed.sets);

  for (let i = collapsedSets.length; i--; ) {
    const targetSetId = collapsedSets[i];
    if (querySetId === targetSetId) continue;
    const isCollapsed = maybeSetCollapsed(targetSetId, data);
    // IF target set is transitioning to uncollapsed
    // THEN skip target set check
    if (!isCollapsed) continue;
    if (setIsWhollyContained(querySetId, targetSetId, model)) {
      return maybeSetVisible(targetSetId, data);
    }
  }
  return false;
}

/**
 * Generates a set of colors already used by MeTRA® Model Sets
 * @param  modelSets
 * @returns  hex-like colors already used by model sets
 */
export const getUsedColors = (modelSets: ModelReducer['sets']): Set<string> => {
  const usedColors = new Set<string>();
  for (const currSet of Object.values(modelSets)) usedColors.add(currSet.color);
  return usedColors;
};

/**
 * Finds a color that has not been used by another set
 * @param  usedColors colors already used by sets
 * @param  colorOptions list of available colors
 * @returns  hex-like color string. Empty if all color options used.
 */
export const getUnusedColor = (
  usedColors: Set<string>,
  colorOptions: string[]
): string => {
  const colorsCount = colorOptions.length;
  for (let i = colorsCount; i--; ) {
    let color = colorOptions[colorsCount - (i + 1)];
    if (!usedColors.has(color)) return color;
  }
  return '';
};

/**
 * Generates a random color for sets
 * @returns  hex-like color string
 */
export const getRandomColor = (): string => {
  const colorHex = Math.floor(Math.random() * HEX_COLOR.WHITE);
  return `#${colorHex.toString(16).padStart(6, '0')}`;
};
