import cloneDeep from 'clone-deep';
import { TABLE } from 'modules/common';
import { globalGetState, dispatcher } from 'utils/utils-extra';
import { compareArrays } from 'utils/utils';
import { MODEL } from 'utils/constants';
import { SHAPE } from 'utils/constants-extra';
import { updateCoords } from 'modules/model/properties/selection';
import { intersection } from 'helpers/utils';
import { issueSelectExactly } from 'actions/actions-helpers';

/**
 * UTILS
 *
 */

/**
 * @param {boolean} show
 * @returns {import('types').MetraSimpleAction<boolean>}
 */
export const setShowSaveFilterFlyout = (show) => ({
  type: MODEL.SET_SHOW_SAVE_FILTER_FLYOUT,
  payload: show,
});

export const setSelectedFilter = (filter) => ({
  type: MODEL.SET_SELECTED_FILTER,
  payload: filter,
});

export const clearSelectedFilter = () =>
  setSelectedFilter({
    id: '-1',
    name: 'Filter Views',
    entities: [],
    filterUnpopulated: false,
    schemas: [],
  });

export const setFilterOpacity = (opacity) => ({
  type: MODEL.SET_FILTER_OPACITY,
  payload: opacity,
});

export const setSelectionEmphasis = (alpha) => ({
  type: MODEL.SET_SELECTION_EMPHASIS,
  payload: alpha,
});

/**
 * applies all filters
 * @returns updated tableItems object
 */
export const applyTableFilters = (tableItems, tableFilter) => {
  const newTableItems = cloneDeep(tableItems);
  let filtered = applyEntitiesFilter(newTableItems, tableFilter);
  filtered = applySchemasFilter(filtered, tableFilter);
  const state = globalGetState().modelReducer;
  if (state.tableFilter.hideUnpopulated) {
    const schemasFilter = filterUnpopulatedSchemas(tableFilter.entities, state);
    filtered = applySchemasFilter(filtered, schemasFilter);
  }
  dispatcher(setTableItems(filtered));
  return filtered;
};

export const setTableItems = (tableItems) => async (dispatch) => {
  await dispatch({
    type: MODEL.SET_TABLE_ITEMS,
    payload: { tableItems },
  });
  dispatch(updateCoords());
};

/**
 * applies entities filter
 * @returns updated tableItems object
 */
export const applyEntitiesFilter = (tableItems, tableFilter) => {
  if (tableFilter.entities.length > 0) {
    tableItems.entities.nodes = intersection(
      tableItems.entities.nodes,
      tableFilter.entities
    );
    tableItems.entities.edges = intersection(
      tableItems.entities.edges,
      tableFilter.entities
    );
    tableItems.entities.sets = intersection(
      tableItems.entities.sets,
      tableFilter.entities
    );
  }
  return tableItems;
};

/**
 * applies schemas filter
 * @returns updated tableItems object
 */
export const applySchemasFilter = (tableItems, tableFilter) => {
  if (tableFilter.schemas.length > 0) {
    tableItems.schemas.nodes = intersection(
      tableItems.schemas.nodes,
      tableFilter.schemas
    );
    tableItems.schemas.edges = intersection(
      tableItems.schemas.edges,
      tableFilter.schemas
    );
    tableItems.schemas.sets = intersection(
      tableItems.schemas.sets,
      tableFilter.schemas
    );
  }
  return tableItems;
};

/**
 * filters entities to only those currently selected
 * @returns {{ entities: Array<string> }} entities object with an array of entity ids
 */
export const filterSelectedEntities = (model) => {
  const { base, shapes, sets } = model;
  let collapsedSetShapes = [];
  let filterableShapes = [];
  base.selected.shapes.forEach((sid) => {
    // Prevent drawing lines from being added to tableFilter.entities.
    // This also prevents lines from being added/saved to Filters.
    if (shapes[sid].type !== SHAPE.DRAWING) {
      filterableShapes.push(sid);
    }
    if (shapes[sid].type === SHAPE.COLLAPSED_SET) {
      let collapsedSet = base.collapsed.shapes[sid];
      let setObj = sets[collapsedSet];
      collapsedSetShapes = [...setObj.nodeIds, ...setObj.edgeIds, setObj.id];
    }
  });

  const entities = [
    ...filterableShapes,
    ...model.base.selected.sets,
    ...collapsedSetShapes,
  ];
  return { entities };
};

/**
 * filters unpopulated schemas leaving only populated ones based on passed entities
 * @returns {{ schemas: Array<string>}} schemas object with an array of schema ids
 */
export const filterUnpopulatedSchemas = (entities, model) => {
  // we need to know which schemas actually have assigned values
  const usedSchemas = Object.entries(model.propValues)
    // first figure out which entities are actually accessible
    .filter(([entityId]) => {
      if (entities.length > 0) {
        return entities.includes(entityId);
      } else {
        return true;
      }
    })
    // now down-select to only the possible schemas for those entities
    .reduce(valuesToSchemas, {});

  // determine which schemas to keep based on whether they have values
  const schemas = Object.values(model.propSchemas).reduce(
    (acc, schema) =>
      // only include schemas that are used and contain at least one key-value pair
      usedSchemas[schema.id] && Object.keys(usedSchemas[schema.id]).length > 0
        ? [...acc, schema.id]
        : acc,
    // HACK: 'null' default is used as a way to down-sort to 'no-props'
    // when a filter is applied. i.e., even if nothing is explicitly filtered
    // it will always have at least a length of 1, allowing all prop schema to
    // be filtered in 'filterSchemas' above, but not when no filter is applied
    // when the length would be 0
    ['null']
  );

  return { schemas };
};

/**
 * converts a property value tuple key-values by schema id
 * @returns {{[schemaId: string]: {[entityId: string]: string}}}
 */
export const valuesToSchemas = (usedSchemas, [entityId, propValues]) => {
  Object.entries(propValues).forEach(([schemaId, value]) => {
    if (!usedSchemas?.[schemaId]) usedSchemas[schemaId] = {};
    usedSchemas[schemaId] = {
      ...usedSchemas[schemaId],
      // if there is no value or its an empty character, do not include it
      ...(value && value !== '' ? { [entityId]: value } : {}),
    };
  });
  return usedSchemas;
};

/**
 * applies filters based on selection state
 * @type {import('types').ThunkActionFunc<[
   filterAlpha?: number,
   filterIds?: GID[],
   filterSchemas?: GID[],
   filterUnpopulated?: boolean
 ],
   import('types').MetraSimpleAction<import('types').TableFilterReducer>
 >}
 */
export const applyFilter =
  (filterAlpha, filterIds, filterSchemas = [], filterUnpopulated = false) =>
  (dispatch, getState) => {
    const model = getState().modelReducer;
    let entities = {};

    if (filterIds) {
      entities = { entities: filterIds };
    } else {
      entities = filterSelectedEntities(model);
    }

    // Only toggle "Hide Empty Columns" if filter specifies, otherwise keep current behavior
    const hideUnpopulated =
      filterUnpopulated ?? model.tableFilter.hideUnpopulated;

    // we are changing the filter - see if the old filter-selection is still valid
    dispatch((d2, getState) => {
      const newFilter = entities.entities;
      const oldFilter =
        getState().modelReducer.base.selectedFilter.entities ?? [];
      if (!compareArrays(oldFilter, newFilter)) {
        return d2(clearSelectedFilter());
      }
    });
    dispatch(setFilterOpacity(filterAlpha));

    // Selection should be cleared when a filter is applied.
    dispatch(issueSelectExactly([]));

    return dispatch({
      type: TABLE.FILTER.UPDATE,
      payload: {
        ...entities,
        schemas: filterSchemas,
        alpha: filterAlpha,
        hideUnpopulated: hideUnpopulated,
      },
    });
  };

/**
 * removes deleted entities
 */
export const deleteFilteredEntities = (filteredIds, idsToDelete) => {
  // Convert idsToDelete array to a Set for fast lookup
  const idsToDeleteSet = new Set(idsToDelete);

  // Maintain the ids that are not in idsToDeleteSet
  const ids = filteredIds.filter((id) => !idsToDeleteSet.has(id));

  if (ids.length !== filteredIds.length) {
    dispatcher({
      type: TABLE.FILTER.UPDATE,
      payload: { entities: ids },
    });
  }
};

/**
 * toggles hideUnpopulated boolean
 */
export const toggleHideUnpopulated = () => ({
  type: TABLE.FILTER.TOGGLE_ALL,
});

/**
 * clear all filters
 */
export const clearFilter = () => (dispatch) => {
  return dispatch({
    type: TABLE.FILTER.CLEAR,
  });
};

/**
 * clear schema filters
 */
export const clearSchemaFilter = () => (dispatch) => {
  return dispatch({
    type: TABLE.FILTER.CLEAR_SCHEMAS,
  });
};
