import { Matrix, Rectangle } from 'pixi.js';
import { ModelReducer } from 'types';
import { isEdge } from 'modules/model/shape/shape-helpers';
import {
  Asset,
  Bounds,
  CollapsedSet,
  Edge,
  Color,
  Position,
  Segment,
  Segments,
  Selected,
  SetColors,
  ShapeID,
  Spatial,
  Style,
  Waypoint,
  Waypoints,
  Renderable,
  Related,
  Width,
  Sprite,
} from 'engine/components';
import { LineRange } from 'engine/range';
import { allSome, isNone, isSome } from './utils';
import { Vector2 } from 'utils/vector';
import { lineIntersect } from 'utils/utils';
import type { EcsInstance } from 'ecs/EcsInstance';
import type { Entity } from 'ecs/Entity';
import type { Component } from 'ecs/Component';
import { EDGE_STYLE, HEX_COLOR } from 'utils/constants';

export declare type EdgeMetadata = {
  adjust: number;
  bounds: Rectangle;
  boundLines: Vector2[][];
  circle: boolean;
  direction: Vector2;
  from: Vector2;
  padding: number;
  square: boolean;
  to: Vector2;
  triangle: boolean;
};

/**
 * The required padding offsets for triangle set colors follows the proportions of the sides of 30:60:90 triangle
 * These padding offsets are 1:sqrt(3):2
 * 1 and 2 are trivial to call. We calculation sqrt(3) here and reuse it below.
 */
const sqrtThree = Math.sqrt(3);

/**
 *
 * THIS FILE IS TO COLLECT VARIOUS HELPERS THAT ARE USEFULL TO ONE OR MORE SYSTEMS
 *
 */

/**
 * returns rectilinear sub-vector tuple for the two vectors
 */
export function getRectilinearVectors(
  from: Vector2,
  to: Vector2
): [Vector2, Vector2, Vector2] {
  let mid!: Vector2;
  const deltaX = Math.abs(to.x - from.x);
  const deltaY = Math.abs(to.y - from.y);
  if (deltaX >= deltaY) {
    // go vertical first then horizontal
    mid = new Vector2(from.x, to.y);
  } else {
    // go horizontal first then vertical
    mid = new Vector2(to.x, from.y);
  }
  return [from, mid, to];
}

/**
 * adjust a rectilinear vector so that it does not double-back and makes better
 * vertical/horizontal heading choices. We prefer that the segments attempt to
 * continue in the same cardinality of the prior segment (if possible)
 */
export function correctRectilinearVectors(
  priorFrom: Vector2,
  priorTo: Vector2,
  from: Vector2,
  mid: Vector2,
  to: Vector2
): [Vector2, Vector2, Vector2] {
  let newMid!: Vector2;

  const dir1 = from.towards(mid);
  const dir2 = priorFrom.towards(priorTo);
  const dot = dir1.dot(dir2);
  // will be 0 if horizontal
  const horiz1 = dir1.dot(new Vector2(0, 1));
  const horiz2 = dir2.dot(new Vector2(0, 1));

  if (dot > 0) {
    // same direction, choose to continue in that direction
    if (horiz2 === 0) {
      // go horizontal first then vertical
      newMid = new Vector2(to.x, from.y);
    } else {
      // go vertical first then horizontal
      newMid = new Vector2(from.x, to.y);
    }
  } else if (dot < 0) {
    // doubling-back, choose a perpendicular direction first
    if (horiz1 === 0) {
      // go vertical first then horizontal
      newMid = new Vector2(from.x, to.y);
    } else {
      // go horizontal first then vertical
      newMid = new Vector2(to.x, from.y);
    }
  } else {
    // dot = 0 i.e., perpendicular, try to continue previous direction
    if (horiz2 === 0) {
      // go horizontal first then vertical
      newMid = new Vector2(to.x, from.y);
    } else {
      // go vertical first then horizontal
      newMid = new Vector2(from.x, to.y);
    }

    // we could have chosen... poorly, so re-validate
    const dir3 = from.towards(newMid);
    const dotCheck = dir3.dot(dir2);
    if (dotCheck < 0) {
      // oops, bad choice! flip it!
      if (horiz2 === 0) {
        // go vertical first then horizontal
        newMid = new Vector2(from.x, to.y);
      } else {
        // go horizontal first then vertical
        newMid = new Vector2(to.x, from.y);
      }
    }
  }

  return [from, newMid, to];
}

/**
 * Check whether two connected entities are overlapping,
 * causing their edges to be reversed from their expected arrangement.
 * eg) Node A is the starting entity and connected to Node B- the ending entity.
 * Node A is to the left of Node B. However, the starting point of the edge is
 * to the right of its ending point. The edge is reversed, indicating there is
 * overlap in its connected entities.
 *
 * Subtract the positions of the edges and entities to find their relative
 * positions. Multiply their components and check whether either result is less
 * than or equal to zero, indicating that the signs do not match- they point in
 * different directions.
 */
export function edgeEntitiesOverlap(
  entityPosA: Vector2,
  entityPosB: Vector2,
  edgeStart: Vector2,
  edgeEnd: Vector2
): boolean {
  if (!edgeEnd) return true;
  const entityDiff = entityPosB.sub(entityPosA);
  const edgeDiff = edgeEnd.sub(edgeStart);
  return entityDiff.x * edgeDiff.x <= 0 && entityDiff.y * edgeDiff.y <= 0;
}

/**
 * Return an array of arrays of Vectors representing the lines for this
 * entity's bounding box.
 */
export function edgeEntityBoundLines(metadata: EdgeMetadata): EdgeMetadata {
  const {
    from: { x, y },
    bounds: { width, height },
    padding = 0,
  } = metadata;
  const hw = width / 2 + padding;
  const hh = height / 2 + padding;
  metadata.boundLines = [
    [new Vector2(x - hw, y - hh), new Vector2(x + hw, y - hh)], // top
    [new Vector2(x - hw, y + hh), new Vector2(x + hw, y + hh)], // bottom
    [new Vector2(x - hw, y - hh), new Vector2(x - hw, y + hh)], // left
    [new Vector2(x + hw, y - hh), new Vector2(x + hw, y + hh)], // right
  ];
  return metadata;
}

export function edgeEntityTriangleLines(metadata: EdgeMetadata): EdgeMetadata {
  const {
    from: { x, y },
    bounds: { width, height },
    padding = 0,
  } = metadata;
  const hw = width / 2;
  const hh = height / 2;
  metadata.boundLines = [
    [
      new Vector2(x, y - hh - padding * 2),
      new Vector2(x - hw - padding * sqrtThree, y + hh + padding),
    ], //left
    [
      new Vector2(x, y - hh - padding * 2),
      new Vector2(x + hw + padding * sqrtThree, y + hh + padding),
    ], //right
    [
      new Vector2(x - hw - padding * sqrtThree, y + hh + padding),
      new Vector2(x + hw + padding * sqrtThree, y + hh + padding),
    ], //bottom
  ];
  return metadata;
}

/**
 * Returns the point where the given vector
 * (originating at the entity's center) intersects the given entity.
 * If no point exists (IE the vector is entirely within the entity's bounds)
 * return null
 **/
export function edgeEntityIntersection({
  from,
  to,
  boundLines,
}: EdgeMetadata): Vector2 | null {
  const { x: x1, y: y1 } = from;
  const { x: x2, y: y2 } = to;

  let intersect: { x: number; y: number } | null = null;
  for (let i = boundLines.length; i--; ) {
    const [start, end] = boundLines[i];
    const { x: x3, y: y3 } = start;
    const { x: x4, y: y4 } = end;
    intersect = lineIntersect(x1, y1, x2, y2, x3, y3, x4, y4);
    if (intersect) break;
  }

  // returns null if the vector is entirely within the entity's bounds
  return intersect ? Vector2.fromPoint(intersect) : null;
}

export function entityIsCircle(ecs: EcsInstance, entity: Entity): boolean {
  const [collapsedSet, asset] = ecs.retrieve(entity, [CollapsedSet, Asset]);
  return !collapsedSet && isSome(asset) && asset.value === 'default';
}

export function constructEdgeMetadata(
  ecs: EcsInstance,
  entity: Entity,
  from: Vector2,
  to: Vector2,
  adjust: number,
  isWaypoint?: boolean
): EdgeMetadata {
  const [_setColors, bounds, asset] = ecs.retrieve(entity, [
    SetColors,
    Bounds,
    Asset,
  ]);
  const direction = from.towards(to);

  // add padding for any set rings the entity may have
  // let ringCount = 0;
  // if (setColors && !setColors.hidden) ringCount = setColors.values.length;
  // const padding = ringCount * SIZING.SET_RING_WIDTH;

  // calculate edge start for default circle node
  const shape = asset ? asset.value : '';
  let circle = false,
    square = false,
    triangle = false;

  // treat waypoints as circles because they're just points in space
  if (shape === 'default' || isWaypoint) {
    circle = true;
  } else if (shape === 'triangle') {
    triangle = true;
  } else {
    square = true;
  }

  return {
    adjust,
    bounds: bounds?.value ?? new Rectangle(),
    boundLines: [],
    circle,
    direction,
    from,
    padding: 0,
    square,
    to,
    triangle,
  };
}

/**
 * calculate the spot where an edge from fEntity to tEntity should start
 * on fEntity's shape. Reversing fEntity and tEntity will give you the spot
 * where the edge should end
 *
 * Use `adjust` to offset the point a certain number of pixels, to make room
 * for an arrow or other visual indicators of predetermined size
 */
export function calculateEdgeStart(metadata: EdgeMetadata): Vector2 | null {
  let start!: Vector2 | null;

  if (metadata.square) {
    metadata = edgeEntityBoundLines(metadata);
  }

  if (metadata.triangle) {
    metadata = edgeEntityTriangleLines(metadata);
  }

  const { adjust, from, to, direction, bounds, padding } = metadata;
  // Circle is default
  if (metadata.circle) {
    // rx, ry - the 'radius' of the from-node, adjusted for size & stretching
    const rx = bounds.width / 2;
    const ry = bounds.height / 2;
    // dx, dy - offset from the center of 'from' to the point on the
    // from-node-edge where we want the segment to make contact.
    let dy = 0;
    let dx = 0;
    if (to.y === from.y) {
      // segment is horizontal
      dy = 0;
      dx = rx * Math.sign(to.x - from.x);
    } else if (to.x === from.x) {
      // segment is vertical
      dy = ry * Math.sign(to.y - from.y);
      dx = 0;
    } else {
      // These computations are derived from the twin observations that
      // dy / dx = m
      // and
      // (dx^2 / rx^2) + (dy^2 / ry^2) = 1
      const rx2 = rx * rx;
      const ry2 = ry * ry;
      // m - slope of the line containing the centers of 'from' and 'to'
      const m = (to.y - from.y) / (to.x - from.x);
      const m2 = m * m;
      dy = (rx / Math.sqrt(rx2 / ry2 + 1 / m2)) * Math.sign(to.y - from.y);
      dx = dy / m;
    }
    const offset = new Vector2(dx, dy);
    start = from.add(offset);
  } else {
    // collapsedSet, square, or triangle
    start = edgeEntityIntersection(metadata);
  }

  if (start) {
    return start.add(direction.normalize().multScalar(adjust));
  } else {
    // one entity is entirely on top of another
    return null;
  }
}

type Endpoints = { from: Vector2 | None; to: Vector2 | None };

/**
 * Get the endpoints for a segment. For standard segments, this just returns their
 * "from" and "to" values. But for a starting / ending segment, we make adjustments
 * so that they line up perfectly with the starting or ending node.
 * We also set the value to `null` in situations where the starting or ending node
 * overlap or do not exist. Generally this means the segment cannot be rendered.
 */
export function getSegmentEndpoints(
  ecs: EcsInstance,
  segment: Segment,
  includeArrowhead: boolean = true
) {
  const endpoints: Endpoints = { from: segment.from.val, to: segment.to.val };

  const related = ecs.getComponentById(segment.owner, Related) as Related;
  const edge = ecs.getComponent(related.value, Edge) as Edge;
  const width = ecs.getComponent(related.value, Width) as Width;

  const arrowScale = 0.5 + 0.125 * width.value;

  endpoints.from = (() => {
    if (!segment.first.val) return segment.from.val;
    const fromEntity = ecs.tagManager.getEntityByTag(edge.outAnchorId);
    if (!fromEntity) return null;
    if (!ecs.hasComponent(fromEntity, Renderable.type)) return null;
    const adjust = includeArrowhead ? -arrowScale * 0.5 : 0.0;
    const metadata = constructEdgeMetadata(
      ecs,
      fromEntity,
      segment.from.val,
      segment.to.val,
      adjust
    );
    return calculateEdgeStart(metadata);
  })();

  endpoints.to = (() => {
    if (!segment.last.val) return segment.to.val;
    const sprite = ecs.getComponentById(segment.owner, Sprite) as Sprite;

    // curved arrowheads have a tapered base,
    // so we adjust the line to overlap the sprite slightly
    const toEntity = ecs.tagManager.getEntityByTag(edge.inAnchorId);
    if (!toEntity) return null;
    if (!ecs.hasComponent(toEntity, Renderable.type)) return null;
    const adjust = includeArrowhead ? sprite.asset.width : 0.0;
    const metadata = constructEdgeMetadata(
      ecs,
      toEntity,
      segment.to.val,
      segment.from.val,
      adjust
    );

    // should this be null like start? not sure why we handle it differently...
    return calculateEdgeStart(metadata) || segment.to.val;
  })();

  return endpoints;
}

/**
 * returns a matrix to appropriately adjust a segment line texutre
 * @param width - the edge width
 * @param angle - the angle of the edge wrt cardinal x-axis
 * @returns adjusted identity matrix
 */
export function calcSegmentTextureMatrix(width: number, angle: number): Matrix {
  const matrix = Matrix.IDENTITY;
  // NOTE: each edge line texture style is 51 pixels high,
  // so this normalizes the scaling
  const ratio = width / 51;
  matrix.setTransform(0, 0, 0, 0, ratio, ratio, angle, 0, 0);
  return matrix;
}

/**
 * Determine the color based on its selection state and set memberships
 * Fallback to default color
 * @param selected whether the edge is selected
 * @param setColors the colors of the visible sets of which the edge is a member
 * @param color styled color of the edge entity
 * @returns hex color
 */
export function getEdgeColor(
  selected: Option<Selected>,
  setColors: SetColors,
  color: Color
): number {
  if (selected) {
    return HEX_COLOR.SELECT_FILL;
  } else if (setColors.values.length) {
    // Zero or one set color values maintained for edges
    return setColors.values[0];
  } else if (color.value) {
    // If no set colors, but edge has been styled, use selected color
    return color.value;
  } else {
    return HEX_COLOR.EDGE_DEFAULT;
  }
}

export function updateEdgeWaypoints(
  entity: Entity,
  ecs: EcsInstance,
  modelState: ModelReducer
): void {
  const retrieved = ecs.retrieve(entity, [ShapeID, Waypoints]);
  if (!allSome(retrieved)) return;
  const [shapeID, waypoints] = retrieved;
  const config = modelState.shapes[shapeID.value];
  if (!isEdge(config)) return;
  const updates: Component[] = [];
  for (const [[waypoint, position, spatial]] of ecs.join(waypoints.values, [
    Waypoint,
    Position,
    Spatial,
  ])) {
    const [x, y] = config.waypoints[waypoint.value];
    position.value.set(x, y);
    updates.push(position);
    updates.push(spatial);
  }
  ecs.updateAll(updates);
}
