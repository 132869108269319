import cloneDeep from 'clone-deep';
import difference from 'lodash/difference';
import { getGuildName, getIdsOnPage } from 'utils/utils';
import {
  apiGet,
  apiPatch,
  apiPut,
  ENTITIES,
  USERS,
  NO_OP,
  isApiError,
  TOASTS,
} from 'modules/common';
import {
  loadMore,
  mapCollectionToEntities,
} from 'modules/entities/collections';
import { MESSAGES } from 'modules/ui/messages';
import {
  fetchGuildMetrics,
  showLicenseLimitError,
} from 'modules/admin/actions';
import { isSome } from 'helpers/utils';
import { ROLE_OPERATION } from 'utils/constants';
import { createToastMessage } from 'modules/ui/toasts';
import { getUserFirstLastNameById } from 'utils/utils';

/**
 *
 * @type {import('types').GuildSettings}
 */
export const guildSettings = {
  addingUser: false,
  showAddGuildUserModal: false,
  sort: {
    ascending: true,
    column: 'role',
  },
  selectedIdNotOnPage: false,
  loading: false,
  loaded: false,
  selected: {
    ids: [],
    orgUsers: [],
  },
  pagination: {
    count: 0,
    numberOfRows: 50,
    rowCount: 0,
    startIndex: 0,
    total: 0,
  },
  columns: ['Select', 'Name', 'Email', 'StatusReadOnly'],
  columnOptions: {
    Select: {
      value: 'selected',
      title: '',
      colWidth: '46px',
    },
    Name: {
      value: 'name',
      title: 'Name',
      archives: false,
      colWidth: '350px',
      minimumWidth: 160,
      resizable: true,
    },
    Email: {
      value: 'email',
      title: 'Email',
      colWidth: '280px',
      minimumWidth: 160,
      resizable: true,
    },
    StatusReadOnly: {
      value: 'status',
      title: 'Status',
      colWidth: '150px',
      minimumWidth: 60,
    },
  },
  tableScroll: {
    scrolledRight: 0,
  },
  filterByEnabled: false,
};

/**
 * @type {import('types').OrgUsersReducer}
 */
export const initialState = {
  orgUsers: {
    sort: {
      ascending: false,
      column: 'modified',
    },
    selectedIdNotOnPage: false,
    filterByEnabled: false,
    loading: false,
    loaded: false,
    selected: {
      ids: [],
      orgUsers: [],
    },
    pagination: {
      count: 0,
      numberOfRows: 50,
      rowCount: 0,
      startIndex: 0,
      total: 0,
    },
    columns: ['Select', 'Name', 'Guild', 'Status', 'Manage'],
    columnOptions: {
      Select: {
        value: 'selected',
        title: '',
        colWidth: '46px',
      },
      Name: {
        value: 'name',
        title: 'Name',
        archives: false,
        colWidth: '565px',
        minimumWidth: 160,
        resizable: true,
      },
      Guild: {
        value: 'guild',
        title: 'Guild',
        colWidth: '80px',
        minimumWidth: 60,
      },
      Status: {
        value: 'status',
        title: 'Status',
        colWidth: '115px',
        minimumWidth: 80,
      },
      Manage: {
        value: 'manage',
        title: 'Manage',
        colWidth: '80px',
        minimumWidth: 60,
      },
    },
    tableScroll: {
      scrolledRight: 0,
    },
  },
  showLicenseErrorModal: false,
  guildSettings: {},
};

export const SCHEMA_NAME = 'users';

/**
 * @param {unknown} query - additional query params
 * @param {import('types').CollectionKey} collection - collection to add the results [default: 'orgUsers']
 * @param {string | import('types').ToastFunc<
 * import('types').MetraApiErrorAction<
 * import('types').NormalizedResult
 * >>} errorMessage - message or function to call on error [default: undefined]
 * @returns {import('types').MetraThunkAction<
 * void,
 * import('types').NormalizedResult<'users'>,
 * Promise<import('types').MetraApiAction<
 * import('types').NormalizedResult<'users'>>
 * >} a metra thunk
 */
export const getUsers = (
  query = {},
  collection = 'orgUsers',
  errorMessage = undefined,
  entity = SCHEMA_NAME
) => {
  return async (dispatch) => {
    const result = await dispatch(
      apiGet({
        /* eslint-disable camelcase */
        entity: entity,
        params: {
          ...query,
        },
        collection,
        types: [ENTITIES.ACTION_SUCCESS, USERS.GET_FAILURE],
        error: errorMessage,
        meta: {
          excludeGuild: true,
        },
        schema: SCHEMA_NAME,
        /* eslint-enable camelcase */
      })
    );
    return result;
  };
};

/**
 * @param {Numberish} guildId - guild id
 * @param {Numberish} userId - id of user that needs their role updated
 * @param {import('types').CollectionKey} collection - collection to add the results [default: 'orgUsers']
 * @param {string} roleOperation - operation if we're adding a role to the user or remove one
 * @param {(import('types').GUILD_ROLE | 'all')[]} role - role(s) we are adding or removing
 * @param {unknown} params - extra data for fetching users
 * @param {boolean} skipRefetch - extra data for fetching users
 * @param {string | import('types').ToastFunc<
 * import('types').MetraApiErrorAction<
 * import('types').NormalizedResult
 * >>}
 * @returns {import('types').MetraThunkAction<
 * void,
 * import('types').NormalizedResult<'users'>,
 * Promise<import('types').MetraApiAction<
 * import('types').NormalizedResult<'users'>>
 * >} a metra thunk
 */
export const updateGuildUserRole = (
  guildId,
  userId,
  collection = 'orgUsers',
  roleOperation,
  role,
  params,
  skipRefetch = false
) => {
  const data = {};

  if (isSome(guildId)) {
    data.id = userId;
  }

  // Check for 'add_roles' operation and set the corresponding property
  if (isSome(roleOperation) && roleOperation === ROLE_OPERATION.ADD) {
    data['add_roles'] = role;
  }

  // Check for 'remove_roles' operation and set the corresponding property
  if (isSome(roleOperation) && roleOperation === ROLE_OPERATION.REMOVE) {
    data['remove_roles'] = role;
  }

  return async (dispatch, getState) => {
    const result = await dispatch(
      apiPut({
        entity: `guilds/${guildId}/users`,
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify([data]),
        collection,
        types: [ENTITIES.ACTION_SUCCESS, USERS.GET_FAILURE],
        meta: {
          excludeGuild: true,
        },
      })
    );

    if (skipRefetch === false) {
      dispatch(fetchUsers(params, collection, undefined, guildId));
    }

    if (!isApiError(result)) {
      const users = result.payload.entities?.users;
      const guildName = getGuildName(
        guildId,
        getState().entityReducer.adminGuilds
      );
      for (const user in users) {
        const userName = getUserFirstLastNameById(
          user,
          getState().entityReducer.users
        );

        // adding users / roles
        if (data.add_roles) {
          if (data.add_roles.includes('admin')) {
            dispatch(
              createToastMessage(
                TOASTS.SUCCESS,
                `${userName} promoted to Guild Admin and notified.`
              )
            );
          } else {
            dispatch(
              createToastMessage(
                TOASTS.SUCCESS,
                `${userName} added to ${guildName} and notified.`
              )
            );
          }
        }

        // removing users / roles
        else if (data.remove_roles) {
          if (data.remove_roles.includes('admin')) {
            dispatch(
              createToastMessage(
                TOASTS.SUCCESS,
                `${userName} removed as Guild Admin and notified.`
              )
            );
          } else {
            dispatch(
              createToastMessage(
                TOASTS.SUCCESS,
                `${userName} removed from ${guildName} and notified.`
              )
            );
          }
        }
      }
    } else {
      dispatch(createToastMessage(TOASTS.ERROR, MESSAGES.ERROR.UPDATE.ROLE));
    }

    return result;
  };
};

// /api/org/<orgName>/users/<user_id:nucleo>/roles
/**
 * @returns {import('types').MetraThunkAction<
 *   void,
 *   import('types').MetraUser,
 *   Promise<
 *     import('types').MetraApiResponseAction<
 *       import('types').NormalizedResult<'users'>
 * >>>}
 */
export const fetchUserGuildRoles =
  (userId, query = {}) =>
  async (dispatch) => {
    const signal = query.signal;
    const results = await dispatch(
      apiGet({
        entity: `users/${userId}/roles`,
        types: [ENTITIES.ACTION_SUCCESS, NO_OP.FAILURE],
        error: MESSAGES.USER_DETAILS,
        meta: {
          excludeGuild: true,
        },
        signal,
      })
    );
    if (results.error) {
      return null;
    }
    return results;
  };

/**
 * @returns {import('types').MetraThunkAction<
 *   void,
 *   import('types').MetraUser,
 *   Promise<Option<
 *     import('types').MetraApiResponseAction<
 *       import('types').NormalizedResult<'users'>
 * >>>>}
 */
export const fetchUserDetail = (id) => async (dispatch) => {
  const results = await dispatch(
    apiGet({
      entity: SCHEMA_NAME,
      record: id,
      types: [ENTITIES.ACTION_SUCCESS, NO_OP.FAILURE],
      error: MESSAGES.USER_DETAILS,
      meta: {
        excludeGuild: true,
      },
    })
  );
  if (results.error) {
    return null;
  }
  return results;
};

/**
 * Ensures user details have been retrieved for all userIds passed in.
 */
export const ensureUserDetails = (userIds) => async (dispatch, getState) => {
  // Pick out any users that we don't know the full set of guilds, and retrieve
  // the detailed user view so that we do know all the guilds.
  const detailsRetrievals = userIds
    .filter((userId) => {
      const user = getState().entityReducer.users[userId];
      return !user?.groups;
    })
    .map((missingDetailsUserId) =>
      dispatch(fetchUserDetail(missingDetailsUserId))
    );
  return Promise.all(detailsRetrievals);
};

/**
 * @type {import('types').ThunkActionFunc<[
 *   userSearchString: string
 * ],
 * Promise<import('types').MetraApiAction<
 *   import('types').NormalizedResult<'users'>,
 *   void
 * >>>}
 */
export const findUserToAdd = (userSearchString) => async (dispatch) => {
  // find the user to add
  return dispatch(getUsers({ search: userSearchString }, 'searchUsers'));
};

/**
 * @type {import('types').ThunkActionFunc<[
 *   guild: MetraGuild
 *   userSearchString: string
 * ],
 * Promise<import('types').MetraApiAction<
 *   import('types').NormalizedResult<'users'>,
 *   void
 * >>>}
 */
export const findGuildUsers = (guild, userSearchString) => async (dispatch) => {
  return dispatch(
    getUsers(
      { search: userSearchString },
      'searchUsers',
      undefined,
      'guilds/' + guild.id + '/users'
    )
  );
};
/**
 * @type {import('types').ThunkActionFunc<[args: unkown], Promise<void>>}
 */
export const addUserToGuild =
  ({ user, guild }) =>
  async (dispatch, getState) => {
    // add user to guild
    const isOrgAdmin = getState().entityReducer.users[user.id]?.org_admin;
    const guildColl = 'guild_' + guild.cname;
    const userRole = user.roles;
    const result = await dispatch(
      updateGuildUserRole(
        guild.id,
        user.id,
        guildColl,
        ROLE_OPERATION.ADD,
        isOrgAdmin ? ['user'] : userRole,
        undefined
      )
    );

    if (isApiError(result)) {
      dispatch(
        createToastMessage(TOASTS.ERROR, MESSAGES.ERROR.GUILDS.ADD.USER)
      );
    }
    await dispatch(updateUserList(guild));
  };

/**
 * performs necessary updates on the guild view when the set of users that
 * are guild-members changes.
 * @type {import('types').ThunkActionFunc<[
 *   guild: import('types').MetraGuild
 * ],
 * Promise<[
 *   void,
 *   Option<import('types').MetraApiResponseAction<import('types').NormalizedResult<'guildMetrics'>>>
 * ]>>>} a metra thunk
 */
export const updateUserList = (guild, params) => async (dispatch) => {
  // we need to refresh the table data, it should have changed
  const userListUpdate = dispatch(
    fetchUsers(params, 'guild_' + guild.cname, undefined, guild.id)
  );
  const metricsUpdate = dispatch(fetchGuildMetrics(guild));
  return Promise.all([userListUpdate, metricsUpdate]);
};

/**
 * @type {import('types').ThunkActionFunc<[
 *   args: unknown
 * ],
 * Promise<[
 *   void,
 *   Option<import('types').MetraApiResponseAction<import('types').NormalizedResult<'guildMetrics'>>>
 * ]>>} a metra thunk
}
 */
export const removeUsersFromGuild =
  ({ userIds, guild }) =>
  async (dispatch) => {
    const guildUsersCollection = 'guild_' + guild.cname;
    dispatch(setLoading(true, guildUsersCollection));
    // We want to remove each user from the guild.
    // The call to update the user's guild memberships needs to have all the
    // remaining guild memberships reflected.  We need to make sure we know all
    // of them.
    await dispatch(ensureUserDetails(userIds));
    // we have gathered all the guilds for each user to be removed from guild,
    // now do the removals

    const updates = userIds.map((userId) => {
      return dispatch(
        updateGuildUserRole(
          guild.id,
          userId,
          guildUsersCollection,
          ROLE_OPERATION.REMOVE,
          ['all'],
          undefined
        )
      );
    });
    const updateResponses = await Promise.all(updates);
    updateResponses.some((response) => {
      if (isApiError(response) && response.payload.status === 400) {
        dispatch(createToastMessage(TOASTS.ERROR, MESSAGES.ERROR.USER.REMOVE));
        return true;
      }
      return false;
    });
    // we need to refresh the table data, it should have changed
    return dispatch(updateUserList(guild));
  };

export const handleSelected = (user, collection) => (dispatch, getState) => {
  const state = getState();
  const settings = getOrgUserCollectionSettings(
    state.orgUsersReducer,
    collection
  );
  const selected = settings.selected.ids ? settings.selected.ids : [];
  let selectedCp = [...selected];
  const userIndex = selectedCp.indexOf(user.id);
  const userNotPreviouslySelected = userIndex === -1;
  userNotPreviouslySelected
    ? selectedCp.push(user.id)
    : selectedCp.splice(userIndex, 1);
  dispatch(setSelected(selectedCp, collection));
};

export const setSelected =
  (toBeSelectedIds, collection) => async (dispatch, getState) => {
    const { users } = getState().entityReducer;
    const toBeSelectedUsers = mapCollectionToEntities(
      { ids: toBeSelectedIds },
      users
    );
    await dispatch({
      type: USERS.UPDATE.SELECTED,
      payload: {
        selected: {
          ids: toBeSelectedIds,
          orgUsers: toBeSelectedUsers,
        },
        collection,
      },
    });
    const state = getState();
    const { pagination, selected } = getOrgUserCollectionSettings(
      state.orgUsersReducer,
      collection
    );
    const ids = state.collectionReducer[collection].ids;
    dispatch(setSelectedIdNotOnPage(ids, pagination, selected.ids, collection));
  };

/**
 * Selects all users displayed within the parameters of the paginated users table
 */
export const selectPage = (collection) => (dispatch, getState) => {
  const state = getState();
  if (getPageIsSelected(state, collection)) {
    dispatch(resetSelected(collection));
  } else {
    const { pagination, selected } = getOrgUserCollectionSettings(
      state.orgUsersReducer,
      collection
    );
    const ids = state.collectionReducer[collection].ids;
    const itemsOnPage = getIdsOnPage(ids, pagination, collection);
    const unselectedIdsOnPage = itemsOnPage.filter(
      (id) => !selected.ids.includes(id)
    );
    dispatch(setSelected(unselectedIdsOnPage.concat(selected.ids), collection));
  }
};

export const resetSelected = (collection) => async (dispatch, getState) => {
  await dispatch({
    type: USERS.UPDATE.SELECTED,
    payload: {
      selected: {
        ids: [],
        users: [],
      },
      collection,
    },
  });
  const state = getState();
  const { pagination, selected } = getOrgUserCollectionSettings(
    state.orgUsersReducer,
    collection
  );
  const ids = state.collectionReducer[collection]?.ids ?? [];
  dispatch(setSelectedIdNotOnPage(ids, pagination, selected.ids, collection));
};

export const setSort = (ascending, column, collection) => ({
  type: USERS.UPDATE.SORT,
  payload: { ascending, column, collection },
});

export const setPagination =
  (paginate, collection) => async (dispatch, getState) => {
    await dispatch({
      type: USERS.UPDATE.PAGINATION,
      payload: { paginate, collection },
    });
    const state = getState();
    const { pagination, selected } = getOrgUserCollectionSettings(
      state.orgUsersReducer,
      collection
    );
    const userIds = state.collectionReducer?.[collection]?.ids ?? [];
    dispatch(
      setSelectedIdNotOnPage(userIds, pagination, selected.ids, collection)
    );
  };

export const updateColumnWidths =
  (column, width, collection) => async (dispatch, getState) => {
    const columnOptions = cloneDeep(
      getOrgUserCollectionSettings(getState().orgUsersReducer, collection)
        .columnOptions
    );

    columnOptions[column].colWidth = width;
    dispatch({
      type: USERS.UPDATE.COLUMN_WIDTH,
      payload: { columnOptions, collection },
    });
  };

export const setSelectedIdNotOnPage =
  (ids, pagination, selectedIds, collection) => (dispatch) => {
    const selectedIdNotOnPage =
      difference(selectedIds, getIdsOnPage(ids, pagination, collection))
        .length > 0;
    dispatch({
      type: USERS.UPDATE.SELECTED_ID_NOT_ON_PAGE,
      payload: { selectedIdNotOnPage, collection },
    });
  };

export const setAddingToGuild = ({ guild, adding }) => ({
  type: USERS.ADDING,
  payload: { adding, collection: 'guild_' + guild.cname },
});

export const applyingChanges = (applying) => ({
  type: USERS.UPDATE.APPLYING,
  payload: applying,
});

export const setLoading = (loading, collection) => ({
  type: USERS.UPDATE.LOADING,
  payload: { loading, collection },
});

export const setLoaded = (loaded, collection) => ({
  type: USERS.UPDATE.LOADED,
  payload: { loaded, collection },
});

export const setScrolledRight = (scrolledRight, collection) => ({
  type: USERS.UPDATE.SCROLLED_RIGHT,
  payload: {
    tableScroll: {
      scrolledRight: scrolledRight,
    },
    collection,
  },
});

export const getSelectedOnPage = (state, collection) => {
  const { pagination, selected } = getOrgUserCollectionSettings(
    state.orgUsersReducer,
    collection
  );
  const ids = state.collectionReducer[collection].ids;
  const idsOnPage = getIdsOnPage(ids, pagination);
  return idsOnPage.filter((element) => selected.ids.includes(element));
};

/**
 * Selects all users displayed within the parameters of the paginated table
 */
export const selectAllPageUsers = (collection) => (dispatch, getState) => {
  const state = getState();
  if (getPageIsSelected(state, collection)) {
    dispatch(resetSelected(collection));
  } else {
    const { pagination, selected } = getOrgUserCollectionSettings(
      state.orgUsersReducer,
      collection
    );
    const ids = state.collectionReducer[collection].ids;
    const usersOnPage = getIdsOnPage(ids, pagination);
    const unselectedUserIdsOnPage = usersOnPage.filter(
      (userId) => !selected.ids.includes(userId)
    );
    dispatch(
      setSelected(unselectedUserIdsOnPage.concat(selected.ids), collection)
    );
  }
};

/**
 * Selects all users, whether currently displayed or not.
 */
export const selectAllOrgUsers = (collection) => async (dispatch, getState) => {
  let { count, ids, next } = getState().collectionReducer[collection];
  while (ids.length !== count && next) {
    let promise = await dispatch(loadMore(collection));
    next = promise.payload.next;
    ids = getState().collectionReducer[collection].ids;
  }
  dispatch(setSelected(ids, collection));
};

/**
 * @param [collection]
 * @param [collectionIds]
 * @param [searchedRows]
 * @type {import('types').ThunkActionFunc<[
 *   collection?: import('types').CollectionKey,
 *   collectionIds?: array
 *   searchedRows?: object
 * ], Promise<void>>}
 */
export const selectAllSearchedUsers =
  (collection, collectionIds, searchedRows) => async (dispatch, getState) => {
    const selectedIds = collectionIds.filter((id) =>
      searchedRows.some((item) => item.id === id)
    );
    dispatch(setSelected(selectedIds, collection));
  };

export const initCollection = (collection) => async (dispatch, getState) => {
  const state = getState();
  if (!getOrgUserCollectionSettings(state.orgUsersReducer, collection)) {
    await dispatch({
      type: USERS.INIT_GUILD_USERS,
      payload: collection,
    });
  }
};

/**
 * @param [queryParams]
 * @param [collection]
 * @param [resetPagination]
 * @param [guildId]
 * @type {import('types').ThunkActionFunc<[
 *   queryParams?: Record<string, unknown>,
 *   collection?: import('types').CollectionKey,
 *   resetPagination?: boolean,
 *   guildId?: Numberish
 * ], Promise<void>>}
 */
export const fetchUsers =
  (
    queryParams = {},
    collection = 'orgUsers',
    resetPagination = true,
    guildId
  ) =>
  async (dispatch, getState) => {
    const state = getState();
    const sortBy =
      state.orgUsersReducer.guildSettings[collection]?.sort?.column;
    dispatch(setLoading(true, collection));
    const qp = queryParams ? queryParams : {};
    const query = { ...qp, limit: 50, ordering: sortBy };
    if (resetPagination) {
      dispatch(setPagination({ numberOfRows: 50, startIndex: 0 }, collection));
    } else {
      // we aren't going to completely reset pagination, but
      // we need to make sure it is in a valid state.
      const orgUsersCount = state.collectionReducer[collection].count;
      const settings = getOrgUserCollectionSettings(
        state.orgUsersReducer,
        collection
      );
      const oldDisplayOffset = settings.pagination.startIndex;
      let newDisplayOffset = oldDisplayOffset;
      const pageSize = settings.pagination.numberOfRows;
      while (newDisplayOffset >= orgUsersCount) {
        // current page for display is past the end of the items
        newDisplayOffset = Math.max(newDisplayOffset - pageSize, 0);
        if (newDisplayOffset === 0) {
          break;
        }
      }
      if (newDisplayOffset !== oldDisplayOffset) {
        dispatch(
          setPagination(
            {
              numberOfRows: pageSize,
              startIndex: newDisplayOffset,
            },
            collection
          )
        );
      }
      query.limit = pageSize;
    }
    dispatch(resetSelected(collection));

    if (isSome(guildId)) {
      await dispatch(
        getUsers(
          query,
          collection,
          collection === 'orgUsers'
            ? MESSAGES.ERROR.GET.ORG_USERS
            : MESSAGES.ERROR.GET.GUILD_USERS,
          `guilds/${guildId}/users`
        )
      );
    } else {
      // Manage Org Users table
      await dispatch(
        getUsers(
          query,
          collection,
          collection === 'orgUsers'
            ? MESSAGES.ERROR.GET.ORG_USERS
            : MESSAGES.ERROR.GET.GUILD_USERS
        )
      );
    }

    dispatch(setLoaded(true, collection));
    dispatch(setLoading(false, collection));
  };

export const fetchAdmins =
  (queryParams = {}, collection = 'admins') =>
  async (dispatch) => {
    let params = { ...queryParams, role: 'admin' };
    const signal = queryParams.signal;
    // DONT contaminate url params with non-url data.
    delete params.signal;

    dispatch(setLoading(true, collection));
    const result = await dispatch(
      apiGet({
        /* eslint-disable camelcase */
        entity: 'users',
        collection,
        types: [NO_OP.SUCCESS, NO_OP.FAILURE],
        error: MESSAGES.ERROR.GET.ORG_ADMINS,
        meta: {
          schema: 'admins',
          excludeGuild: true,
        },
        params: {
          ...params,
        },
        signal,
        /* eslint-enable camelcase */
      })
    );

    if (result.error) {
      return dispatch({
        ...result,
        type: USERS.GET_FAILURE,
      });
    } else {
      const payload = result.payload;
      // move users from the result into destination property
      payload.entities['admins'] = result.payload.entities.users;
      // dont overwrite users
      delete payload.entities.users;
      // issue the update dispatch for entities
      const results = dispatch({
        ...result,
        payload,
        type: ENTITIES.ACTION_SUCCESS,
        meta: {
          ...result.meta,
          mutation: ENTITIES.MUTATE_READ,
        },
      });

      dispatch(setLoading(false, collection));
      return results;
    }
  };

export const getPageIsSelected = (state, collection) => {
  let pagination = getOrgUserCollectionSettings(
    state.orgUsersReducer,
    collection
  ).pagination;
  let ids = state.collectionReducer[collection].ids;
  return (
    getIdsOnPage(ids, pagination, collection).length ===
    getSelectedOnPage(state, collection).length
  );
};

export const toggleEnabledFilter =
  (collection) => async (dispatch, getState) => {
    const table = getOrgUserCollectionSettings(
      getState().orgUsersReducer,
      collection
    );
    const filterStatus = table.filterByEnabled;

    await dispatch({
      type: USERS.UPDATE.FILTER_BY_ENABLED,
      payload: {
        filterByEnabled: !filterStatus,
        collection,
      },
    });

    // by changing the enabled filter we no longer have the right data loaded.
    // indicating that data isn't loaded will cause the reload to happen with the
    // right filter / query-params.
    dispatch(setLoaded(false, collection));
  };

/**
 * @params {unknown[]} usersWithUpdatedValues
 * @params {boolean} skipRefetch
 * @param {Option<string | import('types').ToastFunc<any>>} successMessage
 * @param {Option<string | import('types').ToastFunc<any>>} failureMessage
 * @returns {import('types').ThunkAction<Promise<
 *   import('types').MetraSimpleAction<{loading: false, collection: 'orgUsers'}>
 * >>}
 */
export const updateUsers =
  (
    usersWithUpdatedValues,
    skipRefetch = false,
    successMessage = null,
    failureMessage = null
  ) =>
  async (dispatch, getState) => {
    /** @type {import('types').MetraApiAction<import('types').AddUserResult>[]} */
    const results = await Promise.all(
      usersWithUpdatedValues.map((userWithUpdateValues) =>
        dispatch(
          updateUser(
            userWithUpdateValues.user,
            userWithUpdateValues.updatedValues,
            successMessage,
            failureMessage
          )
        )
      )
    );
    if (
      results.some(
        (result) =>
          result.error &&
          result.payload.name === 'ApiError' &&
          result.payload.response.error ===
            'Your current license does not support creating another user'
      )
    ) {
      dispatch(showLicenseLimitError(true));
    }
    if (
      getState().orgUsersReducer.orgUsers.filterByEnabled &&
      usersWithUpdatedValues.some(
        (u) => u.updatedValues.enabled === false && !skipRefetch
      )
    ) {
      // we disabled a user; and we're filtering on enabled users.  we need to
      // refetch users.
      await dispatch(fetchUsers({ enabled: true }));
    } else if (!skipRefetch) {
      await usersWithUpdatedValues.map((user) =>
        dispatch(fetchUserDetail(user.id))
      );
    }

    return dispatch({
      type: ENTITIES.USERS_LOADING,
      payload: {
        loading: false,
        collection: 'orgUsers',
      },
    });
  };

/**
 * @type {import('types').ThunkActionFunc<[
 *   user: import('seamless-immutable').Option<import('types').MetraUser>,
 *   updatedValues: Partial<import('types').MetraUser>,
 *   successMessage: Option<string | import('types').ToastFunc<any>>
 *   failureMessage: Option<string | import('types').ToastFunc<any>>
 * ],
 * Promise<import('types').MetraApiAction<
 *   import('types').AddUserResult,
 *   void
 * >>>}
 */
export const updateUser =
  (user, updatedValues, successMessage = null, failureMessage = null) =>
  async (dispatch) => {
    const requestBody = updatedValues;
    if (updatedValues.enabled !== undefined) {
      requestBody.enabled = updatedValues.enabled;
    }
    const result = await dispatch(
      apiPatch({
        entity: SCHEMA_NAME,
        record: user.id,
        body: JSON.stringify(requestBody),
        headers: {
          'Content-Type': 'application/json',
        },
        types: [ENTITIES.ACTION_SUCCESS, USERS.PATCH_FAILURE],
        meta: { excludeGuild: true },
        success: successMessage,
        error: failureMessage,
      })
    );
    return result; //.payload?.response?.error;
  };

export const getOrgUserCollectionSettings = (orgUsersReducer, collection) => {
  if (collection === 'orgUsers') {
    return orgUsersReducer.orgUsers;
  }
  return orgUsersReducer.guildSettings[collection];
};

/**
 * @type {import('redux').Reducer<import('types').OrgUsersReducer, import('types').MetraAction<any, any, any>>}
 */
export const reducer = (state = initialState, action) => {
  const collection = action.payload?.collection ?? 'orgUsers';
  const newState = { ...state };
  let settingsContainer = newState;
  let settings;
  if (collection === 'orgUsers') {
    newState.orgUsers = { ...state.orgUsers };
    settings = newState.orgUsers;
  } else {
    newState.guildSettings = {
      ...state.guildSettings,
      [collection]: {
        ...state.guildSettings[collection],
      },
    };
    settingsContainer = newState.guildSettings;
    settings = newState.guildSettings[collection];
  }
  switch (action.type) {
    case USERS.ADDING:
      settings.addingUser = action.payload.adding;
      return newState;
    case USERS.MODAL.SHOW_ADD_GUILD_USER:
      settings.showAddGuildUserModal = action.payload.showAddGuildUserModal;
      return newState;
    case USERS.UPDATE.APPLYING:
      settings.applyingUserEdits = action.payload;
      return newState;
    case USERS.UPDATE.SORT:
      settings.startIndex = 0;
      settings.sort = {
        ...settings.sort,
        ...action.payload,
      };
      return newState;
    case USERS.UPDATE.PAGINATION:
      settings.pagination = {
        ...settings.pagination,
        ...action.payload.paginate,
      };
      return newState;
    case USERS.UPDATE.COLUMN_WIDTH:
      settings.columnOptions = {
        ...action.payload.columnOptions,
      };
      return newState;
    case USERS.INIT_GUILD_USERS:
      newState.guildSettings[action.payload] = cloneDeep(guildSettings);
      return newState;
    case USERS.SHOW_LICENSE_ERROR_MODAL:
      return {
        ...state,
        ...action.payload,
      };
    default:
      if (
        typeof action.type === 'string' &&
        action.type?.includes(USERS.UPDATE.GENERIC)
      )
        settingsContainer[collection] = {
          ...settingsContainer[collection],
          ...action.payload,
        };
      return newState;
  }
};

export default reducer;
