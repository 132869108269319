import {
  BaseBackgroundConfig,
  BaseCollapsedConfig,
  BaseConfig,
  BaseDrawingConfig,
  BaseEdgeConfig,
  BaseModelShapeConfig,
  BaseNodeConfig,
  BasePendingEdgeConfig,
  BaseSelectorConfig,
  BaseTextConfig,
  ExtendedConfig,
  ShapeConfig,
  ShapeConfigFunc,
} from 'types';
import { hasAsset, isEdge } from './shape-helpers';
import {
  ASSET_PRESETS,
  ASSET_TYPE,
  DRAWING_MODE,
  EDGE_STYLE,
  LAYER,
} from 'utils/constants';
import { GRID, SHAPE } from 'utils/constants-extra';
import { gid } from '../gid';

export function withAssetType<T extends ShapeConfig>(config: T): T {
  if (!hasAsset(config)) return config;

  if (isEdge(config)) {
    return { ...config, assetType: ASSET_TYPE.PRESET };
  }

  if (!ASSET_PRESETS.includes(config.asset)) {
    return { ...config, assetType: ASSET_TYPE.IMAGE };
  }

  return { ...config, assetType: ASSET_TYPE.PRESET };
}

/**
 * make a selector configuration object
 * @returns
 */
export function makeConfig<T>(
  {
    alpha = 1,
    color = '#ffffff',
    extensions,
    id = gid(),
    layer = LAYER.DEFAULT,
    order = 0,
    pos = {
      x: 0,
      y: 0,
    },
    scale = {
      x: 1,
      y: 1,
    },
    type = SHAPE.CIRCLE,
    visible = true,
    hidden = false,
  }: Partial<BaseConfig> & ExtendedConfig<T> = { extensions: {} as T }
): T & BaseConfig {
  return {
    alpha,
    color,
    id,
    layer,
    pos,
    order,
    scale,
    type,
    visible,
    hidden,
    ...extensions,
  };
}

/**
 * update config, retaining any values not explicitly overriden.
 * @param  A
 * @param  B
 * @returns  merged config
 */
export function mergeConfig(A: ShapeConfig, B: ShapeConfig): ShapeConfig {
  return { ...A, ...B };
}

/**
 * make a configuration object
 * @returns
 */
export const makeNodeConfig: ShapeConfigFunc<BaseNodeConfig> = ({
  asset = 'default',
  assetType = ASSET_TYPE.PRESET,
  edges = [],
  options = {},
  labelContent = GRID.NAME_COLUMN.NODES,
  labelPosition = 'above',
}) => {
  return withAssetType(
    makeConfig({
      type: SHAPE.NODE,
      layer: LAYER.NODE,
      ...options,
      extensions: {
        asset,
        assetType,
        edges: edges || [],
        labelContent,
        labelPosition,
      },
    })
  );
};

/**
 * make a configuration object
 */
export const makeEdgeConfig: ShapeConfigFunc<BaseEdgeConfig> = ({
  arrowhead = 'curvedArrowhead',
  asset = 'solidLine',
  assetType = ASSET_TYPE.PRESET,
  from = '',
  options = {},
  style = EDGE_STYLE.DEFAULT,
  to = '',
  waypoints = [],
  width = 4,
  labelContent = GRID.NAME_COLUMN.EDGES,
  labelPosition = 'above',
}) => {
  return withAssetType(
    makeConfig({
      type: SHAPE.EDGE,
      layer: LAYER.EDGE,
      ...options,
      extensions: {
        asset,
        assetType,
        from,
        to,
        width,
        arrowhead,
        waypoints,
        style,
        labelContent,
        labelPosition,
      },
    })
  );
};

/**
 * make a configuration object
 * @returns
 */
export const makeBackgroundConfig: ShapeConfigFunc<BaseBackgroundConfig> = ({
  asset = 'default',
  assetType = ASSET_TYPE.PRESET,
  options = {},
}) => {
  return withAssetType(
    makeConfig({
      type: SHAPE.BACKGROUND,
      layer: LAYER.BACKGROUND,
      ...options,
      extensions: {
        asset,
        assetType,
      },
    })
  );
};

/**
 * make a selector configuration object
 * @param
 * @returns
 */
export const makeSelectorConfig: ShapeConfigFunc<BaseSelectorConfig> = ({
  width = 0,
  height = 0,
  options = {},
}) => {
  return makeConfig({
    type: SHAPE.SELECTOR,
    ...options,
    extensions: {
      height,
      width,
      ephemeral: true as const,
    },
  });
};

/**
 * make a configuration object
 * @returns
 */
export const makePendingEdgeConfig: ShapeConfigFunc<BasePendingEdgeConfig> = ({
  from = '',
  to = '',
  width = 4,
  asset = 'edgeHandle',
  assetType = ASSET_TYPE.PRESET,
  ephemeral = true as const,
  arrowhead = '',
  style = EDGE_STYLE.DEFAULT,
  waypoints = [],
  options = {},
  labelContent = GRID.NAME_COLUMN.EDGES,
  labelPosition = 'above',
}) => {
  return withAssetType(
    makeConfig({
      type: SHAPE.PENDING_EDGE,
      layer: LAYER.EDGE,
      ...options,
      extensions: {
        arrowhead,
        asset,
        assetType,
        ephemeral,
        from,
        style,
        to,
        waypoints,
        width,
        labelContent,
        labelPosition,
      },
    })
  );
};

/**
 * make a configuration object
 * @returns
 */
export const makeCollapsedConfig: ShapeConfigFunc<BaseCollapsedConfig> = ({
  setId = '',
  startPos = { x: 0, y: 0 },
  options = {},
}) => {
  return makeConfig({
    type: SHAPE.COLLAPSED_SET,
    layer: LAYER.COLLAPSED_SET,
    ...options,
    extensions: {
      setId,
      startPos,
    },
  });
};

/**
 * make a model shape config
 * @param  params
 * @returns
 */
export const makeModelShapeConfig: ShapeConfigFunc<BaseModelShapeConfig> = ({
  height = 50,
  lineColor = '#ff5555',
  lineWidth = 0,
  radius = 50,
  shapeType = 0,
  width = 50,
  options = {},
}) => {
  return makeConfig({
    alpha: 1.0,
    color: '#ff5555',
    layer: LAYER.SHAPE,
    type: SHAPE.SHAPE,
    ...options,
    extensions: {
      height,
      radius,
      shapeType,
      width,
      lineWidth,
      lineColor,
    },
  });
};

/**
 * make a model shape config
 * @param  params
 * @returns a text configuration
 */
export const makeTextConfig: ShapeConfigFunc<BaseTextConfig> = ({
  fontSize = 12,
  height = 42,
  text = 'hi hi',
  width = 200,
  stroke = '#48454a',
  strokeThickness = 2,
  strokeAlpha = 1,
  angle = 0,
  options = {},
}) => {
  return makeConfig({
    alpha: 1.0,
    color: '#48454a',
    layer: LAYER.FOREGROUND,
    type: SHAPE.TEXT,
    ...options,
    extensions: {
      fontSize,
      height,
      text,
      width,
      stroke,
      strokeThickness,
      strokeAlpha,
      angle,
    },
  });
};

/**
 * make a model shape config
 * @param  params
 * @returns a drawing configuration
 */
export const makeDrawingConfig: ShapeConfigFunc<BaseDrawingConfig> = ({
  shape = DRAWING_MODE.LINE,
  fillColor = '#ffffff',
  fillAlpha = 1,
  strokeColor = '#000000',
  strokeAlpha = 1,
  height = 30,
  strokeWidth = 2,
  width = 65,
  angle = 0.5,
  lineLength = 70,
  options = {},
}) => {
  return makeConfig({
    alpha: 1.0,
    color: '#000000',
    layer: LAYER.SHAPE,
    type: SHAPE.DRAWING,
    ...options,
    extensions: {
      shape,
      fillColor,
      fillAlpha,
      strokeColor,
      strokeAlpha,
      height,
      strokeWidth,
      width,
      angle,
      lineLength,
    },
  });
};

export const Shape = {
  makeBackgroundConfig,
  makeCollapsedConfig,
  makeConfig,
  mergeConfig,
  makeEdgeConfig,
  makeModelShapeConfig,
  makeNodeConfig,
  makePendingEdgeConfig,
  makeSelectorConfig,
  makeTextConfig,
  makeDrawingConfig,
};
