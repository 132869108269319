import { FILTER_SORT, SELECTION_FILTER } from 'utils/constants';
import { Reducer } from 'redux';
import { MetraAction, MetraActionFunc } from 'types';
import { isPayloadAction } from 'modules/common';

export const initFilterSort: MetraActionFunc = (filterSort: GID[] = []) => ({
  type: FILTER_SORT.INIT,
  payload: filterSort,
});

/**
 * updates the sort order of selection filters
 */
export const updateFilterSort: MetraActionFunc = (sortOrder: GID[] = []) => ({
  type: FILTER_SORT.UPDATE,
  payload: sortOrder,
});

/*******************
 * Reducer
 *******************/

export const filterSortReducer: Reducer<GID[], MetraAction<any, any, any>> = (
  state = [],
  action
) => {
  if (!isPayloadAction(action)) {
    return state;
  }
  switch (action.type) {
    case FILTER_SORT.INIT:
      return action.payload;
    case FILTER_SORT.UPDATE:
      return action.payload;
    case SELECTION_FILTER.ADD_FILTER:
      return [Object.keys(action.payload)[0], ...state];
    case SELECTION_FILTER.DELETE_FILTER:
      const remainingFilters = Object.keys(action.payload);
      return state.filter((gid) => remainingFilters.includes(gid));
    case SELECTION_FILTER.DELETE_SHAPES_FROM_FILTERS:
      const updatedFilters = Object.keys(action.payload);
      return state.filter((gid) => updatedFilters.includes(gid));
    default:
      return state;
  }
};
