import { Node, Zoom } from 'engine/components';
import type { ModelEngine } from 'engine/engine';
import type { Rectangle } from 'pixi.js';
import type { RootReducer } from 'types';
import { EXTRA_GRID_LINES, MIN_GRID_SIZE } from 'utils/constants';
import { Vector2 } from 'utils/vector';

/**********************
Grid utility functions
**********************/

export function getZoomAdjustedGridSettings(
  rawGridSettings: { width: number; height: number },
  scale: { x: number; y: number }
): { width: number; height: number } {
  const adjustedZoomSettings = {
    width: Math.abs(rawGridSettings.width),
    height: Math.abs(rawGridSettings.height),
  };

  if (adjustedZoomSettings.width <= MIN_GRID_SIZE) {
    adjustedZoomSettings.width = MIN_GRID_SIZE;
  }

  if (adjustedZoomSettings.height <= MIN_GRID_SIZE) {
    adjustedZoomSettings.height = MIN_GRID_SIZE;
  }

  while (
    (adjustedZoomSettings.width * scale.x) / Math.abs(rawGridSettings.width) <=
    0.5
  ) {
    adjustedZoomSettings.width = adjustedZoomSettings.width * 2;
  }

  while (
    (adjustedZoomSettings.height * scale.y) /
      Math.abs(rawGridSettings.height) <=
    0.5
  ) {
    adjustedZoomSettings.height = adjustedZoomSettings.height * 2;
  }

  return adjustedZoomSettings;
}

export function getTotalNumberOfVerticalGridLines(
  screen: Rectangle,
  zoom: Vector2,
  gridColumnSize: number
) {
  const screenWidthCoords = screen.width / zoom.x;

  const numVerticalLines =
    zoom.x < 1
      ? Math.round(screenWidthCoords / gridColumnSize / zoom.x) +
        EXTRA_GRID_LINES
      : Math.round((screenWidthCoords / gridColumnSize) * zoom.x) +
        EXTRA_GRID_LINES;

  return numVerticalLines;
}

export function getTotalNumberOfHorizontalGridLines(
  screen: Rectangle,
  zoom: Vector2,
  gridRowSize: number
) {
  const screenWidthCoords = screen.height / zoom.y;

  const numVerticalLines =
    zoom.x < 1
      ? Math.round(screenWidthCoords / gridRowSize / zoom.x) + EXTRA_GRID_LINES
      : Math.round((screenWidthCoords / gridRowSize) * zoom.x) +
        EXTRA_GRID_LINES;

  return numVerticalLines;
}

export function getSnapPosition(
  zoom: Vector2,
  initialGridSettings: { width: number; height: number },
  mouse: Vector2
) {
  const gridSettings = getZoomAdjustedGridSettings(
    {
      width: initialGridSettings.width,
      height: initialGridSettings.height,
    },
    zoom
  );

  const numHorizontalLinesFromYOriginToMouseClickPos = Math.round(
    mouse.y / gridSettings.height
  );
  const numVerticalLinesFromXOriginToMouseClickPos = Math.round(
    mouse.x / gridSettings.width
  );

  const newXPos =
    numVerticalLinesFromXOriginToMouseClickPos * gridSettings.width;
  const newYPos =
    numHorizontalLinesFromYOriginToMouseClickPos * gridSettings.height;

  return new Vector2(newXPos, newYPos);
}

export function getZoom(engine: ModelEngine) {
  const zooms = engine.ecs.getComponentsByType(Zoom);
  const defaultZoom = new Vector2(1, 1);

  if (zooms && zooms.count === 1) {
    const zoom = zooms.first;
    const zoomValue = zoom?.val ? zoom.val : defaultZoom;

    return zoomValue;
  }

  return defaultZoom;
}

export function getPlacementPosition(
  engine: ModelEngine,
  mouse: Vector2,
  state: RootReducer
) {
  let placementPosition = mouse;

  if (state.appReducer.grid.snap) {
    const zoom = getZoom(engine);
    placementPosition = getSnapPosition(
      zoom,
      state.modelReducer.gridSettings,
      mouse
    );
  }

  const hasCollision = getHasNodeCollision(placementPosition, engine);

  if (hasCollision) {
    return null;
  }

  return placementPosition;
}

export function getHasNodeCollision(
  placementPosition: Vector2,
  engine: ModelEngine
) {
  const results = engine.sys2.atoms.collision.queryPoint(placementPosition);
  for (const eid of results) {
    if (engine.sys2.has(eid, Node)) {
      return true;
    }
  }

  return false;
}
