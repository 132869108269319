import type {
  MetraThunkAction,
  DownloadFileResult,
  ExtendedNormalizedResult,
  FileSizeResults,
  MetraApiAction,
  MetraApiResponseAction,
  NormalizedResult,
  ValidationResult,
  MetraTask,
  MetraDownload,
  MetraMedia,
  FailedTaskResult,
  TaskResult,
  ThunkAction,
  ApiRequest,
  AsImmutableRecord,
  ThunkActionFunc,
  MediaQuery,
  GetMediaArgs,
  APIDownloadPayload,
  DownloadTaskPayload,
  EntityDefinedNormalizedResult,
  DownloadTaskErrorResponse,
  BulkPathResult,
  RootReducer,
} from 'types';
import { API_URL } from 'utils/settings';
import { OrgContext } from 'utils/OrganizationContext';
import {
  buildGuildUrl,
  buildOrgUrl,
  parseMediaIdFromMediaAssetUrl,
} from 'utils/url-builders';
import {
  ENTITIES,
  MEDIA,
  NO_OP,
  apiPost,
  apiGet,
  isApiErrorAction,
  TASKS,
  isApiResponseAction,
  TOASTS,
  isApiError,
  isAPIDownloadPayload,
} from 'modules/common';
import { MESSAGES } from 'modules/ui/messages';
import { SCHEMA_NAME, SCHEMA_NAME_FILESIZE } from './constants';
import { asyncSleep, getBelongsTo } from 'utils/utils';
import { getMedia } from './actions';
import { isNone, isSome } from 'helpers/utils';
import { createToastMessage } from 'modules/ui/toasts';
import { ImmutableArray } from 'seamless-immutable';

export const getMediaByID =
  (
    id: Numberish,
    opts: Partial<ApiRequest<NormalizedResult<'media' | 'versions'>>> = {}
  ): MetraThunkAction<
    void,
    NormalizedResult<'media' | 'versions'>,
    Promise<MetraApiAction<NormalizedResult<'media' | 'versions'>>>
  > =>
  (dispatch) => {
    const val = dispatch(
      apiGet<NormalizedResult<'media' | 'versions'>>({
        entity: SCHEMA_NAME,
        record: String(id),
        types: [ENTITIES.ACTION_SUCCESS, MEDIA.GET_FAILURE],
        error: MESSAGES.ERROR.GET.MEDIA_ID,
        ...opts,
        meta: { ...opts.meta, excludeGuild: true },
      })
    );
    return val;
  };

export const getMediaFilesize =
  ({
    ids,
    guildCName = undefined,
  }: {
    ids: string[];
    guildCName?: string;
  }): MetraThunkAction<unknown, unknown, Promise<void>> =>
  async (dispatch) => {
    const idQueryValues = ids.map((id) => ({ id }));
    const callResults = await dispatch(
      apiPost<FileSizeResults>({
        entity: SCHEMA_NAME_FILESIZE,
        body: JSON.stringify({ media: idQueryValues }),
        headers: { 'Content-Type': 'application/json' },
        types: [NO_OP.SUCCESS, NO_OP.FAILURE],
        explicit: guildCName
          ? `${API_URL}${buildOrgUrl()}/guild/${guildCName}/${SCHEMA_NAME_FILESIZE}`
          : undefined,
      })
    );
    if (isApiErrorAction(callResults)) {
      // mark all the MetraMedia we were trying to get filesize for with
      // file_size = -1 to indicate the failure
      ids.forEach((id) =>
        dispatch({
          type: ENTITIES.ACTION_SUCCESS,
          meta: {
            mutation: ENTITIES.MUTATE_UPDATE_ONE,
            schema: SCHEMA_NAME,
          },
          payload: {
            id: id,
            file_size: -1,
          },
        })
      );
    } else {
      Object.values(callResults.payload).forEach((responseElement) => {
        if (responseElement.status !== 200 && responseElement.response?.id) {
          // we tried to get file_size for this media and failed; mark the
          // MetraMedia with file_size = -1 to indicate the failure
          dispatch({
            type: ENTITIES.ACTION_SUCCESS,
            meta: {
              mutation: ENTITIES.MUTATE_UPDATE_ONE,
              schema: SCHEMA_NAME,
            },
            payload: {
              id: responseElement.response.id,
              file_size: -1,
            },
          });
        } else {
          // update the MetraMedia with its file_size
          dispatch({
            type: ENTITIES.ACTION_SUCCESS,
            meta: {
              mutation: ENTITIES.MUTATE_UPDATE_ONE,
              schema: SCHEMA_NAME,
            },
            payload: responseElement.response,
          });
        }
      });
    }
  };

export const getDownload =
  (
    guildCName: string,
    downloadId: number,
    signal?: AbortSignal,
    gettingModel: boolean = false
  ): MetraThunkAction<
    unknown,
    ExtendedNormalizedResult<DownloadFileResult, 'downloads'>,
    Promise<Option<MetraDownload>>
  > =>
  async (dispatch) => {
    const webServiceResponse = await dispatch(
      apiGet<ExtendedNormalizedResult<DownloadFileResult, 'downloads'>>({
        entity: `guild/${guildCName}/downloads`,
        record: downloadId,
        headers: { 'Content-Type': 'application/json' },
        params: { inline: 'True' },
        meta: {
          excludeGuild: true,
        },
        signal,
        types: [ENTITIES.ACTION_SUCCESS, NO_OP.FAILURE],
      })
    );
    if (isApiErrorAction(webServiceResponse)) {
      return null;
    }
    const downloadableFile =
      webServiceResponse.payload.entities.downloads[downloadId].file;
    return {
      downloadUrl: downloadableFile.url,
      virusFound: downloadableFile.virus_found,
    };
  };

export const getTask =
  (
    taskId: Numberish,
    guild?: string,
    signal?: AbortSignal
  ): ThunkAction<Promise<TaskResult>> =>
  async (dispatch) => {
    const entityGuildParams = guild
      ? {
          entity: `guild/${guild}/tasks`,
          meta: { excludeGuild: true },
        }
      : {
          entity: 'tasks',
        };
    const response = await dispatch(
      apiGet<NormalizedResult<'tasks' | 'guilds'>>({
        record: taskId,
        headers: { 'Content-Type': 'application/json' },
        types: [ENTITIES.ACTION_SUCCESS, NO_OP.FAILURE],
        ...entityGuildParams,
      })
    );
    if (isApiErrorAction(response)) {
      if (response.payload.name === 'ApiError') {
        return {
          id: taskId,
          status: 'FAILED',
        };
      } else {
        return {
          id: taskId,
          status: 'FAILED',
        };
      }
    } else {
      return response.payload.entities.tasks[taskId];
    }
  };

export const finishTask =
  (
    taskId: Numberish,
    guild?: string,
    signal?: AbortSignal
  ): MetraThunkAction<
    unknown,
    ExtendedNormalizedResult<TaskResult, 'tasks'>,
    Promise<MetraTask | FailedTaskResult>
  > =>
  async (dispatch) => {
    let waitTimer = 1000;
    let response = await dispatch(getTask(taskId, guild, signal));
    while (
      response.status == TASKS.PENDING ||
      response.status == TASKS.IN_PROCESS
    ) {
      if (signal?.aborted)
        return {
          id: taskId,
          status: 'FAILED',
          error: 'user aborted',
        };
      await asyncSleep(waitTimer);
      waitTimer *= 1.5;

      if (signal?.aborted)
        return {
          id: taskId,
          status: 'FAILED',
          error: 'user aborted',
        };
      response = await dispatch(getTask(taskId, guild, signal));
    }

    return response;
  };

export const areAnyContainedInMetraFiles = (
  mediaIds: Numberish[] | readonly Numberish[],
  media: AsImmutableRecord<MetraMedia>
) => {
  return mediaIds.some((mediaId) =>
    media?.[mediaId]?.filePath?.endsWith('.metra')
  );
};

export const getValidationInfo = (
  id: string,
  guildCName: Option<string> = OrgContext.guild,
  limit?: number,
  offset?: number
): ThunkAction<Promise<Option<MetraApiResponseAction<ValidationResult>>>> => {
  return async (dispatch) => {
    const results = await dispatch(
      apiGet<{ id: Numberish }, { error: string }>({
        explicit: `${API_URL}${buildOrgUrl()}/guild/${guildCName}/uploads/${id}/_validate`,
        params: {
          limit,
          offset,
        },
        headers: { 'Content-Type': 'application/json' },
        types: [NO_OP.SUCCESS, NO_OP.FAILURE],
        error: MESSAGES.ERROR.GET.VALIDATION,
      })
    );
    if (isApiErrorAction(results)) {
      return null;
    } else {
      const taskResult = await dispatch(
        finishTask(results.payload.id, guildCName as string)
      );
      if (
        taskResult.status !== TASKS.COMPLETED ||
        taskResult.resource_id === undefined
      ) {
        throw new Error('could not get the validation results');
      }
      const taskOutputMeta = await dispatch(
        getDownload(guildCName as string, taskResult.resource_id)
      );
      if (!taskOutputMeta) {
        throw new Error('could not get the validation results');
      }

      const validationInfoUrl = taskOutputMeta?.downloadUrl;
      if (!validationInfoUrl) {
        throw new Error('could not get the validation results');
      }
      const validationInfoPromise = (await fetch(validationInfoUrl)).json();
      return validationInfoPromise;
    }
  };
};

export type LoadFolderMediaArgs = {
  projectId: Numberish;
  folderMediaId: Numberish | null;
  startIndex: number;
  limit: number;
  guildCName: Option<string>;
};

/**
 * Loads the metadata for media that are children of the indicated folder.  Null
 * can be used to indicate the root-folder of a project.  The media metadata
 * will be loaded into the entityReducer, and also returned from this function.
 * The returned values will be ordered by name, asending.
 *
 * @param guildCName - optional, omit for current-guild.
 * @param projectId - Project that owns the folder.
 * @param folderId - media id of the folder (null for root-folder).
 * @returns An api normalized result.
 */
export const loadFolderMedia: ThunkActionFunc<
  [params: LoadFolderMediaArgs],
  Promise<MetraApiAction<NormalizedResult>>
> =
  ({
    projectId,
    folderMediaId,
    startIndex,
    limit,
    guildCName = OrgContext.guild!,
  }) =>
  async (dispatch) => {
    const queryCriteria = {
      offset: startIndex,
      limit,
      ...getBelongsTo(folderMediaId),
    };
    const getMediaArgs: Partial<GetMediaArgs> = {
      projectId,
      belongsTo: folderMediaId,
      collection: null,
      query: {
        ordering: 'name',
        // eslint-disable-next-line camelcase
        show_hidden: true,
        ...queryCriteria,
      } as Partial<MediaQuery>,
    };
    if (isSome(guildCName)) {
      getMediaArgs.guildCName = guildCName;
    }

    const result = await dispatch(getMedia(getMediaArgs));
    return result;
  };

/**
 * Generate a link element and then click it, to download a resource from the api
 * @param {string} href url path to resource to be downloaded
 * @param {string} name
 * @modifies {DOM} creates and clicks an 'a' element on the DOM
 */
export const clickDownloadLink = (href: string, name: string) => {
  const a = document.createElement('a');
  a.href = href;
  a.download = name;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);

  if (sessionStorage.getItem('unsavedIgnoreNextRedirect'))
    sessionStorage.removeItem('unsavedIgnoreNextRedirect');
};

/**
 * Downloads the content at href to the user's machine.
 * @param {*} href Location of content to be downloaded.
 * @param {*} name Default file-name of the content.
 * @param {*} isMetraModel true if the downloaded file is a MeTRA® model - for
 *     these, nucleo will do an async assembly-task for the download file before
 *     we can access it.
 */
export const doDownload =
  (
    href: string,
    name: string,
    isMetraModel: boolean = false
  ): ThunkAction<void> =>
  (dispatch) => {
    if (isMetraModel) {
      dispatch(doMetraModelDownload(href, name));
    } else {
      clickDownloadLink(href, name);
    }
  };

/**
 * Prepares and executes download of a MeTRA® model; the entire model in .tar
 * format will be downloaded.
 * @param {*} href The download link to the model.
 */
const doMetraModelDownload =
  (href: string, name: string): ThunkAction<void> =>
  async (dispatch, getState) => {
    const createTaskResponse = await dispatch(
      apiGet<APIDownloadPayload | Blob, DownloadTaskErrorResponse>({
        types: [NO_OP.SUCCESS, NO_OP.FAILURE],
        explicit: href,
      })
    );
    let succeeded = false;
    if (isApiResponseAction(createTaskResponse)) {
      if (isAPIDownloadPayload(createTaskResponse.payload)) {
        const taskId = createTaskResponse.payload.id;
        const taskResult = await dispatch(finishTask(taskId));
        if (taskResult.status === 'FAILED') {
          throw new Error('Failed to retrieve model');
        } else {
          const guildId = taskResult.guild;
          const resourceId = taskResult.resource_id;
          const guildCName = getState().entityReducer.guilds[guildId].cname;
          const downloadInfoUrl = `${API_URL}${buildGuildUrl(
            guildCName
          )}/downloads/${resourceId}`;
          const downloadInfoResponse = await dispatch(
            apiGet<EntityDefinedNormalizedResult<DownloadTaskPayload>>({
              types: [NO_OP.SUCCESS, NO_OP.FAILURE],
              explicit: downloadInfoUrl,
            })
          );
          if (isApiResponseAction(downloadInfoResponse)) {
            const downloadInfo =
              downloadInfoResponse.payload.entities.downloads[resourceId];
            const downloadFilename = downloadInfo.file.name;
            const downloadHref = downloadInfo.file.url;
            clickDownloadLink(downloadHref, downloadFilename);
            succeeded = true;
          }
        }
      } else {
        // must be blob - 307
        const url = URL.createObjectURL(createTaskResponse.payload);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', name);
        document.body.appendChild(link);
        link.click();
        succeeded = true;
        URL.revokeObjectURL(url);
      }
    }
    if (!succeeded) {
      dispatch(createToastMessage(TOASTS.ERROR, MESSAGES.ERROR.DOWNLOAD.MEDIA));
    }
  };

export const isSuperXlImage = (
  assetImageLink: Option<string>,
  state: RootReducer
): boolean => {
  let superXl = false;
  if (assetImageLink?.includes('/media/')) {
    const mediaId = parseMediaIdFromMediaAssetUrl(assetImageLink);
    const entityReducer = state.entityReducer;
    const assetMedia = entityReducer.media[mediaId];
    if (isSome(assetMedia.versions)) {
      const assetVersion =
        entityReducer.versions[
          (assetMedia.versions as ImmutableArray<number>)[0]
        ];
      superXl = assetVersion.is_tiled_image;
    }
  }
  return superXl;
};
