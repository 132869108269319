import { createReducer } from '@reduxjs/toolkit';
import { gid } from '../gid';
import {
  addCategoryMembers,
  createCategory,
  createProperty,
  deleteCategory,
  deleteProperty,
  renameCategory,
  renameProperty,
} from './actions';
import { hydrateModel } from '../actions';
import { deleteRows } from '../sheets/actions';

type PropertyEntry = { name: string; category: GID };
type CategoryEntry = {
  name: string;
  sheet: GID;
  members: string[];
  membersById: Record<string, boolean>;
};

export interface SchemaReducerState {
  properties: {
    byId: Record<GID, PropertyEntry>;
  };

  categories: {
    byId: Record<GID, CategoryEntry>;
    bySheet: Record<GID, GID>;
  };
}

type PrunedCategoryEntry = {
  name: string;
  sheet: GID;
  members: string[];
};
export interface PrunedSchemaReducerState {
  properties: {
    byId: Record<GID, PropertyEntry>;
  };

  categories: {
    byId: Record<GID, PrunedCategoryEntry>;
  };
}

export const initialState: SchemaReducerState = {
  properties: {
    byId: {},
  },
  categories: {
    byId: {},
    bySheet: {},
  },
};

export const reducer = createReducer(initialState, (builder) => {
  // --- HYDRATION ---
  builder.addCase(hydrateModel, (state, { payload: model }) => {
    if (!model.schema) return;

    // hydrate membersById because it's only used locally for performance
    for (const catId in model.schema.categories.byId || {}) {
      const category: CategoryEntry = {
        ...model.schema.categories.byId[catId],
        membersById: {},
      };
      for (const member of category.members) {
        category.membersById[member] = true;
      }
      state.categories.byId[catId] = category;

      // hydrate bySheet because it's only used locally for performance
      state.categories.bySheet ||= {};
      state.categories.bySheet[category.sheet] = catId;
    }

    // properties can be used as-is
    state.properties.byId = model.schema.properties.byId || {};
  });

  // --- CATEGORIES ---
  builder.addCase(createCategory, (state, { payload }) => {
    const id = gid();
    state.categories.byId[id] = { ...payload, members: [], membersById: {} };
    state.categories.bySheet[payload.sheet] = id;
  });

  builder.addCase(renameCategory, (state, { payload }) => {
    state.categories.byId[payload.category].name = payload.name;
  });

  builder.addCase(deleteCategory, (state, { payload }) => {
    const sheet = state.categories.byId[payload.category].sheet;
    delete state.categories.bySheet[sheet];
    delete state.categories.byId[payload.category];
  });

  // --- CATEGORY MEMBERS ---
  builder.addCase(addCategoryMembers, (state, { payload }) => {
    const category = state.categories.byId[payload.category];
    for (const member of payload.members) {
      category.members.push(member);
      category.membersById[member] = true;
    }
  });

  // --- PROPERTIES ---
  builder.addCase(createProperty, (state, { payload }) => {
    const id = payload.id || gid();

    // id is in the payload but should not be part of the record body
    state.properties.byId[id] = {
      name: payload.name,
      category: payload.category,
    };
  });

  builder.addCase(renameProperty, (state, { payload }) => {
    state.properties.byId[payload.property].name = payload.name;
  });

  builder.addCase(deleteProperty, (state, { payload }) => {
    delete state.properties.byId[payload.property];
  });

  // when we remove rows from the sheet update the category schema to no longer include those rows
  builder.addCase(deleteRows, (state, { payload }) => {
    const categoryId = state.categories.bySheet[payload.sheet];

    state.categories.byId[categoryId].members = state.categories.byId[
      categoryId
    ].members.filter((member) => {
      return !payload.rows.includes(member);
    });

    for (const rowId of payload.rows) {
      delete state.categories.byId[categoryId].membersById[rowId];
    }
  });
});
