import {
  AnyMetraMathNode,
  EvaluatedExpression,
  EvaluatorCache,
  LaneParse,
  ModelPropertySchema,
  ModelReducer,
  Params,
  QualifiedRef,
  Scope,
  Script,
  ScriptScope,
} from 'types';
import { isNone, isNumeric, isSome, isStringish } from 'helpers/utils';
import { makeRange, toSnakeCase } from 'utils/utils';
import { EXPRESSIONS, GridNameColumnValues } from 'utils/constants-extra';
import type { Interpreter } from './interpreter';
import {
  makeMetraMathArrayNode,
  makeMetraMathNode,
  MetraMathArrayNode,
  MetraMathNode,
} from './nodes';
import { mathjs, typeOf, parse, mathImport } from './t3math';
import { getInterpreter, isResultSet } from './helpers';
import { EXP_NAME_CELL_SCHEMA_IDS } from './constants';
import { t3dev } from 't3dev';
import { logError } from 'utils/utils-extra';

export class InvalidSearchError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'InvalidSearchError';
  }
}

export function parseExp<R, O>(
  expRef: QualifiedRef,
  root: QualifiedRef = expRef,
  itpr: Interpreter<R, O> = getInterpreter()
): math.MathNode {
  let mathNode: math.MathNode & { name?: string };
  const exp = itpr.getExp(expRef);
  if (isNone(exp))
    throw new Error(`ScriptError: entity ${expRef} does not exist!`);
  // prevent blanks from becoming undefined.
  // sum(undefined) => error
  // sum('') => 0
  if (exp.evaluated === '') {
    mathNode = parse("''");
  } else if (exp.isPlain) {
    // treat plaintext as numeric when possible
    if (isNumeric(exp.evaluated)) {
      mathNode = new mathjs.ConstantNode(Number(exp.evaluated));
    } else {
      // all other plain text will be a string
      mathNode = new mathjs.ConstantNode(String(exp.evaluated));
    }
  } else {
    // if this isn't plain text, parse it to get its value
    mathNode = parse(serialize(exp.evaluated));
  }

  return makeMetraMathNode(mathNode, expRef, root, mathNode?.name);
}

export function parseLane<Results, Options>(
  cache: EvaluatorCache,
  lane: LaneParse,
  root: QualifiedRef,
  itpr = getInterpreter<Results, Options>()
): MetraMathArrayNode {
  const cached = cache[lane.type][lane.ent][lane.value];

  // MathJS clone won't carry custom properties
  // so we add our `lane` decoration before returning
  if (cached) {
    const cloned = cached.clone();
    cloned.lane = cached.lane;
    return cloned;
  }

  const exps = [];
  const tableSort = itpr.state.tableSort;
  if (lane.type === 'row') {
    for (let col = 0; col < tableSort.schemas[lane.ent].length + 1; col++) {
      const rowExp = itpr.fromCoords(lane.ent, lane.value, col);
      if (isNone(rowExp)) continue;
      // ignore blank cells for user convenience
      if (rowExp.isPlain && rowExp.value === '') continue;
      exps.push(parseExp(rowExp.ref));
    }
  } else {
    for (let row = 1; row < tableSort.entities[lane.ent].length + 1; row++) {
      const colExp = itpr.fromCoords(lane.ent, row, lane.value);
      if (isNone(colExp)) continue;

      // ignore blank cells for user convenience
      if (colExp.isPlain && colExp.value === '') continue;
      exps.push(parseExp(colExp.ref));
    }
  }

  const node = makeMetraMathArrayNode(new mathjs.ArrayNode(exps), lane, root);
  cache[lane.type][lane.ent][lane.value] = node;
  return node;
}

// serialize things in a way that lets them be re-evaluated by mathjs
// IDK why mathjs doesn't do this out of the box ¯\_(ツ)_/¯
function serializeObject(obj: RecordOf<any>): string {
  return `{ ${Object.map(
    obj,
    ([key, value]) => `${serialize(key)}: ${serialize(value)}`
  ).join(', ')} }`;
}

function serializeArray(arr: Array<any>): string {
  return `[${arr.map((i) => serialize(i)).join(', ')}]`;
}

function serializeMatrix(mat: math.Matrix): string {
  return mat.map((i) => serialize(i)).toString();
}

function serializeString(str: string): string {
  const escaped = str
    .replace(/\\(?![ntr])/g, '\\\\') // replaces single \ with \\ as long as the pattern isn't \n, \t or \r
    .replace(/\\(")|"/g, '$1') // un-escapes \" and removes "
    .replace(/\n/g, '\\n') // escapes new line characters
    .replace(/\t/g, '\\t') // escapes tab characters
    .replace(/\r/g, '\\r'); // escapes carriage return characters
  return `"${escaped}"`;
}

export function serialize(value: any): string {
  let returnValue: string;

  if (value === null) {
    return 'null';
  }

  if (value === undefined) {
    return 'undefined';
  }

  if (value.isResultSet) {
    // automatically unwrap a single return value
    if (value.entries.length < 2) return serialize(value.entries[0]);
    return serialize(value.entries);
  }

  switch (typeOf(value)) {
    case 'Array':
      returnValue = serializeArray(value);
      break;
    case 'Matrix':
      returnValue = serializeMatrix(value);
      break;
    case 'Expression':
      returnValue = serialize(value.evaluated);
      break;
    case 'string':
      returnValue = serializeString(value);
      break;
    case 'function':
      throw new InvalidSearchError(
        `\`${value.name}\` is a function, not a value. Did you mean to call it?`
      );
    case 'Object':
      returnValue = serializeObject(value);
      break;
    default:
      if (isStringish(value)) {
        returnValue = value?.toString();
      } else {
        returnValue = 'undefined';
      }
      break;
  }

  return returnValue;
}

// if zero-based index is set, adjust all the indexes accordingly
export function indexAdjustCallback(
  mathNode: AnyMetraMathNode
): AnyMetraMathNode {
  if (EXPRESSIONS.ONE_BASED_INDEX) return mathNode;

  if (mathjs.isAccessorNode(mathNode) && !mathNode.index.dotNotation) {
    mathNode.index.dimensions.forEach((node) => {
      if (mathjs.isConstantNode(node)) {
        if (typeof node.value === 'number') node.value += 1;
      } else if (
        mathjs.isRangeNode(node) &&
        mathjs.isConstantNode(node.start) &&
        mathjs.isConstantNode(node.end)
      ) {
        node.start.value += 1;
        node.end.value += 1;
      }
    });
  }

  return mathNode;
}

function makeScriptFunction<Entries, AnyArgs extends any[]>(
  script: Script,
  scriptScope: ScriptScope<Entries>
) {
  function scriptFunction(...args: AnyArgs) {
    if (script.params.length > args.length) {
      throw new Error(
        `User-defined function ${script.name} expects ${script.params.length} arguments but received ${args.length}`
      );
    }

    const params: Params<typeof args> = {};

    script.params.forEach((param, i) => {
      params[param] = args[i];
    });

    const scope = {
      ...scriptScope,
      ...params,
      isMetraScript: true as const,
    } as Scope<Entries, AnyArgs>;

    // remove outer whitespace and any semi-colon on final statement
    const block = script.block.trim().replace(/;$/, '');

    try {
      const parsed: AnyMetraMathNode = parse(block);

      // we traverse because we're modifying nodes, not replacing them
      parsed.traverse(indexAdjustCallback);

      const result = parsed.evaluate(scope);

      // if a ResultSet is returned, we always want to grab the final result
      return isResultSet<Entries>(result)
        ? result.entries.slice(-1)[0]
        : result;
    } catch (e: any) {
      logError(e, `Error in ${script.name}(): ${e.message}`);
      throw new Error(`Error in ${script.name}(): ${e.message}`);
    }
  }
  return scriptFunction;
  //
}

export function makeScriptsScope<Entries>(
  modelState: ModelReducer | { scripts: ModelReducer['scripts'] }
): ScriptScope<Entries> {
  const scriptsScope: ScriptScope<Entries> = {};
  Object.forEach(modelState.scripts, ([_key, script]) => {
    scriptsScope[script.name] = makeScriptFunction(script, scriptsScope);
  });

  return scriptsScope;
}

/**
 * @param expRef - qualified ref of the expression to evaluate
 * @param scope - current script scope
 * @param [itpr] - current interpreter context
 */
export function evaluateExp<R, O>(
  expRef: QualifiedRef,
  scope: ScriptScope<math.MathNode>,
  itpr: Interpreter<R, O> = getInterpreter()
) {
  return parseExp(expRef, expRef, itpr).evaluate(scope);
}

export function getRangeValues<R, O>(
  startRef: QualifiedRef,
  endRef: QualifiedRef,
  scope: ScriptScope<math.MathNode>,
  itpr: Interpreter<R, O> = getInterpreter()
): math.MathNode[] {
  const rangeValues: math.MathNode[] = [];
  const start = itpr.getExp(startRef);
  const end = itpr.getExp(endRef);
  if (!start) throw new Error(`entity ${start} does not exist`);
  if (!end) throw new Error(`entity ${end} does not exist`);

  // ranges let us iterate backwards (A5:A1) while preserving direction
  const rows = makeRange(start.row, end.row, true);
  const cols = makeRange(start.col, end.col, true);
  rows.forEach((row) => {
    cols.forEach((col) => {
      const rangeExp = itpr.fromCoords(start.ent, row, col);
      if (!rangeExp) return;
      // ignore blank cells for user convenience
      if (rangeExp.isPlain && rangeExp.value === '') {
        return;
      }
      const value = evaluateExp(rangeExp.ref, scope, itpr);
      if (isSome(value)) rangeValues.push(value);
    });
  });
  return rangeValues;
}

type ProcessEntityArgs = [
  args: [
    maybeArrayNode?: MetraMathArrayNode,
    maybeBooleanNode?: AnyMetraMathNode
  ],
  _math: math.MathJsStatic,
  scope: ScriptScope<math.MathNode>,
  _wrapped: { wrappedObject: any }
];

/**
 * Returns an key-value map for the property values of the entity
 * at the given row. EG `= entity(_4)``
 * @param args - the user-defined arguments for this function
 * @param math - the current instance of MathJS
 * @param scope - the evaluation scope for this evaluation
 * @returns map - the entity properties as key-value pairs
 */
export function processEntity(
  ...passedArgs: ProcessEntityArgs
): Record<string, Option<EvaluatedExpression>> {
  const [args, _, scope] = passedArgs;

  if (scope.get('isMetraScript')) {
    throw new Error('entity() cannot be called from within a script');
  }

  const [maybeArrayNode, maybeBooleanNode] = args;

  if (
    maybeArrayNode &&
    maybeArrayNode.lane &&
    maybeArrayNode.lane.type === 'row'
  ) {
    const { lane } = maybeArrayNode;
    const itpr = getInterpreter();

    // add names in with the schemas so we don't need hard-coded exceptions
    const nameCellSchemas = {} as Record<
      GridNameColumnValues,
      Partial<ModelPropertySchema>
    >;
    EXP_NAME_CELL_SCHEMA_IDS.forEach(
      (nameCellSchemaId) =>
        (nameCellSchemas[nameCellSchemaId] = { label: 'Name' })
    );
    const schemas = {
      ...nameCellSchemas,
      ...itpr.state.propSchemas,
    } as Record<GID, ModelPropertySchema>;
    const exps = {} as Record<string, Option<EvaluatedExpression>>;
    const tableSort = itpr.state.tableSort;
    for (let col = 0; col < tableSort.schemas[lane.ent].length + 1; col++) {
      const rowExp = itpr.fromCoords(lane.ent, lane.value, col);
      if (!rowExp) continue;
      const label = schemas?.[rowExp.ids.schemaId].label;
      // `entity(_1, true)` will convert props to snake case
      if (
        maybeBooleanNode &&
        maybeBooleanNode.evaluate(
          Object.overlay(scope as any, {
            currentEnt: rowExp,
          })
        )
      ) {
        exps[toSnakeCase(label)] = rowExp.evaluated;
      } else {
        exps[label] = rowExp.evaluated;
      }
    }
    return exps;
  }

  throw new Error('entity() must take a row reference as its only argument');
}
processEntity.rawArgs = true;
mathImport({ entity: processEntity });

/**
 * Returns a key-value map for the Name of an entity
 * at the given row. EG `= getName(_4)`
 * @param args - the user-defined arguments for this function
 * @param math - the current instance of MathJS
 * @param scope - the evaluation scope for this evaluation
 * @returns an object - the entity Name as key-value pairs
 */
export function processEntityName(
  ...passedArgs: ProcessEntityArgs
): Record<string, Option<EvaluatedExpression>> {
  const [args, _, scope] = passedArgs;

  if (scope.get('isMetraScript')) {
    throw new Error('entity() cannot be called from within a script');
  }

  const [maybeArrayNode] = args;

  if (
    maybeArrayNode &&
    maybeArrayNode.lane &&
    maybeArrayNode.lane.type === 'row'
  ) {
    const { lane } = maybeArrayNode;
    const itpr = getInterpreter();

    const exps = {} as Record<string, Option<EvaluatedExpression>>;
    const rowExp = itpr.fromCoords(lane.ent, lane.value, 0)!;
    let entityName = rowExp.evaluated;
    if (typeof rowExp.evaluated === 'object') {
      entityName = JSON.stringify(rowExp.evaluated).replaceAll('"', '');
    }
    exps['Name'] = entityName;
    exps['name'] = entityName;

    return exps;
  }

  throw new Error('getName() must take a row reference as its only argument');
}
processEntityName.rawArgs = true;
mathImport({ getName: processEntityName });

type CellRangeArgs = [start: AnyMetraMathNode, end: AnyMetraMathNode];

// NOTE: this may not be what we think it is...
// start and end may be QualifiedRefs
export function cellRange<R, O>(
  args: CellRangeArgs,
  _math: math.MathJsStatic,
  scope: ScriptScope<math.MathNode>
): Option<math.MathNode[]> {
  if (scope.get('isMetraScript')) {
    throw new Error('cell ranges cannot be called from within a script');
  }

  const [start, end] = args;
  const itpr = getInterpreter();

  if (!itpr || !('exp' in start) || !('exp' in end)) return null;

  const values = getRangeValues(start.exp, end.exp, scope, itpr);
  return values;
}

cellRange.rawArgs = true;
mathImport({ cellRange });

type RNMItems = MetraMathNode | MetraMathNode[];
// Add a custom members() function for filtering an array to a set's members
// Can only be used inside the Sets tab
function removeNonMembers<R, O>(
  items: RNMItems[],
  root: QualifiedRef,
  math: math.MathJsStatic,
  itpr = getInterpreter<R, O>()
): MetraMathNode[] {
  const returning: MetraMathNode[] = [];
  return items.reduce((members: MetraMathNode[], item) => {
    // if (math.typeOf(item) === 'Array') {
    if (Array.isArray(item) || mathjs.isArrayNode(item)) {
      return members.add(removeNonMembers(item as any, root, math, itpr));
    } else {
      const exp = itpr.getExp(item.exp);
      const root = itpr.getExp(item.root);
      // filter out any non-cell references
      if (isNone(exp) || isNone(root)) {
        throw new Error(
          'arrays passed to members() must contain only Cell References'
        );
      }
      const ent = exp.ent;
      const entId = exp.ids.parentId;
      const setId = root.ids.parentId;
      const entity = itpr.state[ent][entId];
      if ('setIds' in entity && entity.setIds.includes(setId))
        return members.addItem(item);
      else return members;
    }
  }, returning);
}

/**
 * Filters an array of cell references and returns only the references which
 * are members of the Set cell being evaluated. Uses mathjs raw functions to
 * achieve this additional context.
 * @param args - the user-defined arguments for this function
 * @param math - the current instance of MathJS
 * @param scope - the evaluation scope for this evaluation
 */
const members = (
  args: [node: { items: MetraMathNode[]; root: QualifiedRef }],
  math: math.MathJsStatic,
  scope: ScriptScope<math.MathNode>
) => {
  if (scope.get('isMetraScript')) {
    throw new Error('members() cannot be called from within a script');
  }

  const arr = args[0];
  if (!arr || !arr.items) {
    throw new Error('members() must take an array of Cell References');
  }

  const itpr = getInterpreter();
  const root = itpr.getExp(arr.root);

  if (isNone(root)) {
    throw new Error(`referenced cell ${arr.root} not found`);
  }

  if (root.ent !== 'sets') {
    throw new Error('members() can only be used within a Sets property cell');
  }

  const removed = removeNonMembers(arr.items, root.ref, math);
  return removed.map((r) => r.evaluate(scope));
};
members.rawArgs = true;
mathImport({ members });
