import { EDGE_COLUMNS } from 'utils/constants';
import { Reducer } from 'redux';
import { EdgeColumns, MetraAction, ModelPropertySchema } from 'types';
import { isPayloadAction } from 'modules/common';
import { gid } from './gid';

export const sourceID = gid();
export const targetID = gid();

export const initialEdgeColumnsState = {
  source: sourceID,
  target: targetID,
};

export const initEdgeColumns = (edgeColumns: EdgeColumns) => {
  return {
    type: EDGE_COLUMNS.INIT,
    payload: edgeColumns,
  };
};

/*******************
 * Reducer
 *******************/

export const edgeColumnsReducer: Reducer<
  EdgeColumns,
  MetraAction<any, any, any>
> = (state = initialEdgeColumnsState, action) => {
  if (!isPayloadAction(action)) {
    return state;
  }
  switch (action.type) {
    case EDGE_COLUMNS.INIT:
      return action.payload;
    default:
      return state;
  }
};
