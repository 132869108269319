import {
  ShapeID,
  Selected,
  Drawing,
  DrawingShape,
  LineEndPoint,
  Text,
  Locked,
  PendingInitialization,
} from 'engine/components';
import { SubAtoms } from '../sub-atoms';
import type { EntityId, Query } from '..';
import { addShapeByConfig, updateShapeByConfig } from 'modules/model/pixi';
import {
  remember,
  setTool,
  toggleLockDrawings,
} from 'modules/model/base/actions';
import { DrawingConfig, ShapeConfig } from 'types';
import { DRAWING_MODE, TOOL } from 'utils/constants';

export class DrawingAtoms extends SubAtoms {
  private _selectedDrawings!: Query;
  private _allText!: Query;
  private _allDrawings!: Query;
  private _allDrawingLineEndPoints!: Query;

  init() {
    this._selectedDrawings = this.sys2.query(Drawing, Selected);
    this._allText = this.sys2.query(Text);
    this._allDrawings = this.sys2.query(Drawing);
    this._allDrawingLineEndPoints = this.sys2.query(LineEndPoint);
  }

  // this method handles initializing new drawings after their entity is created,
  // they are fully drawn and the bounds have been set (SysDeriveDrawingBounds)
  initDrawing(config: ShapeConfig, eid: EntityId) {
    this.atoms.dispatch(addShapeByConfig(config));
    this.atoms.dispatch(remember());
    this.atoms.dispatch(setTool(TOOL.SELECT));
    this.atoms.selection.selectExactly([eid], true);
    this.sys2.engine.ecs.resolveById(eid);
    this.sys2.removeComponent(eid, PendingInitialization);
  }

  editDrawing(property: keyof DrawingConfig, value: number | string) {
    for (const eid of this._selectedDrawings.all) {
      const [gid, drawing] = this.sys2.assertAll(eid, [ShapeID, Drawing]);
      const shapeConfig = this.sys2.state.modelReducer.shapes[
        gid.val
      ] as DrawingConfig;
      const shapeConfigValue = shapeConfig[property];

      // check if the config needs to be updated
      if (value !== shapeConfigValue) {
        const updatedConfig = {
          ...shapeConfig,
          [property]: value,
        };

        if (drawing.drawingType.val === DRAWING_MODE.LINE) {
          this.editDrawingLine(eid, property, value, updatedConfig);
        } else {
          this.editDrawingShape(eid, property, value, updatedConfig);
        }
      }
    }
  }

  editDrawingLine(
    eid: EntityId,
    property: keyof DrawingConfig,
    value: number | string,
    updatedConfig: DrawingConfig
  ) {
    const drawing = this.sys2.assert(eid, Drawing);

    // keep the line's color in sync with the strokeColor
    if (property === 'strokeColor' && typeof value === 'string') {
      drawing.color.val = value;
      drawing[property].val = value;
    }

    if (
      (property === 'strokeAlpha' || property === 'strokeWidth') &&
      typeof value === 'number'
    ) {
      drawing[property].val = value;
    }

    this.atoms.dispatch(updateShapeByConfig(updatedConfig));
    this.atoms.dispatch(remember());
  }

  editDrawingShape(
    eid: EntityId,
    property: keyof DrawingConfig,
    value: number | string,
    updatedConfig: DrawingConfig
  ) {
    const [drawing, drawingShape] = this.sys2.assertAll(eid, [
      Drawing,
      DrawingShape,
    ]);

    // Update the DrawingShape and Drawing components
    if (property === 'fillColor' && typeof value === 'string') {
      drawingShape[property].val = value;
      drawing.color.val = value;
    } else if (property === 'fillAlpha' && typeof value === 'number') {
      drawingShape[property].val = value;
    } else if (property === 'strokeColor' && typeof value === 'string') {
      drawing[property].val = value;
    } else if (
      (property === 'strokeAlpha' || property === 'strokeWidth') &&
      typeof value === 'number'
    ) {
      drawing[property].val = value;
    }

    this.atoms.dispatch(updateShapeByConfig(updatedConfig));
    this.atoms.dispatch(remember());
  }

  unlockDrawings() {
    const allEids: EntityId[] = [
      ...this._allText.all,
      ...this._allDrawings.all,
      ...this._allDrawingLineEndPoints.all,
    ];

    for (const eid of allEids) {
      this.sys2.removeComponent(eid, Locked);
    }

    this.atoms.dispatch(toggleLockDrawings(false));
  }
}
