import {
  Background,
  Edge,
  Node,
  Order,
  Selected,
  ShapeID,
  MetraLayer,
  Displayables,
  Drawing,
  Text,
  Locked,
  LineEndPoint,
  SuperXLBackground,
} from 'engine/components';
import { SubAtoms } from '../sub-atoms';
import type { EntityId, Query } from '..';
import {
  hideContextMenu,
  remember,
  setMenuLaunchLocation,
  showContextMenu,
  toggleLockBackground,
  toggleLockDrawings,
} from 'modules/model/base/actions';
import { Vector2 } from 'utils/vector';
import { LAYER, MODEL } from 'utils/constants';
import { BinaryHeap } from 'utils/BinaryHeap';
import { ShapeConfig } from 'types';
import { updateShapesByConfig } from 'modules/model/pixi';
import { cloneDeep } from 'lodash';

export class ContextMenuAtoms extends SubAtoms {
  private _selectedEdges!: Query;
  private _selectedImages!: Query;
  private _selectedNodes!: Query;
  private _selectedDrawings!: Query;
  private _selectedText!: Query;
  private _allText!: Query;
  private _allDrawings!: Query;
  private _allDrawingLineEndPoints!: Query;
  private _allImages!: Query;
  private _allSuperXlImages!: Query;

  pendingEdge!: Boolean;

  init() {
    this._selectedEdges = this.sys2.query(Selected, Edge);
    this._selectedImages = this.sys2.query(Selected, Background);
    this._selectedNodes = this.sys2.query(Selected, Node);
    this._selectedDrawings = this.sys2.query(Selected, Drawing);
    this._selectedText = this.sys2.query(Selected, Text);
    this._allText = this.sys2.query(Text);
    this._allDrawings = this.sys2.query(Drawing);
    this._allDrawingLineEndPoints = this.sys2.query(LineEndPoint);
    this._allImages = this.sys2.query(Background);
    this._allSuperXlImages = this.sys2.query(SuperXLBackground);
    this.pendingEdge = false;
  }

  hideContextMenu() {
    this.atoms.dispatch(hideContextMenu());
  }

  showContextMenu() {
    this.atoms.dispatch(
      showContextMenu(
        new Vector2(
          this.sys2.engine.mouseSystem.mouseClient.x,
          this.sys2.engine.mouseSystem.mouseClient.y
        ).toObject()
      )
    );
    this.atoms.dispatch(
      setMenuLaunchLocation(
        new Vector2(
          this.sys2.engine.mouseSystem.mousedownAt.x,
          this.sys2.engine.mouseSystem.mousedownAt.y
        ).toObject()
      )
    );
  }

  selectAll() {
    this.sys2.atoms.selection.selectAll();
    this.hideContextMenu();
  }

  setPendingEdge(status: boolean) {
    this.pendingEdge = status;
  }

  deselectImages() {
    const imageEids: number[] = [...this._selectedImages.all];
    this.atoms.selection.deselect(imageEids);
    this.hideContextMenu();
  }

  deselectNodes() {
    const nodeEids: EntityId[] = [...this._selectedNodes.all];
    this.atoms.selection.deselect(nodeEids);
    this.hideContextMenu();
  }

  deselectEdges() {
    const edgeEids: EntityId[] = [...this._selectedEdges.all];
    this.atoms.selection.deselect(edgeEids);
    this.hideContextMenu();
  }

  toggleDrawingsLock() {
    const allEids: EntityId[] = [
      ...this._allText.all,
      ...this._allDrawings.all,
      ...this._allDrawingLineEndPoints.all,
    ];
    const selectedEids: EntityId[] = [
      ...this._selectedText.all,
      ...this._selectedDrawings.all,
    ];
    const isLocked = this.sys2.state.modelReducer.base.lockDrawings;
    for (const eid of allEids) {
      if (isLocked) {
        this.sys2.removeComponent(eid, Locked);
      } else {
        this.sys2.addComponent(eid, new Locked());
      }
    }

    this.atoms.dispatch(toggleLockDrawings(!isLocked));
    this.atoms.selection.deselect(selectedEids);
    this.hideContextMenu();
  }

  toggleImagesLock() {
    const allEids: EntityId[] = [
      ...this._allImages.all,
      ...this._allSuperXlImages.all,
    ];
    const selectedEids: EntityId[] = [...this._selectedImages.all];
    const isLocked = this.sys2.state.modelReducer.base.lockBackground;
    for (const eid of allEids) {
      if (isLocked) {
        this.sys2.removeComponent(eid, Locked);
      } else {
        this.sys2.addComponent(eid, new Locked());
      }
    }

    this.atoms.dispatch(toggleLockBackground(!isLocked));
    this.atoms.selection.deselect(selectedEids);
    this.hideContextMenu();
  }

  changeOrder(direction: string) {
    const shapes = cloneDeep(this.sys2.state.modelReducer.shapes) as Record<
      string,
      ShapeConfig
    >;
    const selected = this.sys2.state.modelReducer.base.selected
      .shapes as string[];
    const updated: ShapeConfig[] = [];

    // sort shapes by their display layer
    const layers: Record<string, ShapeConfig[]> = {};
    for (const shape of Object.values(shapes)) {
      layers[shape.layer] = layers[shape.layer] || [];
      layers[shape.layer].push(shape);
    }

    // change order of selected shapes
    for (let i = selected.length; i--; ) {
      const shape = shapes[selected[i]];
      const newOrders = {
        // less than 0 ensures it will be be pop'd first
        [MODEL.SEND_LAYER_TO_BACK]: -1,
        // +-1.5 ensure intent of forward/backward motion is preserved
        // by placing it between two integers
        [MODEL.SEND_LAYER_BACKWARD]: shape.order - 1.5,
        [MODEL.SEND_LAYER_FORWARD]: shape.order + 1.5,
        // greater than length ensures it will be pop'd last
        [MODEL.SEND_LAYER_TO_FRONT]: layers[shape.layer].length + 1,
      };
      shape.order = newOrders[direction];
    }

    // compact shape orders to fit in range [0, layer.length - 1]
    for (const layer of Object.values(layers)) {
      // construct the binary heap for efficient sorting
      const heap = new BinaryHeap((s: ShapeConfig) => s.order);

      // for each item in this layer, add it to the heap
      for (let i = layer.length; i--; ) {
        const shape = layer[i];
        heap.push(shape);
      }

      // for this heap, go through and update all the orders if they changed
      // when they change, we'll use the index so that it stays bounded
      // within the layer's length i.e., 0 to layer.length
      for (let i = 0; i < layer.length; i++) {
        // pop each element out of the heap and check to see if its
        // order has been updated
        const shape: ShapeConfig = heap.pop();
        if (!shape) continue;
        if (shape.order !== i) {
          // the order has changed for this shape, so update it
          shape.order = i;
          updated.push(shape);

          const entity = this.sys2.engine.ecs.tagManager.getEntityByTag(
            shape.id
          );
          if (!entity) continue;
          const order = this.sys2.assert(entity.id, Order);
          order.val = shape.order;
          this.sys2.engine.ecs.resolveById(entity.id);
        }
      }
    }

    this.atoms.dispatch(updateShapesByConfig(updated));
    this.atoms.dispatch(remember());
    this.hideContextMenu();
  }

  changeLayer(direction: string) {
    const newLayer = {
      [MODEL.SEND_SHAPE_TO_BACKGROUND]: LAYER.BACKGROUND,
      [MODEL.SEND_SHAPE_TO_FOREGROUND]: LAYER.FOREGROUND,
    };
    const shapes = cloneDeep(this.sys2.state.modelReducer.shapes);
    const updated: ShapeConfig[] = [];

    for (const eid of this._selectedImages.all) {
      const shapeID = this.sys2.assert(eid, ShapeID);
      shapes[shapeID.val].layer = newLayer[direction];
      updated.push(shapes[shapeID.val]);

      const layer = this.sys2.assert(eid, MetraLayer);
      layer.val = newLayer[direction];
      const displayables = this.sys2.assert(eid, Displayables);
      for (const displayable of displayables.val) {
        displayable.layer = layer.val;
      }
      displayables.update();

      // engine needs to move this image to a different container
      // someday we should move this logic to a system
      this.sys2.engine.dirty = true;

      // Always display the moved background on top of new layer
      this.changeOrder(MODEL.SEND_LAYER_TO_FRONT);
    }

    this.atoms.dispatch(updateShapesByConfig(updated));
    this.atoms.dispatch(remember());
    this.hideContextMenu();
  }
}
