import { Visible } from 'engine/components';
import { isNone, isSome } from 'helpers/utils';
import { TransitionData } from 'types';
import { maybeShapeCollapsed } from './collapsed';

export function maybeShapeVisible(gid: GID, data: TransitionData): boolean {
  const entity = data.ecs.getEntityByTag(gid);
  let visible = false;
  if (isNone(entity)) {
    const maybeShape = data.model.shapes?.[gid];
    visible = maybeShape?.visible ?? false;
  } else {
    visible = data.ecs.hasComponentOfType(entity, Visible);
  }
  const maybeTransition = data?.shapeVisibleTransitions?.[gid];
  const tryVisible = isSome(maybeTransition) && maybeTransition.after;
  return (visible && isNone(maybeTransition)) || tryVisible;
}

/**
 * checks if the given entity is or will be visible, given its GID
 * @param gid entity to test
 * @param data the current transition data
 */
export function isVisibleByGID(gid: GID, data: TransitionData): boolean {
  const isVisible = maybeShapeVisible(gid, data);
  const isCollapsed = maybeShapeCollapsed(gid, data);
  return isVisible && !isCollapsed;
}
