import { Vector2 } from './vector';

export type Polygon = Vector2[];
export type Point = Vector2;

export interface AABB {
  top: number;
  bottom: number;
  left: number;
  right: number;
  aabbFromOBB?: any;
}

// fastest way to detect collisions with axis-aligned (non-rotatable) rectangles
// WARNING: this function will not return the correct result if the points passed in
// represent a rotated rectangle!
export function pointInAABB(point: Vector2, aabb: AABB) {
  if (point.x < aabb.left) return false;
  if (point.x > aabb.right) return false;
  if (point.y < aabb.top) return false;
  if (point.y > aabb.bottom) return false;
  return true;
}

// slower point-in-polygon collision detection strategy which works for rotated rectangles
export function pointInRectangle(point: Vector2, rect: Polygon) {
  // first get the area of the rectangle
  const aRect = areaOfRectangle(rect);

  // then sum the areas of 4 triangles built
  // from the point and each rectangle corner
  let aTris = 0;
  aTris += areaOfTriangle([point, rect[0], rect[1]]);
  aTris += areaOfTriangle([point, rect[1], rect[2]]);
  aTris += areaOfTriangle([point, rect[2], rect[3]]);
  aTris += areaOfTriangle([point, rect[3], rect[0]]);

  // if the sums are equal, the point is inside the rectangle
  // we need a tolerance to allow for floating point error
  return Math.abs(aRect - aTris) < 0.001;
}

// WARNING: points must represent a rectangle (90 degree angles at each corner)
// otherwise this function will return an incorrect area for the shape
export function areaOfRectangle(p: Polygon) {
  if (p.length !== 4) {
    throw new Error(
      `Polygon is not a rectangle! Actual number of points: ${p.length}`
    );
  }

  // multiply length by width
  return p[0].sub(p[1]).magnitude() * p[1].sub(p[2]).magnitude();
}

export function areaOfTriangle(p: Polygon) {
  if (p.length !== 3) {
    throw new Error(
      `Polygon is not a triangle! Actual number of points: ${p.length}`
    );
  }

  // not sure how this was derived
  // found it by googling
  let a = 0;
  a += p[0].x * (p[1].y - p[2].y);
  a += p[1].x * (p[2].y - p[0].y);
  a += p[2].x * (p[0].y - p[1].y);
  a /= 2.0;
  return Math.abs(a);
}

class Range {
  min = Infinity;
  max = -Infinity;

  update(num: number) {
    if (num < this.min) {
      this.min = num;
    }

    if (num > this.max) {
      this.max = num;
    }
  }
}

// collision between two axis-aligned rects.
// faster than convexCollision as long as rects aren't rotated
export function aabbCollision(a: AABB, b: AABB) {
  if (a.left > b.right) return false;
  if (a.right < b.left) return false;
  if (a.bottom < b.top) return false;
  if (a.top > b.bottom) return false;
  return true;
}

// use the Separating Axis Theorem to detect collisions
// between any two convex polygons. Slower than the above special cases.
// if you need to collide concave polygons, godspeed, i cannot help you ._.

// note: this could be optimized for rectangles because they only have 2 perpendiculars for 4 sides
export function convexCollision(a: Polygon, b: Polygon) {
  const normals: Vector2[] = [];

  // get all vectors perpendicular to sides of A
  for (let i = 0; i < a.length - 1; i++) {
    const aLine = a[i + 1].sub(a[i]);
    normals.push(new Vector2(-aLine.y, aLine.x));
  }
  const aFinal = a[0].sub(a[a.length - 1]);
  normals.push(new Vector2(-aFinal.y, aFinal.x));

  // get all vectors perpendicular to sides of B
  for (let i = 0; i < b.length - 1; i++) {
    const bLine = b[i + 1].sub(b[i]);
    normals.push(new Vector2(-bLine.y, bLine.x));
  }
  const bFinal = a[0].sub(b[b.length - 1]);
  normals.push(new Vector2(-bFinal.y, bFinal.x));

  // for each perpendicular...
  for (const normal of normals) {
    const aRange = new Range();
    const bRange = new Range();

    // project vertices of A onto the vector
    for (const point of a) {
      const projected = normal.dot(point);
      aRange.update(projected);
    }

    // project vertices of B onto the vector
    for (const point of b) {
      const projected = normal.dot(point);
      bRange.update(projected);
    }

    // check whether the projected vertices of A overlap those of B

    if (aRange.min > bRange.min && aRange.min < bRange.max) {
      // overlap on this projection
      continue;
    }

    if (bRange.min > aRange.min && bRange.min < aRange.max) {
      // overlap on this projection
      continue;
    }

    // if even one projection has no overlap, it means there is no collision
    return false;
  }

  // if we got here, there was overlap on every projection
  // this means we have a collision!
  return true;
}
