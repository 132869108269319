import { SubAtoms } from '../sub-atoms';
import { Selected, Edge, Node, ShapeID, LabelRef } from 'engine/components';
import { updateShapeProperties } from 'modules/model/pixi';

import type { LabelPosition } from 'types';
import type { Query } from '..';
import { SHAPE } from 'utils/constants-extra';

export class StyleAtoms extends SubAtoms {
  /** A filter of the current selection, only including nodes. */
  private selectedNodes!: Query;
  /** A filter of the current selection, only including edges. */
  private selectedEdges!: Query;

  init() {
    this.selectedNodes = this.sys2.query(Selected, Node, ShapeID);
    this.selectedEdges = this.sys2.query(Selected, Edge, ShapeID);
  }

  /**
   * Changes the position of a label relative to the parent shape.
   * @param labelPosition: LabelPosition - The alignment position of the label.
   * @param shape: SHAPE - The category of shape that the label position will be applied to.
   */
  setLabelPosition(
    labelPosition: LabelPosition,
    shape: typeof SHAPE.NODE | typeof SHAPE.EDGE
  ) {
    const targets =
      shape === SHAPE.NODE ? this.selectedNodes : this.selectedEdges;
    for (const eid of targets.all) {
      const ref = this.sys2.assert(eid, LabelRef);
      ref.position.val = labelPosition;

      // label systems are still ecs 1.0
      this.sys2.engine.ecs.update(ref);
    }

    const gids = this.toGIDs(targets.all);
    this.dispatch(updateShapeProperties(gids, { labelPosition }));
  }

  /**
   * Changes the content of a label.
   * @param labelContent: GID - The label's GID.
   * @param shape: SHAPE - The category of shape that the label position will be applied to.
   */
  setLabelContent(
    labelContent: GID,
    shape: typeof SHAPE.NODE | typeof SHAPE.EDGE
  ) {
    const targets =
      shape === SHAPE.NODE ? this.selectedNodes : this.selectedEdges;
    for (const eid of targets.all) {
      const ref = this.sys2.assert(eid, LabelRef);
      ref.content.val = labelContent;

      // Label systems are still ecs 1.0.
      this.sys2.engine.ecs.update(ref);
    }

    const gids = this.toGIDs(targets.all);
    this.dispatch(updateShapeProperties(gids, { labelContent }));
  }
}
