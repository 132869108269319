import { isNone, isSome } from 'helpers/utils';
import { TransitionData } from 'types';
import { getEntitySets } from './utils';

export function maybeSetCollapsed(gid: GID, data: TransitionData): boolean {
  const setData = data.model.sets?.[gid];
  if (isNone(setData)) return false;
  const maybeCollapse = data?.setCollapseTransitions?.[setData.id];
  const tryCollapse = isSome(maybeCollapse) && maybeCollapse.after;
  return (setData.collapsed && isNone(maybeCollapse)) || tryCollapse;
}

/**
 * checks if a given entity is within a collapsed set
 * @param data current transition data
 * @returns `true` if in a collapsed set otherwise `false`
 */
export function isInCollapsedSet(data: TransitionData): boolean {
  const { setData } = data;
  const sets = getEntitySets(data);

  // check to see if any set is collapsed or will be
  for (let i = sets.length; i--; ) {
    const setId = sets[i];
    // skip the provided set if provided
    if (isSome(setData) && setId === setData.id) continue;
    const isCollapsed = maybeSetCollapsed(setId, data);
    // IF this set is collapsed
    // OR will be collapsed
    // THEN return true
    // OTHERWISE return false
    if (isCollapsed) return true;
  }
  return false;
}
