import { Collapsed, Edge, Hidden, ShapeID, Visible } from 'engine/components';
import { isVisibleByGID } from 'helpers/entities/visibility';
import { isNone, isSome } from 'helpers/utils';
import { TransitionData } from 'types';

/**
 * determines whether an edge is visible based on current visibility transition data
 * @param data the current transition data
 */
export function determineEdgeVisibility(data: TransitionData): boolean {
  return !isExpresslyHidden(data) && canEdgeBeVisible(data);
}

/**
 * determines whether an edge can be visible based on current visibility transition data
 * (ignores whether the edge has been expressly hidden by the user)
 * @param data the current transition data
 */
export function canEdgeBeVisible(data: TransitionData): boolean {
  if (isNone(data.targetEntity)) return false;
  const [edge, _maybeVisible, shapeID, _maybeCollapsed] = data.ecs.retrieve(
    data.targetEntity,
    [Edge, Visible, ShapeID, Collapsed]
  );
  if (isNone(edge) || isNone(shapeID)) return false;
  // IF both anchors are the same,
  // THEN this edge should not be visible
  if (edge.inAnchorId === edge.outAnchorId) return false;

  const inAnchorVisible = isVisibleByGID(edge.inAnchorId, data);
  const outAnchorVisible = isVisibleByGID(edge.outAnchorId, data);
  // IF either anchor is not visible
  // THEN this edge should not be visible
  if (!inAnchorVisible || !outAnchorVisible) return false;

  const edgeData = data.model.edges[shapeID.value];
  if (!edgeData) return false;

  let anyVisibleSets = edgeData.setIds.length < 1;
  for (const setId of edgeData.setIds) {
    if (data.model.sets[setId].visible) {
      anyVisibleSets = true;
      break;
    }
  }

  if (!anyVisibleSets) return false;

  return true;
}

/**
 * returns whether an edge has been expressly hidden by the user
 * @param data the current transition data
 */
export function isExpresslyHidden(data: TransitionData): boolean {
  const [hidden] = data.ecs.retrieve(data.targetEntity, [Hidden]);
  return isSome(hidden);
}
