import cloneDeep from 'clone-deep';
import { MODEL, TOOL } from 'utils/constants';
import { TABLE } from 'modules/common';
import { initialBaseModelState } from './initial-state';

/**
 * reducer for 'base' modelState.
 * @param {import('types').BaseModelReducer} baseState - 'base' modelSTate.
 * @param {import('types').MetraSimpleAction<any>} action - redux action.
 * @returns {import('types').BaseModelReducer} updated state.
 */
export const baseModelReducer = (state = initialBaseModelState, action) => {
  switch (action.type) {
    case MODEL.SET_MODEL_VERSION_ID: {
      return {
        ...state,
        modelVersionId: action.payload,
      };
    }
    case MODEL.SET_IS_MODEL_LOADING: {
      return {
        ...state,
        isLoading: action.payload,
      };
    }
    case MODEL.SET_IS_VERIFYING_MODEL_VERSION: {
      return {
        ...state,
        isVerifyingModelVersion: action.payload,
      };
    }
    case MODEL.SET_PROTECTED: {
      return {
        ...state,
        isProtected: action.payload,
        isReadOnly: false,
      };
    }
    case MODEL.SET_READ_ONLY: {
      return {
        ...state,
        isProtected: false,
        isReadOnly: action.payload,
      };
    }
    case MODEL.SET_IS_OLDER_MODEL: {
      return {
        ...state,
        isOlderModel: action.payload,
      };
    }
    case MODEL.SET_ACKNOWLEDGE_OLDER_MODEL: {
      return {
        ...state,
        acknowledgedOlderModel: action.payload,
      };
    }
    case MODEL.SHOW_CONTEXT_MENU: {
      return {
        ...state,
        contextMenu: {
          show: true,
          position: { ...action.payload },
        },
      };
    }
    case MODEL.HIDE_CONTEXT_MENU: {
      if (!state.contextMenu.show) return state;
      return {
        ...state,
        contextMenu: {
          show: false,
          position: { ...state.contextMenu.position },
        },
      };
    }
    case MODEL.SET_NAME: {
      return {
        ...state,
        name: action.payload,
      };
    }
    case MODEL.SET_NODE_DEFAULT_LAYOUT: {
      return {
        ...state,
        nodeDefaultLayout: action.payload,
      };
    }
    case MODEL.SET_VALID_CLIPBOARD_CELL_RANGE_TYPE: {
      return {
        ...state,
        validClipboardCellRangeType: action.payload,
      };
    }
    case MODEL.SET_COPYING: {
      return {
        ...state,
        copying: action.payload,
      };
    }
    case MODEL.SET_CUTTING: {
      return {
        ...state,
        cutting: action.payload,
      };
    }
    case MODEL.SET_PASTING: {
      return {
        ...state,
        pasting: action.payload,
      };
    }
    case MODEL.SET_MODEL_LIBRARY_PATH: {
      return {
        ...state,
        modelLibraryPath: action.payload,
      };
    }
    case MODEL.SET_MODEL_PARENT_FOLDER: {
      return {
        ...state,
        modelParentFolder: action.payload,
      };
    }
    case MODEL.SET_SELECTED_FILTER: {
      return {
        ...state,
        selectedFilter: action.payload,
      };
    }
    case MODEL.SET_FILTER_OPACITY: {
      return {
        ...state,
        filterAlpha: action.payload,
      };
    }
    case MODEL.SET_SELECTION_EMPHASIS: {
      return {
        ...state,
        selectionEmphasis: action.payload,
      };
    }
    case MODEL.SET_LABEL_FONT_SIZE: {
      return {
        ...state,
        labelSize: action.payload,
      };
    }
    case MODEL.SET_DRAWING_MODE: {
      return {
        ...state,
        drawingMode: action.payload,
      };
    }
    case MODEL.COLLAPSED.CREATE: {
      const collapsed = cloneDeep(state.collapsed);
      const tuples = action.payload;
      tuples.forEach(([collapsedId, setId]) => {
        collapsed.shapes[collapsedId] = setId;
        collapsed.sets[setId] = collapsedId;
      });
      return { ...state, collapsed };
    }
    case MODEL.COLLAPSED.DELETE: {
      const collapsed = cloneDeep(state.collapsed);
      const tuples = action.payload;
      tuples.forEach(([collapsedId, setId]) => {
        delete collapsed.shapes[collapsedId];
        delete collapsed.sets[setId];
      });
      return { ...state, collapsed };
    }
    case MODEL.INIT:
      return {
        ...state,
        ...action.payload,
        initialized: true,
        nodeMediaFile: null,
        selected: { shapes: [], sets: [] },
      };
    case MODEL.SET_INITIALIZED:
      return {
        ...state,
        initialized: action.payload,
      };
    case MODEL.INCOMPATIBLE:
      return {
        ...state,
        initialized: false,
        incompatible: true,
      };
    case MODEL.MENU_LAUNCH_LOCATION: {
      return {
        ...state,
        menuLaunchLocation: action.payload,
      };
    }
    case MODEL.MOUSE_UPDATE: {
      const update = cloneDeep(state.mouse);
      update.prev = update.curr;
      update.curr = action.payload;
      return {
        ...state,
        mouse: update,
      };
    }
    case MODEL.MOUSE_LOCATION: {
      return {
        ...state,
        mouseLocation: action.payload,
      };
    }
    case MODEL.PENDING_EDGE_BEGIN:
      return {
        ...state,
        pendingEdge: action.payload,
      };
    case MODEL.PENDING_EDGE_END:
      return {
        ...state,
        pendingEdge: null,
      };
    case MODEL.PAN_BEGIN:
      return {
        ...state,
        panning: true,
      };
    case MODEL.PAN_END:
      return {
        ...state,
        panning: false,
      };
    case MODEL.OBJECTS_DESELECT: {
      const type = action.payload.type;
      const objects = action.payload.objects;
      const newSelected = [];
      (state.selected[type] || []).forEach((sid) => {
        if (objects.some((id) => id === sid)) return;
        newSelected.push(sid);
      });
      return {
        ...state,
        selected: {
          ...state.selected,
          [type]: newSelected,
        },
      };
    }
    case MODEL.UPDATE_SELECTION: {
      // prevent duplicates
      // ensure all new selections are valid by not allowing undefined values
      const newSets = new Set(action.payload.sets);
      newSets.delete(null);
      newSets.delete(undefined);
      const newShapes = new Set(action.payload.shapes);
      newShapes.delete(null);
      newShapes.delete(undefined);
      return {
        ...state,
        selected: {
          sets: [...newSets],
          shapes: [...newShapes],
        },
      };
    }
    case MODEL.OBJECTS_DESELECT_TYPE:
      return {
        ...state,
        selected: {
          ...state.selected,
          [action.payload.type]: [],
        },
      };
    case MODEL.OBJECTS_DESELECT_ALL:
      return {
        ...state,
        selected: { shapes: [], sets: [] },
      };
    case MODEL.OBJECTS_SELECT: {
      const type = action.payload.type;
      // ensure all new selections are valid by not allowing `undefined` values
      const objects = action.payload.objects.filter((id) => !!id);
      const newSelected = Object.clone(state.selected);
      // use a set to prevent duplicates
      const newSelection = Array.from(
        new Set((newSelected[type] || []).concat(objects))
      );
      newSelected[type] = newSelection;

      return {
        ...state,
        selected: {
          ...newSelected,
        },
      };
    }

    case MODEL.OBJECTS_SELECTED_NODE_COUNT: {
      return {
        ...state,
        nodesSelected: action.payload.nodesSelected,
      };
    }

    case MODEL.OBJECTS_SELECTED_EDGE_COUNT: {
      return {
        ...state,
        edgesSelected: action.payload.edgesSelected,
      };
    }

    case MODEL.OBJECTS_SELECTED_ONLY_DEFAULT_NODE: {
      return {
        ...state,
        onlyDefaultNodesSelected: action.payload.onlyDefaultNodesSelected,
      };
    }

    case MODEL.PIXI_DESTROYED:
      return {
        ...state,
        pixiLoaded: false,
        pixiReady: false,
        // initialized: false,
      };
    case MODEL.PIXI_LOADED:
      return {
        ...state,
        pixiLoaded: action.payload,
      };
    case MODEL.PIXI_RESIZE:
      return {
        ...state,
        resize: action.payload,
        size: action.payload,
      };
    case MODEL.PIXI_READY:
      return {
        ...state,
        pixiReady: true,
      };
    case MODEL.PIXI_RESIZE_COMPLETE:
      return {
        ...state,
        resize: null,
      };
    case MODEL.TOOL_SET:
      return {
        ...state,
        tool: action.payload,
        showImageFilesPanel: action.payload === TOOL.FILES,
      };
    case MODEL.UPDATE_MODEL: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case MODEL.LOAD_FAILURE: {
      return {
        ...state,
        error: true,
      };
    }
    case MODEL.NOT_FOUND: {
      return {
        ...state,
        notFound: true,
      };
    }
    case MODEL.LOAD_SUCCESS: {
      return {
        ...state,
        error: false,
      };
    }
    case MODEL.VIEWPORT_UPDATE: {
      return {
        ...state,
        viewport: action.payload,
      };
    }
    case MODEL.SHOW_PROPS_PANEL: {
      return {
        ...state,
        showPropsPanel: true,
      };
    }

    case MODEL.HIDE_PROPS_PANEL: {
      return {
        ...state,
        showPropsPanel: false,
      };
    }
    case MODEL.TOGGLE_PROPS_PANEL: {
      return {
        ...state,
        showPropsPanel: !state.showPropsPanel,
      };
    }
    case MODEL.SHOW_EXITING_SAVE_MODAL: {
      return {
        ...state,
        showExitingSaveModal: true,
      };
    }
    case MODEL.SHOW_FILES_PANEL: {
      return {
        ...state,
        showImageFilesPanel: true,
      };
    }
    case MODEL.SHOW_NOTES_OUTLINE_FLYOUT: {
      return {
        ...state,
        showNotesOutlineFlyout: true,
        notesOutlineHeaders: action.payload,
      };
    }
    case MODEL.HIDE_NOTES_OUTLINE_FLYOUT: {
      return {
        ...state,
        showNotesOutlineFlyout: false,
        notesOutlineHeaders: initialBaseModelState.notesOutlineHeaders,
      };
    }
    case MODEL.SET_STYLE_PANEL_ACTIVE_TYPE: {
      return {
        ...state,
        stylePanelActiveType: action.payload,
      };
    }
    case MODEL.HIDE_FILES_PANEL: {
      return {
        ...state,
        showImageFilesPanel: false,
      };
    }
    case MODEL.TOGGLE_IMAGE_FILES_PANEL: {
      return {
        ...state,
        showImageFilesPanel: !state.showImageFilesPanel,
      };
    }
    case MODEL.SET_NODE_MEDIA_FILE: {
      return {
        ...state,
        nodeMediaFile: Object.clone(action.payload),
      };
    }
    case MODEL.SET_REASSIGNING_EDGE_ID: {
      return {
        ...state,
        reassigningEdgeId: action.payload,
      };
    }
    case MODEL.SHOW_DEL_SET_CONFIRMATION_MODAL: {
      return {
        ...state,
        showDeleteSetsConfirmationModal: true,
      };
    }
    case MODEL.HIDE_DEL_SET_CONFIRMATION_MODAL: {
      return {
        ...state,
        showDeleteSetsConfirmationModal: false,
      };
    }
    case MODEL.SET_SHOW_HOVER_FLYOUT: {
      return {
        ...state,
        hoverFlyout: action.payload,
      };
    }
    case MODEL.SET_SHOW_NAME_MODAL: {
      return {
        ...state,
        showNameModal: action.payload.show,
        saveSelectionOnly: action.payload.selectionOnly,
      };
    }
    case MODEL.SET_SHOW_UNSAVED_CHANGES: {
      return {
        ...state,
        showUnsavedChanges: action.payload,
      };
    }
    case MODEL.SET_SHOW_BLUE_LOCK: {
      return {
        ...state,
        showBlueLock: action.payload,
      };
    }
    case MODEL.SET_SHOW_RED_LOCK: {
      return {
        ...state,
        showRedLock: action.payload,
      };
    }
    case MODEL.SET_SHOW_LOCKED_MODEL_REFRESH: {
      return {
        ...state,
        showLockModelRefresh: action.payload,
      };
    }
    case MODEL.SET_SHOW_NODE_LABELS: {
      return {
        ...state,
        showNodeLabels: !state.showNodeLabels,
      };
    }
    case MODEL.SET_SHOW_READ_ONLY_WARNING_FLYOUT: {
      return {
        ...state,
        readOnlyWarningFlyout: action.payload,
      };
    }
    case MODEL.SET_NODE_LABELS_VISIBILITY: {
      return {
        ...state,
        showNodeLabels: action.payload,
      };
    }
    case MODEL.SET_SHOW_EDGE_LABELS: {
      return {
        ...state,
        showEdgeLabels: !state.showEdgeLabels,
      };
    }
    case MODEL.SET_EDGE_LABELS_VISIBILITY: {
      return {
        ...state,
        showEdgeLabels: action.payload,
      };
    }
    case MODEL.HIDE_SET_COLOR: {
      return {
        ...state,
        hiddenSetColors: [...state.hiddenSetColors, action.payload],
      };
    }
    case MODEL.SHOW_SET_COLOR: {
      const filteredSets = state.hiddenSetColors.filter(
        (set) => set !== action.payload
      );
      return {
        ...state,
        hiddenSetColors: [...filteredSets],
      };
    }
    case MODEL.SHOW_ALL_SET_COLORS: {
      return {
        ...state,
        hiddenSetColors: [],
      };
    }
    case MODEL.HIDE_ALL_SET_COLORS: {
      return {
        ...state,
        hiddenSetColors: [...action.payload],
      };
    }
    case MODEL.TOGGLE_LOCK_BACKGROUND:
      return {
        ...state,
        lockBackground: action.payload,
      };
    case MODEL.TOGGLE_LOCK_DRAWINGS:
      return {
        ...state,
        lockDrawings: action.payload,
      };
    case MODEL.TOGGLE_MAKE_BACKGROUND:
      return {
        ...state,
        makeBackground: !state.makeBackground,
      };
    case MODEL.TOGGLE_IMAGE_MODAL:
      return {
        ...state,
        replaceNodeFile: {},
        showImageModal: !state.showImageModal,
      };
    case MODEL.TOGGLE_SHOW_FILES_VIEW:
      return {
        ...state,
        showFilesView: !state.showFilesView,
      };
    case MODEL.SET_SHOW_SAVE_FILTER_FLYOUT:
      return {
        ...state,
        showSaveFilterFlyout: action.payload,
      };
    case MODEL.SET_IS_SEARCHING:
      return {
        ...state,
        isSearching: action.payload,
      };
    case MODEL.MODEL_LINK_SEARCHING:
      return {
        ...state,
        isModelLinkSearching: action.payload,
      };
    case MODEL.SET_REPLACE_NODE_FILE:
      return {
        ...state,
        replaceNodeFile: action.payload,
      };
    case MODEL.CHANGE_ALL_SETS_ALPHA:
      return {
        ...state,
        allSetsAlpha: action.payload,
      };
    case MODEL.CHANGE_ALL_NODES_ALPHA:
      return {
        ...state,
        allNodesAlpha: action.payload,
      };
    case MODEL.CHANGE_ALL_EDGES_ALPHA:
      return {
        ...state,
        allEdgesAlpha: action.payload,
      };
    case MODEL.SET_TABLE_ITEMS:
      return { ...state, ...action.payload };
    case MODEL.SET_IS_ON_CANVAS:
      return { ...state, isOnCanvas: action.payload };
    case MODEL.SET_RECENT_COLORS:
      return { ...state, recentColors: action.payload };
    case MODEL.SHAPE_STYLE:
      return { ...state, shapeStyle: action.payload };
    case MODEL.ARROWHEAD_TYPE:
      return { ...state, arrowheadStyle: action.payload };
    case MODEL.WAYPOINT_STYLE:
      return { ...state, waypointStyle: action.payload };
    case MODEL.FOCUS_MPT:
      return { ...state, mptGrabFocus: action.payload };
    case TABLE.FILTER.CLEAR:
      return {
        ...state,
        selectedFilter: initialBaseModelState.selectedFilter,
      };
    case MODEL.TOGGLE_MPT_GRID_POPOUT:
      return {
        ...state,
        showMptGridPopout: !state.showMptGridPopout,
      };
    case MODEL.TOGGLE_DARK_MODE:
      return {
        ...state,
        darkMode: !state.darkMode,
      };
    case MODEL.TOGGLE_FILTER_IMAGES:
      return {
        ...state,
        filterImages: !state.filterImages,
      };
    case MODEL.TOGGLE_GRID_MENU:
      return {
        ...state,
        showGridMenu: !state.showGridMenu,
      };
    case MODEL.CLOSE_MPT_GRID_POPOUT:
      return {
        ...state,
        showMptGridPopout: false,
      };
    case MODEL.TOGGLE_FILTER_OVERLAY:
      return {
        ...state,
        filterOverlay: !state.filterOverlay,
      };
    case MODEL.TOGGLE_FILTERS_FLYOUT:
      return {
        ...state,
        showFiltersFlyout: !state.showFiltersFlyout,
      };
    case MODEL.SEARCH_BAR_FOCUSED:
      return {
        ...state,
        searchBarFocused: action.payload,
      };
    case MODEL.SET_SCRIPTS_FILE_ID:
      return {
        ...state,
        scriptsFileId: action.payload,
      };
    case MODEL.SET_SCRIPTS_CONTENT:
      return {
        ...state,
        scriptsContent: action.payload,
      };
    default:
      return state;
  }
};
