import { Collapsed } from 'engine/components';
import { isNone, isSome } from 'helpers/utils';
import { TransitionData } from 'types';

export function maybeShapeCollapsed(gid: GID, data: TransitionData): boolean {
  const entity = data.ecs.getEntityByTag(gid);
  let collapsed = false;
  if (isNone(entity)) {
    const maybeShape = data.model.shapes?.[gid];
    // visible is the best we can do given we do not track it
    collapsed = !maybeShape?.visible ?? false;
  } else {
    collapsed = data.ecs.hasComponentOfType(entity, Collapsed);
  }
  const maybeCollapse = data?.shapeCollapseTransitions?.[gid];
  const tryCollapse = isSome(maybeCollapse) && maybeCollapse.after;
  return (collapsed && isNone(maybeCollapse)) || tryCollapse;
}
