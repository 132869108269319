import {
  Bounds,
  Order,
  ShapeID,
  MetraLayer,
  Position,
  Scale,
  Alpha,
  Selectable,
  Moveable,
  Text,
  Visible,
  Color,
  DarkMode,
  Displayables,
  DrawingText,
  Interactive,
  Locked,
  Oriented,
  Selected,
} from 'engine/components';
import { SubAtoms } from '../sub-atoms';
import type { Query } from '..';
import { Vector2 } from 'utils/vector';
import { ECS_GROUP, HEX_STRING, LAYER, SHAPE, TOOL } from 'utils/constants';
import { TextConfig } from 'types';
import { addShapeByConfig, updateShapeByConfig } from 'modules/model/pixi';
import { getTextResolution, isSome } from 'helpers/utils';
import { getSnapPosition, getZoom } from 'helpers/grid-utils';
import { Rectangle, Text as PText, TextStyle } from 'pixi.js';
import { colorParse } from 'utils/utils';
import { remember, setTool } from 'modules/model/base/actions';
import { gid } from 'modules/model/gid';

const textPropertyMap: Record<string, keyof TextConfig> = {
  textFontSize: 'fontSize',
  textColor: 'color',
  textStrokeColor: 'stroke',
  textStrokeThickness: 'strokeThickness',
  textStrokeAlpha: 'strokeAlpha',
  textAlpha: 'alpha',
};

const textComponentMap: Record<string, keyof Text> = {
  textFontSize: 'fontSize',
  textColor: 'fill',
  textStrokeColor: 'stroke',
  textStrokeThickness: 'strokeThickness',
  textStrokeAlpha: 'strokeAlpha',
  textAlpha: 'textAlpha',
};

export class TextAtoms extends SubAtoms {
  private _textShapes!: Query;
  private _selectedText!: Query;

  init() {
    this._textShapes = this.sys2.query(Text);
    this._selectedText = this.sys2.query(Text, Selected);
  }

  createText(value: string, coords: Vector2) {
    // set default attributes
    const textColor = HEX_STRING.BLACK;
    const textAlpha = 1;
    const fontSize = 16;
    const textStrokeAlpha = 1;
    const textStrokeColor = HEX_STRING.WHITE;
    const textStrokeThickness = 0;
    const lockedDrawings = this.sys2.state.modelReducer.base.lockDrawings;

    // set the style
    const style = new TextStyle({
      lineJoin: 'round',
      fontSize: fontSize,
      fill: textColor,
      stroke: textStrokeColor,
      strokeThickness: textStrokeThickness,
    });

    const { x, y } = this.sys2.engine.camera.fixedToWorld(coords);

    // create pixi text object
    const textObject = new PText(value, style);
    textObject.x = x;
    textObject.y = y;
    textObject.alpha = textAlpha;
    textObject.resolution = getTextResolution(textObject, this.sys2.engine.app);
    textObject.zIndex = this._textShapes.all.size;
    textObject.anchor.set(0.5, 0.5); // set the anchor to the center so it can rotate around its center

    // If snap to grid is active, set the snap position
    if (this.sys2.state.appReducer.grid.snap) {
      const zoom = getZoom(this.sys2.engine);
      const snappedPosition = getSnapPosition(
        zoom,
        this.sys2.state.modelReducer.gridSettings,
        new Vector2(x, y)
      );
      textObject.x = snappedPosition.x;
      textObject.y = snappedPosition.y;
    }

    const displayables = new Displayables();
    displayables.val = [
      {
        asset: textObject,
        textureName: null,
        layer: LAYER.SHAPE,
      },
    ];

    const drawingText = new DrawingText();
    drawingText.asset = textObject;

    const text = new Text();
    text.fill.val = textColor;
    text.fontSize.val = fontSize;
    text.textString.val = value;
    text.stroke.val = textStrokeColor;
    text.strokeThickness.val = textStrokeThickness;
    text.strokeAlpha.val = textStrokeAlpha;

    const position = new Position();
    // set position to the center of the text
    const centerX = textObject.x + textObject.width / 2;
    const centerY = textObject.y + textObject.height / 2;
    position.value = new Vector2(centerX, centerY);

    // Setting the anchor requires us to reset the Text object's position to the center
    drawingText.asset.position.set(centerX, centerY);

    const oriented = new Oriented();
    oriented.ref.val = drawingText.asset;

    const shapeID = new ShapeID();
    shapeID.value = gid();

    const layer = new MetraLayer();
    layer.value = LAYER.SHAPE;

    const color = new Color();
    color.value = colorParse(textColor);

    const order = new Order();
    order.value = this._textShapes.all.size;

    const scale = new Scale();
    scale.value = Vector2.fromPoint({ x: 1, y: 1 });

    const bounds = new Bounds();
    bounds.value = new Rectangle(0, 0, textObject.width, textObject.height);

    const alpha = new Alpha();
    alpha.value = textAlpha;

    const eid = this.sys2.createEntity([
      bounds,
      text,
      position,
      shapeID,
      layer,
      order,
      scale,
      displayables,
      drawingText,
      alpha,
      new Interactive(),
      new Selectable(),
      color,
      oriented,
      new Moveable(),
      new Visible(),
    ]);

    const entity = this.sys2.engine.ecs.getEntity(eid);
    if (isSome(entity)) {
      this.sys2.engine.ecs.tagManager.tagEntity(shapeID.value, entity);
      this.sys2.engine.ecs.groupManager.addEntityToGroup(
        ECS_GROUP.TEXT,
        entity
      );
    }

    // if we are using default black or white colors, add DarkMode
    // component to update the color when switching between dark mode
    const defaultColor =
      text.fill.val === HEX_STRING.WHITE || text.fill.val === HEX_STRING.BLACK;
    if (defaultColor && textStrokeThickness === 0)
      this.sys2.ensure(eid, new DarkMode());

    // Turn off drawing lock when creating text
    if (lockedDrawings) this.sys2.atoms.drawing.unlockDrawings();

    const textShapeConfig: TextConfig = {
      alpha: textAlpha,
      angle: drawingText.asset.rotation,
      color: textColor,
      id: shapeID.value,
      layer: LAYER.SHAPE,
      order: order.value,
      pos: {
        x: position.val.x,
        y: position.val.y,
      },
      scale: scale.value,
      type: SHAPE.TEXT,
      visible: true,
      hidden: false,
      width: Math.round(textObject.width),
      height: Math.round(textObject.height),
      fontSize: fontSize,
      text: value,
      stroke: textStrokeColor,
      strokeThickness: textStrokeThickness,
      strokeAlpha: textStrokeAlpha,
    };

    this.atoms.dispatch(addShapeByConfig(textShapeConfig));
    this.atoms.dispatch(remember());
    this.atoms.dispatch(setTool(TOOL.SELECT));
    this.sys2.atoms.selection.selectExactly([eid], true);
    this.sys2.engine.ecs.resolveById(eid);
  }

  editTextString(textString: string, gid: GID) {
    const shapeConfig = this.sys2.state.modelReducer.shapes[gid];
    const updatedConfig = {
      ...shapeConfig,
      text: textString,
    };

    const entity = this.sys2.engine.ecs.getEntityByTag(gid);
    if (!entity) return;
    const text = this.sys2.assert(entity.id, Text);
    text.textString.val = textString;

    this.atoms.dispatch(updateShapeByConfig(updatedConfig));
    this.atoms.dispatch(remember());
  }

  editTextColors(property: string, value: string | number) {
    const eid = this._selectedText.all.values().next().value;
    const [gid, text] = this.sys2.assertAll(eid, [ShapeID, Text]);
    const mappedProperty = textPropertyMap[property];
    const textConfig = this.sys2.state.modelReducer.shapes[
      gid.val
    ] as TextConfig;
    const currentConfigValue = textConfig[mappedProperty];

    if (value !== currentConfigValue) {
      // we need to update the value
      const updatedConfig = {
        ...textConfig,
        [mappedProperty]: value,
      };

      const mappedComponentValue = textComponentMap[property];
      // @ts-ignore
      text[mappedComponentValue].val = value;

      this.atoms.dispatch(updateShapeByConfig(updatedConfig));
      this.atoms.dispatch(remember());
    }
  }

  editTextSizes(property: string, value: number) {
    const eid = this._selectedText.all.values().next().value;
    const [gid, text] = this.sys2.assertAll(eid, [ShapeID, Text]);
    const mappedProperty = textPropertyMap[property];
    const textConfig = this.sys2.state.modelReducer.shapes[
      gid.val
    ] as TextConfig;
    const currentConfigValue = textConfig[mappedProperty];

    if (value !== currentConfigValue) {
      // we need to update the value
      const updatedConfig = {
        ...textConfig,
        [mappedProperty]: value,
      };

      const mappedComponentValue = textComponentMap[property];
      // @ts-ignore
      text[mappedComponentValue].val = value;

      this.atoms.dispatch(updateShapeByConfig(updatedConfig));
      this.atoms.dispatch(remember());
    }
  }
}
