/**
 * Finds the total deviation from the total size of cells as if they had all been the standard size
 * @param {Array<String>} tableIds gids for all objects on the grid in the provided dimension
 * @param {Object} resizedCells gids of objects that deviated and their new size
 * @param {Number} standardSize original size for cells
 * @param {bool} isFiltered whether the number of tableIds may have been reduced due to filtering
 * @returns {Number} positive or negative deviation from expected size
 */
export const getSizeDeviation = (tableIds, resizedCells, standardSize) => {
  let deviantCellsCount = 0;
  let deviantCellsSize = 0;
  const searchableTableIds = new Set(tableIds);
  const searchableTableIdsSize = searchableTableIds.size;
  let foundCellsCount = 0;
  for (const [gid, size] of Object.entries(resizedCells)) {
    if (searchableTableIds.has(gid)) {
      deviantCellsSize += size;
      deviantCellsCount++;
      foundCellsCount++;
    }
    if (foundCellsCount >= searchableTableIdsSize) break;
  }
  return deviantCellsSize - deviantCellsCount * standardSize;
};

/**
 * Finds latest cell size, enforcing that a resized size will be at or above the minimum
 * @param {Number} storedSize size from the previous props
 * @param {Object || undefined } resizingData updated size from user input
 * @param {Number} minimumSize lowest allowed size for cell
 * @returns {Number} size of the cell
 */
export const getCellSize = (storedSize, resizingData, minimumSize) => {
  const diff = resizingData ? resizingData.end - resizingData.start : 0;
  return Math.max(minimumSize, storedSize + diff);
};

/**
 * Rows can be moved only when a single cell is selected and that cell is an entity label
 * @param {Object} ids selected cell ids
 * @param {number} row row of selection anchor
 * @param {column} column column of selection anchor
 * @returns {boolean} whether the row meets the requirements to move
 */
export const shouldMoveRow = (ids, row, column) => {
  if (Object.keys(ids).length !== 1) return false;
  // Must be in first column, which has entity label
  // Cannot move name, which is in first row
  return column === 0 && row !== 0;
};

/**
 * Columns can be moved only when a single cell is selected and that cell is a property name
 * @param {Object} ids selected cell ids
 * @param {number} row row of selection anchor
 * @param {number} column column of selection anchor
 * @returns {boolean} whether the column meets the requirements to move
 */
export const shouldMoveColumn = (ids, row, column) => {
  if (Object.keys(ids).length !== 1) return false;
  // Must be in first row, which has prop titles
  // Cannot move name, which is in first column
  return row === 0 && column !== 0;
};
